import React, {useEffect, useState} from 'react';
import GeneralSettingsUI from '../view/GeneralSettingsUI';
import {inject, observer} from 'mobx-react';
import {Organization} from '../../../../data/models/Oganization/Organization';
import SettingsStore from '../../store/SettingsStore';
import {useParams} from 'react-router-dom';

interface Props {
  settingsStore?: SettingsStore;
}

const GeneralSettingsContainer = inject('settingsStore')(
  observer(({settingsStore}: Props) => {
    const [organization, setOrganization] = useState<Organization>();
    const {organizationId} = useParams();

    useEffect(() => {
      if (organizationId) {
        settingsStore!
          .getOrganizationDetails(organizationId)
          .then(setOrganization);
      }
      return () => {
        settingsStore!.clearGeneralErrors();
      };
    }, []);

    const onEdit = async (organization: Organization, image?: File) => {
      const success = await settingsStore!.editOrganization(
        organization,
        image,
      );
      if (success) {
        settingsStore!
          .getOrganizationDetails(organization.id)
          .then(setOrganization);
        //TODO implement notification success
      }
    };

    const onCancel = () => {
      setOrganization(undefined);
      if (organizationId) {
        settingsStore!
          .getOrganizationDetails(organizationId)
          .then(setOrganization);
      }
    };

    const clearErrors = () => {
      settingsStore!.clearGeneralErrors();
    };

    return organization ? (
      <GeneralSettingsUI
        onEdit={onEdit}
        clearErrors={clearErrors}
        organization={organization}
        isLoading={settingsStore!.isLoading}
        errors={settingsStore!.generalSettingsErrors}
        onCancel={onCancel}
      />
    ) : (
      <div />
    );
  }),
);

export default GeneralSettingsContainer;
