import LibraryApi from '../api/LibraryAPI';
import {MEDIA_FILES_ALL} from '../models/LibraryFile/MediaFile';

class LibraryRepository {
  private libraryApi = new LibraryApi();

  public uploadVideo(organizationId: string, video: File, duration: number) {
    return this.libraryApi.uploadLibraryVideo(organizationId, video, duration);
  }

  public getMediaFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    typeFilter?: number,
    search?: string,
    orderAsk?: boolean,
  ) {
    return this.libraryApi.getLibraryFiles(
      page,
      organizationId,
      pageSize,
      orderBy,
      typeFilter !== undefined ? typeFilter : MEDIA_FILES_ALL,
      search,
      orderAsk,
    );
  }

  public getByContentId(organizationId: string, contentId: string) {
    return this.libraryApi.getFileByContentId(organizationId, contentId);
  }

  public deleteMediaFile(organizationId: string, mediaFileId: string) {
    return this.libraryApi.deleteMediaFile(organizationId, mediaFileId);
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    return this.libraryApi.getVimeoVideos(organizationId, page, pageSize);
  }
}

export default LibraryRepository;
