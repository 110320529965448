import QuestionGenerationAPI from '../api/QuestionGenerationAPI';

class QuestionGenerationRepository {
  private questionGenerationApi = new QuestionGenerationAPI();

  public generateQuestions(
    organizationId: string,
    contentId: string,
    topic: string,
  ) {
    return this.questionGenerationApi.generateQuestions(
      organizationId,
      contentId,
      topic,
    );
  }

  public generateChallengeBranchAI(
    organizationId: string,
    lessonId: string,
    videoNodeId: string,
  ) {
    return this.questionGenerationApi.generateChallangeBranchAI(
      organizationId,
      lessonId,
      videoNodeId,
    );
  }
}

export default QuestionGenerationRepository;
