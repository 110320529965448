import * as React from 'react';
import HomeUI from '../view/HomeUI';
import HomeStore from '../store/HomeStore';
import {inject, observer} from 'mobx-react';
import {UserInfo} from '../../../data/models/User/UserInfo';
import {
  LearningCourseModel,
  TeachingCourseModel,
} from '../../../data/models/Course/CourseList';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ModalProviderStore from '../../../modules/ModalProvider/store/ModalProviderStore';
import {
  REGISTER_TO_MANAGE,
  REGISTER_TO_TEACH,
} from '../../../data/staticValues/onboardingOptions';
import {CREATE_ORGANIZATION_MODAL} from '../../../modules/ModalProvider/globalModals';
import {Organization} from '../../../data/models/Oganization/Organization';

interface Props {
  homeStore?: HomeStore;
  modalProviderStore?: ModalProviderStore;
}

const HomeContainer = inject(
  'homeStore',
  'modalProviderStore',
)(
  observer(({homeStore, modalProviderStore}: Props) => {
    const [userInfo, setUserInfo] = React.useState<UserInfo>();
    const [myOrganizations, setMyOrganizations] =
      React.useState<Organization[]>();
    const [learnerOrganization, setLearnerOrganization] =
      React.useState<Organization>();

    const [myLearningCoursesList, setMyLearningCoursesList] = React.useState<
      LearningCourseModel[]
    >([]);
    const [myTeachingCoursesList, setMyTeachingCoursesList] = React.useState<
      TeachingCourseModel[]
    >([]);
    const [dataFetched, setDataFetched] = React.useState(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    React.useEffect(() => {
      homeStore!
        .getUserInfo()
        .then(setUserInfo)
        .then(() => {
          return homeStore!.getLearnerOrganization().then(learnerOrg => {
            setLearnerOrganization(learnerOrg);
            return homeStore!.getMyOrganizations().then(myOrg => {
              setMyOrganizations(myOrg);
            });
          });
        })
        .finally(() => {
          setDataFetched(true);
        });

      homeStore!.getCourseList().then((courses: any) => {
        setMyLearningCoursesList(
          courses.learnerCourses.map((item: any) => ({
            ...item,
            dueDate: new Date(item.dueDate),
          })),
        );
        setMyTeachingCoursesList(courses.instructorCourses);
      });
    }, [homeStore]);

    React.useEffect(() => {
      if (
        searchParams.get('rp') &&
        searchParams.get('rp') === REGISTER_TO_MANAGE
      ) {
        homeStore!.getMyOrganizations().then(res => {
          if (res.length) {
            //TODO navigate to notifications
          } else {
            openCreateOrg();
          }
        });
      } else if (
        searchParams.get('rp') &&
        searchParams.get('rp') === REGISTER_TO_TEACH
      ) {
        homeStore!.getMyOrganizations().then(res => {
          if (res.length) {
            //TODO navigate to notifications
          } else {
            openCreateOrg();
          }
        });
      }
    }, []);

    const openCreateOrg = () => {
      modalProviderStore!.openModal(CREATE_ORGANIZATION_MODAL, onCloseOrgModal);
    };

    const onCloseOrgModal = (orgId?: string) => {
      if (orgId && typeof orgId === 'string') {
        navigate(`/manage/${orgId}/courses`);
      } else if (searchParams.get('rp')) {
        navigate('/home', {replace: true});
      }
    };

    const onExplore = () => {
      navigate('/explore');
    };

    const onCreateCourse = () => {
      if (myOrganizations && myOrganizations.length > 0) {
        navigate(`/manage/${myOrganizations[0].id}/courses/create`);
      }
    };

    const onOpenCourse = (courseId: string, teaching?: boolean) => {
      if (teaching && myOrganizations && myOrganizations.length > 0) {
        navigate(`/preview/${myOrganizations[0].id}/course/${courseId}`);
      } else {
        navigate(`/course/${courseId}`);
      }
    };

    return (
      <HomeUI
        userInfo={userInfo}
        learnerOrganization={learnerOrganization}
        isPartOfOrganization={myOrganizations?.length !== 0}
        teachingCourses={myTeachingCoursesList || []}
        learningCourses={myLearningCoursesList || []}
        isLoading={homeStore!.isLoading}
        onCreateOrg={openCreateOrg}
        onExplore={onExplore}
        onCreateCourse={onCreateCourse}
        onOpenCourse={onOpenCourse}
        dataFetched={dataFetched}
      />
    );
  }),
);

export default HomeContainer;
