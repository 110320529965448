import React from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';
import {LessonContentItem} from '../../../data/models/LessonContent/LessonContentList';
import {
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import VideoPlayerControls from '../../../uiToolkit/VideoPlayerControls';

interface Props {
  source: string;
  startTime: number;
  endTime: number;
  onFinished: () => void;
  currentNode?: LessonContentItem;
  started?: boolean;
  showControls?: boolean;
  fitHeight?: boolean;
  onWidthCalculated: (width: number) => void;
  reference?: (ref: any) => void;
  setProgres: (prog: number) => void;
}

const VideoPlayer = React.memo(
  ({
    source,
    startTime,
    endTime,
    onFinished,
    currentNode,
    started,
    showControls,
    fitHeight,
    onWidthCalculated,
    reference,
    setProgres,
  }: Props) => {
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [playing, setPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const [currentNodeId, setCurrentNodeId] = React.useState<string>();

    const containerRef = React.useRef<any>(null);
    const playerRef = React.useRef<ReactPlayer>(null);

    React.useEffect(() => {
      if (reference) {
        reference({pause});
      }
    }, []);

    React.useEffect(() => {
      if (started) {
        setPlaying(true);
      }
    }, [source, started]);

    React.useEffect(() => {
      if (
        currentNode &&
        (currentNode.data.ivNodeType === VIDEO_NODE_TYPE ||
          currentNode.data.ivNodeType === OVERLAY_VIDEO_TYPE ||
          currentNode.data.ivNodeType === OVERLAY_VIMEO_VIDEO_TYPE ||
          currentNode.data.ivNodeType === VIMEO_VIDEO_TYPE) &&
        currentNode?.data.internalId !== currentNodeId &&
        started
      ) {
        seekTo(startTime);
        setPlaying(true);
        setCurrentNodeId(currentNode.data.internalId);
      }
    }, [currentNode, started]);

    React.useEffect(() => {
      function handleResize() {
        if (containerRef && containerRef.current) {
          setWidth(containerRef.current.offsetWidth);
          onWidthCalculated(containerRef.current.offsetWidth);
          setHeight(containerRef.current.offsetHeight);
        }
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    React.useEffect(() => {
      if (!playing && Math.ceil(currentTime) == Math.ceil(endTime)) {
        onFinished();
      }
    }, [currentTime, playing]);

    React.useLayoutEffect(() => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
        onWidthCalculated(containerRef.current.offsetWidth);
        setHeight(containerRef.current.offsetHeight);
      }
    }, []);

    const onReady = (player: ReactPlayer) => {
      if (playerRef && playerRef.current && !fitHeight) {
        const innerPlayer = player.getInternalPlayer();
        // innerPlayer.style.padding = '3px';
        innerPlayer.style.borderRadius = '20px';
        innerPlayer.style.backgroundColor = 'transparent';
      }
    };

    const togglePlaying = () => {
      setPlaying(!playing);
    };

    const onProgress = (state: OnProgressProps) => {
      let progress = 0;
      if (startTime !== undefined && endTime !== undefined) {
        if (Math.ceil(state.playedSeconds) < Math.ceil(startTime)) {
          playerRef.current?.seekTo(startTime);
          progress = startTime;
        } else if (Math.ceil(state.playedSeconds) >= Math.ceil(endTime)) {
          progress = endTime;
          setPlaying(false);
        } else {
          progress = state.playedSeconds;
        }
      } else {
        progress = state.playedSeconds;
      }
      setCurrentTime(progress);
      if (setProgres) {
        setProgres(progress);
      }
    };

    const onDuration = (seconds: number) => {
      setDuration(seconds);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pause = () => {
      setPlaying(false);
    };

    const seekTo = (time: number) => {
      if (time <= startTime) {
        playerRef.current?.seekTo(startTime);
      } else if (time >= endTime) {
        playerRef.current?.seekTo(endTime);
      } else {
        playerRef.current?.seekTo(time);
      }
    };

    return (
      <div
        className="VideoPlaybackPlayerContainer"
        ref={containerRef}
        style={fitHeight ? {height: '100vh'} : {}}>
        <ReactPlayer
          url={source}
          playing={playing}
          ref={playerRef}
          width={width}
          height={fitHeight ? height : width / 1.77}
          onReady={onReady}
          onProgress={onProgress}
          onDuration={onDuration}
          progressInterval={1}
          pip={false}
          onPause={() => {
            if (playing && playerRef) {
              playerRef.current?.getInternalPlayer().play();
            }
          }}
        />
        {showControls && (
          <VideoPlayerControls
            playing={playing}
            duration={duration}
            togglePlaying={togglePlaying}
            currentTime={currentTime}
            seekTo={seekTo}
            setPlaying={setPlaying}
          />
        )}
      </div>
    );
  },
);

export default VideoPlayer;
