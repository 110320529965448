import {apiClient, withAuth} from './baseApiClient';

class AnalyticsApi {
  public async getAdminAnalytics() {
    const headers = await withAuth({});
    return apiClient.get(`/analytics/statistic`, {headers});
  }
}

export default AnalyticsApi;
