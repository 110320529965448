import React from 'react';
import {inject, observer} from 'mobx-react';
import AddVideoOverlayUI from '../view/AddVideoOverlayUI';
import AddVideoOverlayStore from '../store/AddVideoOverlayStore';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';
import {useParams} from 'react-router-dom';

interface Props {
  addVideoOverlayStore?: AddVideoOverlayStore;
}

const AddVideoOverlayContainer = inject('addVideoOverlayStore')(
  observer(({addVideoOverlayStore}: Props) => {
    const {organizationId, lessonId} = useParams();
    const onClose = () => {
      addVideoOverlayStore!.closeWizard();
    };

    const onSave = (
      start: number,
      end: number,
      overlayStart: number,
      overlayEnd: number,
      overlayAction: number,
      urlPayload: string,
      points: Point[],
      encodedAtWidth: number,
      overlayVisible: boolean,
    ) => {
      if (organizationId && lessonId) {
        addVideoOverlayStore!.saveOverlayNode(
          organizationId,
          lessonId,
          start,
          end,
          overlayStart,
          overlayEnd,
          overlayAction,
          urlPayload,
          points,
          encodedAtWidth,
          overlayVisible,
        );
      }
    };

    return addVideoOverlayStore!.shouldShowWizard() ? (
      <AddVideoOverlayUI
        onCancel={onClose}
        selectedFile={addVideoOverlayStore!.getVideoSource()}
        selectedVimeoFile={addVideoOverlayStore!.getVimeoSource()}
        onSave={onSave}
      />
    ) : null;
  }),
);

export default AddVideoOverlayContainer;
