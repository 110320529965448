import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import {Organization} from '../../../data/models/Oganization/Organization';

class SettingsStore {
  private organizationRepository = new OrganizationRepository();

  public generalSettingsErrors: string[] = [];
  public roleSettingsErrors: string[] = [];
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getOrganizationDetails(organizationId: string) {
    const result =
      await this.organizationRepository.getOrganizationDetails(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async editOrganization(organization: Organization, image?: File) {
    this.generalSettingsErrors = [];
    this.isLoading = true;
    const result = await this.organizationRepository.editOrganization(
      organization,
      image,
    );
    this.isLoading = false;
    if (!result.success && result.errors) {
      this.generalSettingsErrors = result.errors;
      return false;
    }
    return true;
  }

  public async getOrganizationRoles(organizationId: string) {
    const result =
      await this.organizationRepository.getOrganizationRoles(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async createOrganizationRole(
    organizationId: string,
    roleName: string,
    permissionsList: string[],
  ) {
    this.isLoading = true;
    this.roleSettingsErrors = [];
    const result = await this.organizationRepository.createRole({
      organizationId,
      roleName,
      permissionsList,
    });
    this.isLoading = false;
    if (!result.success && result.errors) {
      this.roleSettingsErrors = result.errors;
    }
    return result.success;
  }

  public async getRoleDetailsById(id: string, organizationId: string) {
    this.isLoading = true;
    const result = await this.organizationRepository.getRoleById(
      id,
      organizationId,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async editOrganizationRole(
    organizationId: string,
    id: string,
    roleName: string,
    permissions: string[],
  ) {
    this.isLoading = true;
    this.roleSettingsErrors = [];
    const result = await this.organizationRepository.editRole({
      organizationId,
      roleName,
      permissions,
      id,
    });
    this.isLoading = false;
    if (!result.success && result.errors) {
      this.roleSettingsErrors = result.errors;
    }
    return result.success;
  }

  public async deleteOrganizationRole(organizationId: string, roleId: string) {
    const result = await this.organizationRepository.deleteRole(
      organizationId,
      roleId,
    );
    return result.success;
  }

  public clearGeneralErrors() {
    this.generalSettingsErrors = [];
  }

  public clearRoleSettingsErrors() {
    this.roleSettingsErrors = [];
  }
}

export default SettingsStore;
