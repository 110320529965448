import {makeAutoObservable} from 'mobx';
import FoldersRepository from '../../../data/repositories/FoldersRepository';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {Folder, FolderCreateModel} from '../../../data/models/Course/Folder';

class FoldersStore {
  private readonly foldersRepo = new FoldersRepository();
  private readonly courseRepo = new CoursesRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getFolders(organizationId: string, courseId: string) {
    this.isLoading = true;
    const result = await this.foldersRepo.getCourseFolders(
      organizationId,
      courseId,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getCourse(organizationId: string, courseId: string) {
    this.isLoading = true;
    const result = await this.courseRepo.getCourse(organizationId, courseId);
    this.isLoading = false;
    return result.data;
  }

  public async editFolder(organizationId: string, folder: Folder) {
    this.isLoading = true;
    const result = await this.foldersRepo.editFolder(organizationId, folder);
    this.isLoading = false;
    return result.success;
  }

  public async createFolder(organizationId: string, folder: FolderCreateModel) {
    this.isLoading = true;
    await this.foldersRepo.createFolder(organizationId, folder);
    this.isLoading = false;
  }

  public async deleteFolder(
    organizationId: string,
    courseId: string,
    folderId: string,
  ) {
    this.isLoading = true;
    const result = await this.foldersRepo.deleteFolder(
      organizationId,
      courseId,
      folderId,
    );
    this.isLoading = false;
    if (!result.success && result.errors?.length) {
      return result.errors[0];
    }
  }

  public async reorderFolders(
    organizationId: string,
    courseId: string,
    folders: Folder[],
  ) {
    const result = await this.foldersRepo.reorderFolders(
      organizationId,
      courseId,
      folders,
    );
    return result.success;
  }

  public async getSidebarOptions(organizationId: string, search: string) {
    this.isLoading = true;
    const result = await this.courseRepo.getSidebarOptions(
      organizationId,
      search,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    } else {
      return [];
    }
  }
}

export default FoldersStore;
