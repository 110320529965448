import React, {useEffect, useState} from 'react';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import {lessonEditorText} from '../../../lessonEditorText';
import YesNoOption from '../../../../../uiToolkit/Inputs/YesNoOption';
import {
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
} from '../../../../../data/models/LessonContent/LessonContentBase';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';
import './styles.css';
import Button from '../../../../../uiToolkit/Buttons/Button';
import Dropdown from '../../../../../uiToolkit/Inputs/Dropdown';
import {GradeWeights} from '../../../../../data/models/LessonContent/GradeWeights';

const MAX_AMOUNT_OF_ANSWERS = 4;

interface Props {
  mChoice: LessonContentMChoice;
  onCancel: () => void;
  saveChanges: (
    question: string,
    answers: LessonContentMChoiceAnswer[],
    gradeWeight: number,
  ) => void;
  isLoading: boolean;
  onDelete: () => void;
  isPublished?: boolean;
}

const MChoiceOptionsUI = React.memo(
  ({mChoice, saveChanges, isLoading, onDelete, isPublished}: Props) => {
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState<
      {value: string; isCorrect: boolean}[]
    >([{value: '', isCorrect: false}]);
    const [gradeWeight, setGradeWeight] = useState('1');

    useEffect(() => {
      setQuestion(mChoice.question);
      const newAnswers: any[] = [];
      mChoice.answers.forEach(item => {
        newAnswers.push({
          value: item.value,
          isCorrect: item.isCorrect,
        });
      });
      setGradeWeight(mChoice.gradeWeight?.toString() || '1');
      setAnswers(newAnswers);
    }, [mChoice]);

    const onChangeAnswer = (index: number) => (answer: string) => {
      const newAnswers = [...answers];
      newAnswers[index].value = answer;
      setAnswers(newAnswers);
    };

    const setIsCorrect = (index: number) => (value: boolean) => {
      const newAnswers = [...answers];
      newAnswers[index].isCorrect = value;
      setAnswers(newAnswers);
    };

    const onAddAnswer = () => {
      if (answers.length < MAX_AMOUNT_OF_ANSWERS) {
        const newAnswers = [...answers];
        newAnswers.push({value: '', isCorrect: false});
        setAnswers(newAnswers);
      }
    };

    const onRemove = (index: number) => {
      const newAnswers = [...answers];
      newAnswers.splice(index, 1);
      setAnswers(newAnswers);
    };

    const onSave = () => {
      if (isValid()) {
        saveChanges(question, answers, parseInt(gradeWeight, 10));
      }
    };

    const isValid = () => {
      let allAnswersFilled = true;
      let oneAnswerCorrect = false;
      let have2sameAnswers = false;
      answers.forEach((answer, index) => {
        if (answer.value.trim() === '') {
          allAnswersFilled = false;
        }
        if (answer.isCorrect) {
          oneAnswerCorrect = true;
        }
        if (
          answers.find(
            (x, xIndex) => x.value === answer.value && xIndex !== index,
          )
        ) {
          have2sameAnswers = true;
        }
      });
      return (
        question.trim() !== '' &&
        answers.length > 0 &&
        allAnswersFilled &&
        !have2sameAnswers &&
        oneAnswerCorrect
      );
    };

    const changesWasMade = () => {
      let answersChanged = false;
      if (answers.length !== mChoice.answers.length) {
        return true;
      }
      if (mChoice.gradeWeight?.toString() !== gradeWeight) {
        return true;
      }
      answers.forEach((item, index) => {
        if (
          item.value !== mChoice.answers[index].value ||
          item.isCorrect !== mChoice.answers[index].isCorrect
        ) {
          answersChanged = true;
        }
      });
      return answersChanged || question !== mChoice.question;
    };

    return (
      <div className="MChoiceOptions">
        <Dropdown
          heading={lessonEditorText.gradeWeight}
          options={GradeWeights}
          setSelectedOption={setGradeWeight}
          selectedOption={gradeWeight}
          type="box"
          className="MChoiceGradeWeightInput"
        />
        <TextInput
          value={question}
          onChange={setQuestion}
          heading={lessonEditorText.question}
          placeholder={lessonEditorText.questionPlaceholder}
          maxCharacters={200}
          isMandatory
          uiType="box"
        />
        {answers.map((item, index) => (
          <div key={index.toString()}>
            <TextInput
              value={item.value}
              onChange={onChangeAnswer(index)}
              heading={index === 0 ? lessonEditorText.answerTitle : undefined}
              placeholder={lessonEditorText.answerPlaceholder}
              className="AnswersInput"
              maxCharacters={140}
              isMandatory={index === 0}
              uiType="box"
            />
            <div className="Section">
              <YesNoOption
                value={item.isCorrect}
                onSelect={setIsCorrect(index)}
                uiType="icon"
              />
              <Button
                title={lessonEditorText.deleteAnswer}
                onClick={() => onRemove(index)}
                uiType="text_danger"
                className="DeleteAnswerButton"
              />
            </div>
          </div>
        ))}
        <Button
          title={lessonEditorText.addAnswer}
          onClick={onAddAnswer}
          disabled={answers.length === MAX_AMOUNT_OF_ANSWERS}
          uiType="box"
          icon="icon-typePlus-icon"
          infoText={lessonEditorText.disclaimer}
          className="AddAnswerButton"
        />
        <EditorOptionsFooter
          onSave={onSave}
          saveEnabled={changesWasMade() && isValid()}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default MChoiceOptionsUI;
