import React, {useEffect, useState} from 'react';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';
import './styles.css';
import {LessonContentOverlayVideo} from '../../../../data/models/LessonContent/LessonContentBase';

interface Props {
  width: number;
  onPause: () => void;
  overlayNode: LessonContentOverlayVideo;
  progress: number;
}

const FreeformOverlay = React.memo(
  ({width, onPause, progress, overlayNode}: Props) => {
    const [show, setShow] = useState(true);

    const getObjectPath = () => {
      const points = overlayNode.overlayCoordinates;
      let path = 'M';
      if (points.length === 0) {
        return '';
      }

      points.forEach((point: Point) => {
        path += scaleX(point.x) + ' ' + scaleY(point.y) + ' ';
      });
      path += 'z';
      return path;
    };

    const scaleX = (x: number) => {
      return (x * width) / overlayNode.encodedAtWidth;
    };

    const scaleY = (y: number) => {
      return (y * width) / overlayNode.encodedAtWidth;
    };

    useEffect(() => {
      if (
        overlayNode.overlayStartTime >= progress ||
        overlayNode.overlayEndTime <= progress
      ) {
        setShow(false);
      } else {
        setShow(true);
      }
    }, [progress]);

    const onOpenUrl = () => {
      if (overlayNode.urlPayload) {
        onPause();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.open(overlayNode.urlPayload, '_blank').focus();
      }
    };

    return show ? (
      <div
        style={{width, height: width / 1.77}}
        className="FreeformOverlayCanvas">
        <svg width={width} height={width / 1.77}>
          <path
            fillOpacity={0.4}
            fill={overlayNode?.overlayVisible ? '#3DA2FF' : 'transparent'}
            d={getObjectPath()}
            // onClick={onOpenUrl}
            onPointerDown={onOpenUrl}
          />
        </svg>
      </div>
    ) : null;
  },
);

export default FreeformOverlay;
