import React, {useState} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import {myLibraryModalText} from '../myLibraryModalText';
import {
  MEDIA_FILES_ALL,
  MEDIA_FILES_IMAGE,
  MEDIA_FILES_VIDEO,
  MediaFile,
  VimeoMediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import UploadVideoButton from '../../../uiToolkit/Inputs/UploadVideoButton';
import DataTable from 'react-data-table-component';
import LibraryEmptyState from '../../../uiToolkit/EmptyStates/LibraryEmptyState';
import './styles.css';
import TablePagination from '../../../uiToolkit/TablePagination';
import {isImageFile} from '../../../data/models/LibraryFile/VideoSourceShort';
import {logoTextWhite} from '../../../assets/images';
import Button from '../../../uiToolkit/Buttons/Button';

const columns = [
  {
    name: 'Filename',
    sortable: true,
    sortField: 'file_name',
    selector: (row: MediaFile) => row.fileName,
    cell: (row: MediaFile) => (
      <div className="DataTableImageNameContainer" data-tag="allowRowEvents">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {isImageFile(row.fileType) ? (
            row.fileUrl && <img src={row.fileUrl} />
          ) : row.placeholderUrl ? (
            <img src={row.placeholderUrl} />
          ) : (
            <img src={logoTextWhite} className="PlaceholderLogo" />
          )}
          {row.fileName}
        </h5>
      </div>
    ),
    grow: 2,
  },
  {
    name: 'Type',
    cell: (row: MediaFile) => (
      <div className="DataTableImageNameContainer" data-tag="allowRowEvents">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          {row.fileType}
        </h5>
      </div>
    ),
  },
  {
    name: 'Upload Date',
    sortable: true,
    sortField: 'created_date',
    selector: (row: MediaFile) => row.dateCreated.toDateString(),
    cell: (row: MediaFile) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {new Date(Date.parse(row.dateCreated.toString())).toLocaleDateString()}
      </h5>
    ),
  },
];

const vimeoColumns = [
  {
    name: 'Filename',
    sortable: true,
    sortField: 'file_name',
    selector: (row: VimeoMediaFile) => row.title,
    cell: (row: VimeoMediaFile) => (
      <div className="DataTableImageNameContainer" data-tag="allowRowEvents">
        <h5 className="DataTableText" data-tag="allowRowEvents">
          <img src={row.placeholderUrl} />
          {row.title}
        </h5>
      </div>
    ),
    grow: 2,
  },
];

interface Props {
  onCancel: () => void;
  type: number;
  isLoading: boolean;
  onFileUpload: (file: File, duration: number) => void;
  files: MediaFile[];
  vimeoFiles: VimeoMediaFile[];
  onFileSelect: (file: MediaFile) => void;
  onVimeoFileSelect: (file: VimeoMediaFile) => void;
  pages: number;
  onPageChange: (page: number) => void;
  storageLimit: number;
  onSort: (selectedColumn: any, sortOrder: any) => void;
}

const MyLibraryModalUI = React.memo(
  ({
    onCancel,
    type,
    isLoading,
    onFileUpload,
    files,
    onFileSelect,
    pages,
    onPageChange,
    storageLimit,
    onSort,
    vimeoFiles,
    onVimeoFileSelect,
  }: Props) => {
    const [selectedSource, setSelectedSource] = useState(0);

    const getTitleFromType = () => {
      switch (type) {
        case MEDIA_FILES_ALL:
          return myLibraryModalText.allFiles;
        case MEDIA_FILES_IMAGE:
          return myLibraryModalText.allImages;
        case MEDIA_FILES_VIDEO:
          return myLibraryModalText.allVideos;
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="MyLibraryModalContainer">
          <div className="Header">
            <h1>{myLibraryModalText.title}</h1>
            <UploadVideoButton
              isLoading={isLoading}
              onVideoSelected={onFileUpload}
              type={type}
              maxFileSize={storageLimit}
            />
          </div>
          {vimeoFiles.length !== 0 ? (
            <div className="d-flex flex-row">
              <Button
                title="Tapybl Library"
                onClick={() => setSelectedSource(0)}
                uiType={selectedSource === 0 ? 'tab_active' : 'tab'}
              />
              <Button
                title="Vimeo"
                onClick={() => setSelectedSource(1)}
                uiType={selectedSource === 1 ? 'tab_active' : 'tab'}
              />
            </div>
          ) : null}
          {selectedSource === 0 && (
            <>
              <h3>{getTitleFromType()}</h3>
              <DataTable
                data={files}
                columns={columns}
                onRowClicked={(row: MediaFile) => onFileSelect(row)}
                highlightOnHover
                pointerOnHover
                onChangePage={onPageChange}
                pagination
                paginationServer
                paginationPerPage={10}
                sortServer
                onSort={onSort}
                paginationComponent={TablePagination}
                paginationTotalRows={pages * 10}
                noDataComponent={
                  <LibraryEmptyState
                    onVideoUpload={onFileUpload}
                    isLoading={isLoading}
                    type={type}
                  />
                }
              />
            </>
          )}
          {selectedSource === 1 && (
            <>
              <h3>{myLibraryModalText.vimeoVideos}</h3>
              <DataTable
                data={vimeoFiles}
                columns={vimeoColumns}
                onRowClicked={(row: VimeoMediaFile) => onVimeoFileSelect(row)}
                highlightOnHover
                pagination
                paginationServer
                paginationComponent={TablePagination}
                pointerOnHover
                paginationTotalRows={pages * 10}
                // onChangePage={onPageChange}
              />
            </>
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default MyLibraryModalUI;
