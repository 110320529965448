import React, {useEffect, useState} from 'react';
import CoursesListUI from '../view/CoursesListUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {CourseListItem} from '../../../../data/models/Course/Course';
import ListCoursesStore from '../store/ListCoursesStore';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {coursesListText} from '../coursesListText';
import EnrollLearnersWizardStore from '../../../../modules/EnrollLearnersWizard/store/EnrollLearnersWizardStore';
import {EnrollLearnersWizard} from '../../../../modules/EnrollLearnersWizard';
import {AddInstructorWizard} from '../../../../modules/AddInstructorWizard';
import AddInstructorWizardStore from '../../../../modules/AddInstructorWizard/store/AddInstructorWizardStore';
import {APP_PERMISSIONS} from '../../../../data/permissions';

interface Props {
  listCoursesStore?: ListCoursesStore;
  enrollLearnersWizardStore?: EnrollLearnersWizardStore;
  addInstructorWizardStore?: AddInstructorWizardStore;
  isWorkspace?: boolean;
}

const CoursesListContainer = inject(
  'listCoursesStore',
  'enrollLearnersWizardStore',
  'addInstructorWizardStore',
)(
  observer(
    ({
      listCoursesStore,
      enrollLearnersWizardStore,
      isWorkspace,
      addInstructorWizardStore,
    }: Props) => {
      const [courses, setCourses] = useState<CourseListItem[]>([]);
      const [courseToDelete, setCourseToDelete] = useState<string>();
      const [userPermissions, setUserPermissions] = useState<string[]>([]);
      const [search, setSearch] = useState('');
      const navigate = useNavigate();
      const {organizationId} = useParams();

      useEffect(() => {
        fetchCourses();
        if (organizationId) {
          listCoursesStore!
            .getPermissions(organizationId)
            .then(setUserPermissions);
        }
      }, [listCoursesStore]);

      const onCreateNewCourse = () => {
        navigate(`/manage/${organizationId}/courses/create`);
      };

      const onEdit = (id: string) => {
        navigate(`/manage/${organizationId}/course/${id}/general`);
      };

      const fetchCourses = () => {
        if (organizationId) {
          listCoursesStore!
            .getOrganizationCourses(organizationId)
            .then(setCourses);
        }
      };

      const onStartDelete = (courseId: string) => {
        setCourseToDelete(courseId);
      };

      const onDelete = () => {
        if (organizationId && courseToDelete && !listCoursesStore!.isLoading) {
          listCoursesStore!
            .deleteCourse(organizationId, courseToDelete)
            .then(() => {
              fetchCourses();
              setCourseToDelete(undefined);
            });
        }
      };

      const onOpenFolders = (courseId: string) => {
        navigate(`/editor/${organizationId}/courses/${courseId}/folders`);
      };

      const onAddLearner = (publishedCourseId: string) => {
        enrollLearnersWizardStore?.startWizard(publishedCourseId);
      };

      const onAddInstructor = (courseId: string) => {
        addInstructorWizardStore?.startWizard(courseId);
      };

      const onOpenLearners = (courseId: string) => {
        navigate(`/manage/${organizationId}/learners/general`, {
          state: {courseId},
        });
      };

      const onPreview = (courseId: string) => {
        navigate(`/preview/${organizationId}/course/${courseId}`);
      };

      const getFilteredCourses = () => {
        if (search) {
          return courses.filter(item =>
            item.title.toLowerCase().includes(search.toLowerCase()),
          );
        }
        return courses;
      };

      return (
        <div>
          <CoursesListUI
            onCreateNewCourse={onCreateNewCourse}
            courses={getFilteredCourses()}
            onEdit={onEdit}
            onDelete={onStartDelete}
            onOpenFolders={onOpenFolders}
            onAddLearner={onAddLearner}
            isWorkspace={isWorkspace}
            isLoading={listCoursesStore!.isLoading}
            onOpenLearners={onOpenLearners}
            search={search}
            onSearchChange={setSearch}
            onPreview={onPreview}
            onAddInstructor={onAddInstructor}
            hasAdminPermissions={userPermissions.includes(
              APP_PERMISSIONS.ORG_ASSIGN_INSTRUCOR,
            )}
          />
          <ConfirmationModal
            shown={courseToDelete !== undefined}
            onAccept={onDelete}
            onCancel={() => setCourseToDelete(undefined)}
            title={coursesListText.deleteConfirm}
          />
          <EnrollLearnersWizard />
          <AddInstructorWizard />
        </div>
      );
    },
  ),
);

export default CoursesListContainer;
