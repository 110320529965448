import axios from 'axios';
import {BASE_URL} from './config';
import LocalStorage from '../storage/LocalStorage';

const apiClient = axios.create({
  baseURL: BASE_URL,
});

function isUnauthorizedError(error: any) {
  if (!error || !error?.response || !error?.response?.status) {
    return false;
  }
  const {
    response: {status},
  } = error;
  return status === 401;
}

async function renewToken() {
  const storage = new LocalStorage();
  const refreshToken = await storage.getItemFromStorage(
    LocalStorage.Keys.REFRESH_TOKEN,
  );
  const accessToken = await storage.getItemFromStorage(
    LocalStorage.Keys.ACCESS_TOKEN,
  );

  if (!refreshToken || !accessToken) {
    //TODO log out user
  }

  const refreshPayload = {
    refreshToken: refreshToken,
    accessToken: accessToken,
  };

  const response = await apiClient.post(
    '/authentication/refresh-token',
    refreshPayload,
  );
  const token = response.data.accessToken;
  const newRefreshToken = response.data.refreshToken;

  return [token, newRefreshToken];
}

async function withAuth(headers: any) {
  const storage = new LocalStorage();
  const token = await storage.getItemFromStorage(
    LocalStorage.Keys.ACCESS_TOKEN,
  );

  if (!token) {
    //TODO logout
    return;
  }

  if (!headers) {
    headers = {};
  }

  headers.Authorization = `Bearer ${token}`;

  return headers;
}

let refreshingFunc: any;

apiClient.interceptors.response.use(
  res => ({
    success: true,
    ...res,
    data: res.data,
  }),
  async error => {
    const originalConfig = error.config;
    const storage = new LocalStorage();
    const token = await storage.getItemFromStorage(
      LocalStorage.Keys.ACCESS_TOKEN,
    );

    // if we don't have token in local storage or error is not 401 just return error and break req.
    if (!token || !isUnauthorizedError(error)) {
      if (
        error.response.data.Errors &&
        Array.isArray(error.response.data.Errors)
      ) {
        return Promise.resolve({
          success: false,
          errors: error.response.data.Errors,
        });
      } else {
        return Promise.resolve({
          success: false,
          errors: [error.response.data],
        });
      }
    }

    try {
      if (!refreshingFunc) {
        refreshingFunc = renewToken();
      }

      const [newToken, newRefreshToken] = await refreshingFunc;

      await storage.saveToStorage(LocalStorage.Keys.ACCESS_TOKEN, newToken);
      await storage.saveToStorage(
        LocalStorage.Keys.REFRESH_TOKEN,
        newRefreshToken,
      );

      originalConfig.headers.Authorization = `Bearer ${newToken}`;

      try {
        return await apiClient.request(originalConfig);
      } catch (innerError) {
        if (isUnauthorizedError(innerError)) {
          throw innerError;
        }
      }
    } catch (err) {
      await storage.wipeLocalStorage();
    } finally {
      refreshingFunc = undefined;
    }
  },
);

export {apiClient, withAuth};
