export const videoPlaybackText = {
  onThisCource: 'Unit 7: Conversation',
  multipleChoice: 'Multiple Choice',
  selectAll: 'Select all that apply',
  infoGradable: 'This question is gradable',
  infoPercent: 'Grade needed to pass',
  continue: 'Continue',
  informativeLesson: 'Informative Lesson',
  gradableLesson: 'This is a graded lesson',
  grabableDescription: 'Your answers will count towards your final score.',
  informativeDescription:
    'This lesson is just for learning, without any grading involved.',
  imReady: "I'm ready",
  lessonCompleted: 'Lesson completed!',
  courseHome: 'Course Home',
  nextLesson: 'Next Lesson',
  tryAgain: 'Please try again!',
  finalScore: 'Your final score: ',
  total: 'Total',
  wellDone: 'Well done!',
  publish: 'Publish',
  unpublish: 'Unpublish',
  preview: 'Preview',
  previewMode: 'You are in preview mode',
  question: 'Question',
  back: 'Back',
  retry: 'Retry',
  viewResults: 'View Results',
  challengeBranch: 'Challenge Branch',
  showImage: 'Show Image',
  showQuestion: 'Show question',
  course: 'Course Name: ',
};
