import React, {useCallback, useEffect, useMemo, useState} from 'react';
import EditorLayout from '../../../uiToolkit/Editor/EditorLayout';
import ReactFlow, {
  addEdge,
  useEdgesState,
  useNodesState,
  Controls,
  Background,
} from 'reactflow';
import * as NodeUI from '../nodes';
import EditorHeader from '../../../uiToolkit/Editor/EditorHeader';
import {getLayoutedElements} from './layoutConfiguration';
import EditorOptions from '../../../uiToolkit/Editor/EditorOptions';
import {lessonEditorText} from '../lessonEditorText';
import {VideoSourceOptions} from '../options/VideoSourceOptions';
import {buildNodeTree} from './nodeTreeBuilder';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  LessonContentBase,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import {Lesson} from '../../../data/models/Course/Lesson';
import {ChoiceQuestionOptions} from '../options/ChoiceQuestionOptions';
import {ChoiceTextOptionOptions} from '../options/ChoiceTextOptionOptions';
import {ChoiceImageOptionOptions} from '../options/ChoiceImageOptionOptions';
import {ChoiceHeaderOptions} from '../options/ChoiceHeaderOptions';
import {MChoiceOptions} from '../options/MChoiceOptions';
import './styles.css';
import 'reactflow/dist/style.css';
import {Course} from '../../../data/models/Course/Course';
import {ChallengeQuestionOptions} from '../options/ChallengeQuestionOptions';
import {ChallengeTextOptionOptions} from '../options/ChallengeTextOptionOptions';
import {ChallengeImageOptionOptions} from '../options/ChallengeImageOptionOptions';
import EditorSidebarNavigation from '../../../uiToolkit/Editor/EditorSidebarNavigation';
import ChallengeBranchAIOptionsContainer from '../options/ChallengeBranchAIOptions/container/ChallengeBranchAIOptionsContainer';
import {ActiveTabs} from '../../../data/models/UI/ActiveTabs';
import {VimeoVideoSourceOptions} from '../options/VimeoVideoSourceOptions';

const OPTIONS_TAB = 'Options';

interface Props {
  onBack: () => void;

  selectedItem?: LessonContentBase;
  selectedItemType?: number;
  lessonContent?: LessonContentList;
  onClearSelectedNode: () => void;
  onChangesMade: () => void;
  lesson?: Lesson;
  onPreview: () => void;
  onPublish: () => void;
  course?: Course;
  lessonIsPublisehd?: boolean;
  breadcrumbs: {title: string; link?: string}[];
  sidebarOptions: any;
  activeTabs: ActiveTabs;
  onSearch: (search: string) => void;
}

const CustomEditorUI = React.memo(
  ({
    onBack,

    selectedItem,
    selectedItemType,
    lessonContent,
    onClearSelectedNode,
    onChangesMade,
    lesson,
    onPreview,
    onPublish,
    course,
    lessonIsPublisehd,
    breadcrumbs,
    sidebarOptions,
    activeTabs,
    onSearch,
  }: Props) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [showSidebar, setShowSidebar] = useState(true);

    useEffect(() => {
      if (lessonContent) {
        setNodes([]);
        setEdges([]);
        const {nodes, edges} = buildNodeTree(
          lesson?.title || '',
          lessonContent,
          false,
        );
        const {nodes: layoutedNodes, edges: layoutedEdges} =
          getLayoutedElements(nodes, edges);
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      }
    }, [lessonContent, lesson, lessonIsPublisehd]);

    const nodeTypes = useMemo(
      () => ({
        elementCard: NodeUI.CardNode,
        entryNode: NodeUI.EntryNode,
        addNewSourceNode: NodeUI.AddNewSourceNode,
        choiceHeader: NodeUI.ChoiceHeader,
        choiceQuestion: NodeUI.ChoiceQuestion,
        choiceTextOption: NodeUI.ChoiceTextOption,
        choiceImageNode: NodeUI.ChoiceImageNode,
        mChoiceNode: NodeUI.MChoiceNode,
        challangeBranchHeader: NodeUI.ChallengeBranchHeaderNode,
        challengeBranchQuestionNode: NodeUI.ChallengeBranchQuestionNode,
        challengeBranchTextNode: NodeUI.ChallengeBranchTextNode,
        challengeBranchImageNode: NodeUI.ChallengeBranchImageNode,
        challengeBranchAINode: NodeUI.ChallengeBranchAINode,
        challengeBranchAICorrectNode: NodeUI.ChallengeBranchAICorrectNode,
        challengeBranchAIInorrectNode: NodeUI.ChallengeBranchAIInorrectNode,
      }),
      [],
    );

    const onConnect = useCallback(
      (params: any) => setEdges(eds => addEdge(params, eds)),
      [setEdges],
    );

    return (
      <div className="EditorRoot">
        <EditorLayout
          isPublished={course?.isPublished}
          onContentClick={onClearSelectedNode}
          showSidebar={showSidebar}
          sidebarSet={() => setShowSidebar(!showSidebar)}
          header={
            <EditorHeader
              breadcrumbs={breadcrumbs}
              onBack={onBack}
              nextTitle={lessonEditorText.preview}
              onNext={onPreview}
              onPublish={onPublish}
            />
          }
          sideBar={
            <EditorSidebarNavigation
              onSearchKeyChange={onSearch}
              sidebarOptions={sidebarOptions}
              activeTabs={activeTabs}
            />
          }
          content={
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              fitView={lessonContent?.contentList.rootId !== null}
              defaultViewport={
                lessonContent?.contentList.rootId === null
                  ? {
                      x: window.innerWidth / 3,
                      y: 40,
                      zoom: 0.8,
                    }
                  : undefined
              }>
              <Controls showInteractive={false} />
              <Background />
            </ReactFlow>
          }
          options={
            <EditorOptions
              tabs={[{tabName: OPTIONS_TAB}]}
              entityToEdit={selectedItem}
              entityType={selectedItemType?.toString()}
              emptyStateText={lessonEditorText.emptyState}>
              <VideoSourceOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={VIDEO_NODE_TYPE.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <VideoSourceOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={OVERLAY_VIDEO_TYPE.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <VimeoVideoSourceOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={VIMEO_VIDEO_TYPE.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <VimeoVideoSourceOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={OVERLAY_VIMEO_VIDEO_TYPE.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChoiceQuestionOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHOICE_TEXT_QUESTION.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChoiceTextOptionOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHOICE_TEXT_OPTION.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChoiceImageOptionOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHOICE_IMAGE_OPTION.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChoiceHeaderOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHOICE_HEADER_NODE_TYPE.toString()}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChoiceHeaderOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHALLENGE_BRANCH_HEADER.toString()}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
                isChallenge
              />
              <MChoiceOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={MULTIPLE_CHOICE.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChallengeQuestionOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHALLENGE_BRANCH_QUESTION.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChallengeTextOptionOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHALLENGE_BRANCH_TEXT_OPTION.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChallengeImageOptionOptions
                contentForTab={OPTIONS_TAB}
                optionsForType={CHALLENGE_BRANCH_IMAGE_OPTION.toString()}
                onCancel={onClearSelectedNode}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
              <ChallengeBranchAIOptionsContainer
                contentForTab={OPTIONS_TAB}
                optionsForType={CHALLENGE_BRANCH_AI.toString()}
                onChangesMade={onChangesMade}
                isPublished={lessonIsPublisehd}
              />
            </EditorOptions>
          }
          customContent
        />
      </div>
    );
  },
);

export default CustomEditorUI;
