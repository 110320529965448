import React, {useEffect, useState} from 'react';
import AddVideoSourceWizardStore from '../store/AddVideoSourceWizardStore';
import {inject, observer} from 'mobx-react';
import {CROP_VIDEO_STEP, PICK_VIDEO_STEP} from '../steps';
import PickVideoStepUI from '../view/PickVideoStepUI';
import CropVideoStepUI from '../view/CropVideoStepUI';
import {useParams} from 'react-router-dom';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';

interface Props {
  addVideoSourceWizardStore?: AddVideoSourceWizardStore;
}

const PAGE_SIZE = 10;

const AddVideoSourceWizardContainer = inject('addVideoSourceWizardStore')(
  observer(({addVideoSourceWizardStore}: Props) => {
    const [files, setFiles] = useState<MediaFile[]>([]);
    const [vimeoFiles, setVimeoFiles] = useState<VimeoMediaFile[]>([]);
    const [pages, setPages] = useState(0);
    const [storageLimit, setStorageLimit] = useState(0);
    const {organizationId, lessonId} = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOptions, setSortOptions] = useState({
      column: 'file_name',
      order: 'desc',
    });

    useEffect(() => {
      fetchVideos();
      if (organizationId) {
        addVideoSourceWizardStore!
          .getStorageLimit(organizationId)
          .then(setStorageLimit);
        addVideoSourceWizardStore!
          .getVimeoVideos(organizationId, 1, 10)
          .then(setVimeoFiles);
      }
    }, [currentPage, sortOptions]);

    const fetchVideos = () => {
      if (organizationId) {
        addVideoSourceWizardStore
          ?.getMediaFiles(
            currentPage - 1,
            organizationId,
            PAGE_SIZE,
            sortOptions.column,
            sortOptions.order === 'asc',
          )
          .then(res => {
            setFiles(res.data || []);
            setPages(res.pagesCount);
          });
      }
    };

    const onSave = async (start: number, end: number) => {
      const file = addVideoSourceWizardStore!.getSelectedFile();
      if (organizationId && lessonId && file) {
        await addVideoSourceWizardStore!.addLessonVideoNode(
          organizationId,
          lessonId,
          file,
          start,
          end,
        );
      }
      const vimeoFile = addVideoSourceWizardStore!.getSelectedVimeoFile();
      if (organizationId && lessonId && vimeoFile) {
        await addVideoSourceWizardStore!.addLessonVimeoNode(
          organizationId,
          lessonId,
          vimeoFile,
          start,
          end,
        );
      }
    };

    const onVideoUpload = (video: File, duration: number) => {
      if (organizationId) {
        addVideoSourceWizardStore
          ?.uploadLibraryVideo(organizationId, video, duration)
          .then(fetchVideos);
      }
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    const onFileSelected = (file: MediaFile) => {
      addVideoSourceWizardStore!.selectFile(file);
    };

    const onVimeoFileSelect = (file: VimeoMediaFile) => {
      addVideoSourceWizardStore!.selectVimeoFile(file);
    };

    const onCancel = () => {
      setCurrentPage(1);
      addVideoSourceWizardStore!.cancelWizard();
    };

    const onSort = (selectedColumn: any, sortOrder: any) => {
      setSortOptions({column: selectedColumn.sortField, order: sortOrder});
    };

    switch (addVideoSourceWizardStore?.getActiveStep()) {
      case PICK_VIDEO_STEP:
        return (
          <PickVideoStepUI
            onVideoUpload={onVideoUpload}
            isLoading={addVideoSourceWizardStore.isLoading}
            files={files}
            onFileSelect={onFileSelected}
            onCancel={onCancel}
            pages={pages}
            onPageChange={onPageChange}
            storageLimit={storageLimit}
            onSort={onSort}
            vimeoFiles={vimeoFiles}
            onVimeoFileSelect={onVimeoFileSelect}
          />
        );
      case CROP_VIDEO_STEP:
        return (
          <CropVideoStepUI
            selectedFile={addVideoSourceWizardStore.getSelectedFile()}
            selectedVimeoFile={addVideoSourceWizardStore.getSelectedVimeoFile()}
            onCancel={onCancel}
            onSave={onSave}
          />
        );
      default:
        return null;
    }
  }),
);

export default AddVideoSourceWizardContainer;
