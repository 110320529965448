export const lessonEditorText = {
  emptyState: 'Select element to edit',
  source: 'Source:',
  startTime: 'Start time',
  endTime: 'End time',
  title: 'Title:',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  question: 'Question:',
  questionPlaceholder: 'Choice question',
  option: 'Option:',
  deleteAnswer: 'Delete answer',
  gradeWeight: 'Grade Weight',
  isCorrect: 'Correct Answer?',
  areYouSureDeleteVideo:
    'Delete this video source and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceQuestion:
    'Delete this choice question and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceTextOption:
    'Delete this choice text option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChallengeTextOption:
    'Delete this challenge text option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceImageOption:
    'Delete this choice image option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChallengeImageOption:
    'Delete this challenge image option and its child nodes? (Cannot be undone)',
  areYouSureDeleteChoiceHeaderOption:
    'Delete this choice branch and its child nodes? (Cannot be undone)',
  areYouSureDeleteChallengeHeaderOption:
    'Delete this challenge branch and its child nodes? (Cannot be undone)',
  choiceBranchHeaderText: 'Choice Branch',
  challangeBranchHeaderText: 'Challenge Branch',
  multipleChoiceBranchHeaderText: 'Multiple choice',
  editSource: 'Edit source',
  preview: 'Preview',
  answerTitle: 'Answers:',
  answerPlaceholder: 'Add multiple choice answer',
  areYouSureDeleteMChoice:
    'Delete this multiple choice question and its child nodes? (Cannot be undone)',
  videoNodeTooltip: 'Video Source',
  imageNodeTooltip: 'Image Source',
  choiceImageNodeTooltip: 'Choice Option Image',
  choiceTextNodeTooltip: 'Choice Option Text',
  choiceNodeTooltip: 'Choice Branch',
  choiceQuestionNodeTooltip: 'Choice Branch Question',
  mChoiceNodeTooltip: 'Multiple Choice Question',
  comingSoon: 'Coming Soon',
  disclaimer:
    '*Maximum 4, with at least 1 answer correct. No duplicate answers',
  published: 'Published',
  addAnswer: 'Add answer',
  challengeBranchQuestionTooltip: 'Challenge branch question',
  challengeBranchTextOption: 'Challenge text answer',
  challengeBranchImageOption: 'Challenge image answer',
  challengeBranchAITooltip: 'Challenge Branch AI',
  cantProceedTooltip: 'New nodes cannot be added to a published lesson',
  videoOverlay: 'Video Source with Overlay',
};
