import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import LtiSettingsUI from '../view/LtiSettingsUI';
import AddPlatformModal from '../components/AddPlatformModal';
import {useParams} from 'react-router-dom';
import LtiSettingsStore from '../store/LtiSettingsStore';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {LtiPlatform, LtiTool} from '../../../../data/models/Lti/LtiIntegration';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {settingsText} from '../../settingsText';
import {SubscriptionPermissions} from '../../../../data/models/Subscription/SubscriptionPermission';

interface Props {
  ltiSettingsStore?: LtiSettingsStore;
}

const LtiSettingsContainer = inject('ltiSettingsStore')(
  observer(({ltiSettingsStore}: Props) => {
    const [openAddPLatform, setOpenAddPlatform] = useState(false);
    const [toolData, setToolData] = useState<LtiTool>();
    const [platforms, setPlatforms] = useState<LtiPlatform[]>([]);
    const [platformToEdit, setPlatformToEdit] = useState<LtiPlatform>();
    const [platformToDeleteId, setPlatformToDeleteId] = useState<string>();
    const [availableFeatures, setAvailableFeatures] =
      useState<SubscriptionPermissions>();
    const {organizationId} = useParams();

    useEffect(() => {
      initData();
      fetchPlatforms();
      if (organizationId) {
        ltiSettingsStore!
          .getOrganizationAvailableFeatures(organizationId)
          .then(setAvailableFeatures);
      }
    }, []);

    const initData = async () => {
      if (organizationId) {
        const toolData = await ltiSettingsStore!.getToolData(organizationId);
        setToolData(toolData);
      }
    };

    const onSetupLti = async () => {
      if (organizationId) {
        await ltiSettingsStore!.generateKeys(organizationId);
        const toolData = await ltiSettingsStore!.getToolData(organizationId);
        if (!toolData) {
          toast.error(getErrorFromCode(''), toastConfig);
          return;
        }
        setToolData(toolData);
      }
    };

    const onAddPlatform = () => {
      setOpenAddPlatform(true);
    };

    const fetchPlatforms = () => {
      if (organizationId) {
        ltiSettingsStore!.getPlatforms(organizationId).then(setPlatforms);
      }
    };

    const onCreatePlatform = (platform: LtiPlatform) => {
      if (organizationId) {
        ltiSettingsStore!
          .addPlatform(organizationId, platform)
          .then(success => {
            if (success) {
              fetchPlatforms();
              setOpenAddPlatform(false);
            }
          });
      }
    };

    const onEditPlatform = (platform: LtiPlatform) => {
      if (organizationId && platform.id) {
        ltiSettingsStore!
          .editPlatform(organizationId, platform.id, platform)
          .then(success => {
            if (success) {
              fetchPlatforms();
              setPlatformToEdit(undefined);
            }
          });
      }
    };

    const onOpenDelete = (platformId: string) => {
      setPlatformToDeleteId(platformId);
    };

    const onDeltePlatform = () => {
      if (organizationId && platformToDeleteId) {
        ltiSettingsStore!
          .deletePlatform(organizationId, platformToDeleteId)
          .then(success => {
            if (success) {
              fetchPlatforms();
              setPlatformToDeleteId(undefined);
            }
          });
      }
    };

    const onEditIntegration = (platform: LtiPlatform) => {
      setPlatformToEdit(platform);
    };

    const onClosePlatformModal = () => {
      setOpenAddPlatform(false);
      setPlatformToEdit(undefined);
    };

    return (
      <>
        <LtiSettingsUI
          onOpenAddPlatform={onAddPlatform}
          toolData={toolData}
          isLoading={ltiSettingsStore!.isLoading}
          onSetupLti={onSetupLti}
          platforms={platforms}
          onEditIntegration={onEditIntegration}
          onDeletePlatform={onOpenDelete}
          ltiAvailableFeature={
            availableFeatures && availableFeatures.permissions.lti_ontegration
          }
        />
        {openAddPLatform && (
          <AddPlatformModal
            onClose={onClosePlatformModal}
            onCreatePlatform={onCreatePlatform}
          />
        )}
        {platformToEdit && (
          <AddPlatformModal
            onClose={onClosePlatformModal}
            platformToEdit={platformToEdit}
            onEditPlatform={onEditPlatform}
          />
        )}
        <ConfirmationModal
          title={settingsText.areYouSureDeletePlatform}
          acceptText={settingsText.delete}
          onAccept={onDeltePlatform}
          onCancel={() => setPlatformToDeleteId(undefined)}
          shown={platformToDeleteId !== undefined}
        />
      </>
    );
  }),
);

export default LtiSettingsContainer;
