import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import {CourseViewModel} from '../../../../data/models/Course/Course';
import SubscriptionRepository from '../../../../data/repositories/SubscriptionRepository';

class CreateCourseStore {
  private courseRepo = new CoursesRepository();
  private subscriptionRepo = new SubscriptionRepository();

  public errors: string[] = [];
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getLearningStandarts(organizationId: string) {
    const res = await this.courseRepo.getLearingStandarts(organizationId);
    if (res.success && res.data) {
      return res.data;
    }
    return [];
  }

  public async createCourse(model: CourseViewModel, organizationId: string) {
    this.isLoading = true;
    const result = await this.courseRepo.createCourse(model, organizationId);
    this.isLoading = false;
    if (!result.success && result.errors) {
      this.errors = result.errors;
    }
    return result.data;
  }

  public async getOrganizationAvailableFeatures(organizationId: string) {
    const result =
      await this.subscriptionRepo.getSubscriptionPermissions(organizationId);
    if (result.success && result.data) {
      return result.data;
    }
  }

  public clearErrors() {
    this.errors = [];
  }
}

export default CreateCourseStore;
