import {
  LessonContentChoiceAnswerMapped,
  LessonContentChoiceMapped,
} from '../../data/models/LessonContent/LessonConentMapped';
import {
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_AI_CORRECT,
  CHALLENGE_BRANCH_AI_INCORRECT,
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
  LessonContentChallengeBranchAI,
  LessonContentChallengeBranchImageOption,
  LessonContentChallengeBranchQuestion,
  LessonContentChallengeBranchTextOption,
  LessonContentChoiceImageOption,
  LessonContentChoiceQuestion,
  LessonContentChoiceTextOption,
  LessonContentVideo,
} from '../../data/models/LessonContent/LessonContentBase';
import {
  LessonContentItem,
  LessonContentList,
} from '../../data/models/LessonContent/LessonContentList';

export const getFirstLessonStep = (content: LessonContentList) => {
  return content.contentList?.items[content.contentList.rootId]
    .data as LessonContentVideo;
};

export const getLessonRoot = (content: LessonContentList) => {
  return content.contentList?.items[content.contentList.rootId];
};

export const getNextLessonNode = (
  content: LessonContentList,
  currentNode: LessonContentItem,
): LessonContentItem | null => {
  const childId = currentNode.childIds ? currentNode.childIds[0] : undefined;
  if (!childId) return null;
  const nextNode = content.contentList?.items[childId];
  if (nextNode && nextNode.data.ivNodeType === CHOICE_HEADER_NODE_TYPE) {
    return getChoiceBranchMappedNode(nextNode, content);
  } else if (nextNode && nextNode.data.ivNodeType === CHALLENGE_BRANCH_HEADER) {
    return getChallengeBranchMappedNode(nextNode, content);
  } else if (nextNode && nextNode.data.ivNodeType === CHALLENGE_BRANCH_AI) {
    return getChallengeBranchAIMapped(nextNode, content);
  } else if (nextNode) {
    return nextNode;
  }
  return null;
};

export const getNextNodeById = (id: string, content: LessonContentList) => {
  const nextNode = content.contentList?.items[id];
  if (!nextNode) return null;
  return nextNode;
};

const getChoiceBranchMappedNode = (
  nextNode: LessonContentItem,
  content: LessonContentList,
) => {
  //question
  const choiceHeaderChildId =
    nextNode.childIds && nextNode.childIds[0] ? nextNode.childIds[0] : null;
  if (!choiceHeaderChildId) return null;
  const choiceQuestionNode = content.contentList?.items[choiceHeaderChildId];

  //answers
  const answersIds = choiceQuestionNode?.childIds;
  if (!answersIds || answersIds.length === 0) return null;

  const answersNodes: LessonContentChoiceAnswerMapped[] = [];
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (answer && (answer.data as LessonContentChoiceTextOption)?.title) {
      answersNodes.push({
        type: answer.data.ivNodeType,
        text: (answer.data as LessonContentChoiceTextOption)?.title,
        childId:
          answer.childIds && answer.childIds.length > 0
            ? answer.childIds[0]
            : undefined,
        id: answer.data.internalId,
      });
    }
    if (answer && (answer.data as LessonContentChoiceImageOption)?.contentUrl) {
      answersNodes.push({
        type: answer.data.ivNodeType,
        imageSource: (answer.data as LessonContentChoiceImageOption)
          ?.contentUrl,
        childId:
          answer.childIds && answer.childIds.length > 0
            ? answer.childIds[0]
            : undefined,
        id: answer.data.internalId,
      });
    }
  });

  const choiceMapped: LessonContentChoiceMapped = {
    internalId: nextNode.data.internalId,
    ivNodeType: nextNode.data.ivNodeType,
    question: (choiceQuestionNode?.data as LessonContentChoiceQuestion).title,
    answers: answersNodes,
  };

  return {
    parentsIds: [],
    data: choiceMapped,
    childIds: [],
  };
};

const getChallengeBranchMappedNode = (
  nextNode: LessonContentItem,
  content: LessonContentList,
) => {
  //question
  const challengeHeaderChildId =
    nextNode.childIds && nextNode.childIds[0] ? nextNode.childIds[0] : null;
  if (!challengeHeaderChildId) return null;
  const challengeQuestionNode =
    content.contentList?.items[challengeHeaderChildId];

  //answers
  const answersIds = challengeQuestionNode?.childIds;
  if (!answersIds || answersIds.length === 0) return null;

  const answersNodes: LessonContentChoiceAnswerMapped[] = [];
  answersIds.forEach(answerId => {
    const answer = content.contentList?.items[answerId];
    if (
      answer &&
      (answer.data as LessonContentChallengeBranchTextOption)?.answer
    ) {
      answersNodes.push({
        type: answer.data.ivNodeType,
        text: (answer.data as LessonContentChallengeBranchTextOption)?.answer,
        childId:
          answer.childIds && answer.childIds.length > 0
            ? answer.childIds[0]
            : undefined,
        isCorrect: (answer.data as LessonContentChallengeBranchTextOption)
          ?.isCorrect,
        id: answer.data.internalId,
      });
    }
    if (
      answer &&
      (answer.data as LessonContentChallengeBranchImageOption)?.contentUrl
    ) {
      answersNodes.push({
        type: answer.data.ivNodeType,
        imageSource: (answer.data as LessonContentChallengeBranchImageOption)
          ?.contentUrl,
        childId:
          answer.childIds && answer.childIds.length > 0
            ? answer.childIds[0]
            : undefined,
        isCorrect: (answer.data as LessonContentChallengeBranchImageOption)
          ?.isCorrect,
        id: answer.data.internalId,
      });
    }
  });

  const choiceMapped: LessonContentChoiceMapped = {
    internalId: nextNode.data.internalId,
    ivNodeType: nextNode.data.ivNodeType,
    question: (
      challengeQuestionNode?.data as LessonContentChallengeBranchQuestion
    ).question,
    answers: answersNodes,
  };

  return {
    parentsIds: [],
    data: choiceMapped,
    childIds: [],
  };
};

const getChallengeBranchAIMapped = (
  nextNode: LessonContentItem,
  content: LessonContentList,
) => {
  let correctId = '';
  let incorrectId = '';
  let correctChildId = '';
  let incorrectChildId = '';

  nextNode.childIds.forEach(id => {
    if (
      content.contentList.items[id] &&
      content.contentList.items[id].data.ivNodeType ===
        CHALLENGE_BRANCH_AI_CORRECT
    ) {
      correctId = id;
      if (content.contentList.items[id]?.childIds?.length) {
        correctChildId = content.contentList.items[id]?.childIds[0];
      }
    }
    if (
      content.contentList.items[id] &&
      content.contentList.items[id].data.ivNodeType ===
        CHALLENGE_BRANCH_AI_INCORRECT
    ) {
      incorrectId = id;
      if (content.contentList.items[id]?.childIds?.length) {
        incorrectChildId = content.contentList.items[id]?.childIds[0];
      }
    }
  });

  const result = {
    ...(nextNode.data as LessonContentChallengeBranchAI),
    incorrectAnswerPathId: incorrectId,
    correctAnswerPathId: correctId,
    correctChildId,
    incorrectChildId,
  };

  return {
    parentsIds: [],
    data: result,
    childIds: [],
  };
};
