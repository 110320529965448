import React from 'react';
import {TapyblPlayer} from '../../../modules/TapyblPlayer';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {GradeResult} from '../../../modules/TapyblPlayer/models';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import './styles.css';

interface Props {
  isLoading: boolean;
  lessonContent: LessonContentList;
  onFinish: () => void;
  passingScore: number;
  isGradable: boolean;
  gradeResult?: GradeResult;
  onFinishLesson: (progress: LearnerProgressModel) => void;
}

const LTIPlayerUI = React.memo(
  ({
    isLoading,
    lessonContent,
    onFinish,
    passingScore,
    isGradable,
    gradeResult,
    onFinishLesson,
  }: Props) => {
    return (
      <div className="LTIPlayerContainer">
        <TapyblPlayer
          preview={false}
          isLoading={isLoading}
          lessonContent={lessonContent}
          onCourseHome={onFinish}
          onContinue={onFinish}
          passingScore={passingScore}
          isGradable={isGradable}
          nextLessonIsTheSame={false}
          gradeResult={gradeResult}
          onFinishedLesson={onFinishLesson}
          fitHeight
          skipFinishButtons
        />
      </div>
    );
  },
);

export default LTIPlayerUI;
