import React, {useEffect, useState} from 'react';
import AdminPanelUI from '../view/AdminPanelUI';
import {inject, observer} from 'mobx-react';
import AdminPanelStore from '../store/AdminPanelStore';
import {useNavigate} from 'react-router-dom';

interface Props {
  adminPanelStore?: AdminPanelStore;
}

const AdminPanelContainer = inject('adminPanelStore')(
  observer(({adminPanelStore}: Props) => {
    const navigate = useNavigate();
    const [statistic, setStatistic] = useState<any>();

    useEffect(() => {
      adminPanelStore?.getAnalytics().then(res => {
        if (res.data) {
          setStatistic(res.data);
        } else {
          navigate('/', {replace: true});
        }
      });
    }, []);

    const logout = () => {
      adminPanelStore!.logout().then(() => {
        navigate('/', {replace: true});
      });
    };

    return <AdminPanelUI statistic={statistic} logout={logout} />;
  }),
);
export default AdminPanelContainer;
