import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import LearnerInvitationsUI from '../view/LearnerInvitationsUI';
import {useNavigate, useParams} from 'react-router-dom';
import LearnersStore from '../../store/LearnersStore';
import {LearnerInvitation} from '../../../../data/models/Learners/LearnerInvitation';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {learnerInvitationsText} from '../learnerInvitationsText';
import NavigationStore from '../../../../navigation/store/NavigationStore';

interface Props {
  learnersStore?: LearnersStore;
  navigationStore?: NavigationStore;
}

const PAGE_SIZE = 10;

const LearnersInvitationsContainer = inject(
  'learnersStore',
  'navigationStore',
)(
  observer(({learnersStore, navigationStore}: Props) => {
    const [search, setSearch] = useState('');
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [invitations, setInvitations] = useState<LearnerInvitation[]>([]);
    const [idToDelete, setIdToDelete] = useState<string>();

    const navigate = useNavigate();
    const {organizationId} = useParams();

    useEffect(() => {
      fetchInvitations();
    }, [search, currentPage]);

    const fetchInvitations = () => {
      if (organizationId) {
        learnersStore!
          .getLearnersInvitations(
            organizationId,
            currentPage - 1,
            PAGE_SIZE,
            search,
          )
          .then(res => {
            setInvitations(res.data);
            setPages(res.pagesCount);
          });
      }
    };

    const onAddNewLearners = () => {
      navigate(`/manage/${organizationId}/learners/create`);
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    const onShowDelete = (id: string) => {
      setIdToDelete(id);
    };

    const onDelete = () => {
      if (idToDelete && organizationId) {
        learnersStore!
          .deleteLearnerInvitation(organizationId, idToDelete)
          .then(success => {
            if (success) {
              navigationStore!.getOrganizationUsersCount(organizationId);
              fetchInvitations();
            }
          })
          .finally(() => {
            setIdToDelete(undefined);
          });
      }
    };

    return (
      <>
        <LearnerInvitationsUI
          onAddNewLearners={onAddNewLearners}
          search={search}
          setSearch={setSearch}
          invitations={invitations}
          onPageChange={onPageChange}
          pages={pages}
          onDelete={onShowDelete}
        />
        <ConfirmationModal
          shown={idToDelete !== undefined}
          title={learnerInvitationsText.areYouSure}
          onAccept={onDelete}
          onCancel={() => setIdToDelete(undefined)}
        />
      </>
    );
  }),
);

export default LearnersInvitationsContainer;
