import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import MyLibraryModalUI from '../view/MyLibraryModalUI';
import MyLibraryModalStore from '../store/MyLibraryModalStore';
import {useParams} from 'react-router-dom';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../data/models/LibraryFile/MediaFile';

interface Props {
  myLibraryModalStore?: MyLibraryModalStore;
}

const PAGE_SIZE = 10;

const MyLibraryModalContainer = inject('myLibraryModalStore')(
  observer(({myLibraryModalStore}: Props) => {
    const [files, setFiles] = useState<MediaFile[]>([]);
    const [vimeoFiles, setVimeoFiles] = useState<VimeoMediaFile[]>([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [storageLimit, setStorageLimit] = useState(0);
    const [sortOptions, setSortOptions] = useState({
      column: 'file_name',
      order: 'desc',
    });
    const {organizationId} = useParams();

    useEffect(() => {
      fetchFiles();
      if (organizationId) {
        myLibraryModalStore!
          .getStorageLimit(organizationId)
          .then(setStorageLimit);
      }
    }, [myLibraryModalStore!.isVisible(), currentPage, sortOptions]);

    const fetchFiles = () => {
      if (organizationId) {
        myLibraryModalStore
          ?.getMediaFiles(
            currentPage - 1,
            organizationId,
            PAGE_SIZE,
            sortOptions.column,
            sortOptions.order === 'asc',
          )
          .then(res => {
            setFiles(res.data || []);
            setPages(res.pagesCount);
          });
        myLibraryModalStore
          ?.getVimeoFiles(organizationId, 1, 10)
          .then(setVimeoFiles);
      }
    };

    const onFileUpload = (file: File, duration: number) => {
      if (organizationId) {
        myLibraryModalStore!
          .uploadLibraryFile(organizationId, file, duration)
          .then(fetchFiles);
      }
    };

    const onPageChange = (page: number) => {
      setCurrentPage(page);
    };

    const onSort = (selectedColumn: any, sortOrder: any) => {
      setSortOptions({column: selectedColumn.sortField, order: sortOrder});
    };

    return myLibraryModalStore!.isVisible() ? (
      <MyLibraryModalUI
        isLoading={myLibraryModalStore!.isLoading}
        onCancel={() => myLibraryModalStore!.closeLibrary()}
        files={files}
        type={myLibraryModalStore!.getType()}
        onFileSelect={(file: MediaFile) =>
          myLibraryModalStore!.onFileSelect(file)
        }
        onVimeoFileSelect={(file: VimeoMediaFile) => {
          myLibraryModalStore!.onFileSelect(undefined, file);
        }}
        onFileUpload={onFileUpload}
        pages={pages}
        onPageChange={onPageChange}
        vimeoFiles={vimeoFiles}
        storageLimit={storageLimit}
        onSort={onSort}
      />
    ) : null;
  }),
);

export default MyLibraryModalContainer;
