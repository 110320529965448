import React, {useEffect, useState} from 'react';
import UserProfileRootUI from '../view/UserProfileRootUI';
import {inject, observer} from 'mobx-react';
import NavigationStore from '../../../store/NavigationStore';
import {UserInfo} from '../../../../data/models/User/UserInfo';

interface Props {
  navigationStore?: NavigationStore;
}

const UserProfileRootContainer = inject('navigationStore')(
  observer(({navigationStore}: Props) => {
    const [profileData, setProfileData] = useState<UserInfo>();

    useEffect(() => {
      navigationStore!.getUserInfo().then(data => {
        if (data) {
          setProfileData(data);
        }
      });
    }, [navigationStore]);

    return <UserProfileRootUI profileData={profileData} />;
  }),
);

export default UserProfileRootContainer;
