import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';

class OpenWorkspaceStore {
  private organizationRepo = new OrganizationRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserOrganizations() {
    const res = await this.organizationRepo.getMyOrganizations();
    if (res.success && res.data) {
      return res.data;
    }
    return [];
  }
}

export default OpenWorkspaceStore;
