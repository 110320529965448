import {intervalToDuration} from 'date-fns';

export const getTimeTextFromSeconds = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: Math.ceil(seconds) * 1000,
  });
  const zeroPad = (num: any) => String(num).padStart(2, '0');
  return [duration.minutes, duration.seconds].map(zeroPad).join(':');
};

export const getSecondsFromTimeText = (time: string) => {
  const timeParts = time.split(':');
  const seconds = parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1]);
  return seconds;
};

export const getMinutesFromSeconds = (seconds: number) => {
  return Math.ceil(seconds / 60);
};
