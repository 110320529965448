export const settingsText = {
  title: 'Settings',
  save: 'Save',
  createRole: 'Create a new role',
  newRole: 'Create a New Role',
  newRoleSubtitle:
    'When creating a new role, ensure you assign the necessary permissions based on your specific criteria.',
  roleNameTitle: 'Role name',
  roleNamePlaceholder: 'Enter a New Role Name',
  permissions: 'Permissions',
  cancel: 'Cancel',
  edit: 'Edit',
  editRoleSubtitle:
    'Caution: Please be aware that you are editing permissions that can impact users.',
  editRole: 'Edit user role',
  deleteUser: 'Delete user',
  activateUser: 'Activate user',
  areYouSure: 'Are you sure you want to delete ',
  username: 'Name',
  email: 'Email',
  type: 'Type',
  status: 'Status',
  actions: 'Actions',
  invite: 'Invite new users to your workspace',
  inviteSubtitle: 'Add users and assign their roles.',
  userEmail: 'User Email',
  role: 'Role',
  selectRole: 'Select user role',
  inviteAction: 'Invite',
  youSureDeleteMember: 'Are you sure you want to delete this Workspace member?',
  staffEditSubtitle: 'Assign user a different role',
  memberDeleted: 'User deleted',
  memberActivated: 'User activated',
  somethingWentWrong: 'Something went wrong',
  ltiPrompt:
    'Enhance your educational experience effortlessly with our LTI integration. Seamlessly connect external tools and resources to your learning management system, simplifying access and fostering a cohesive learning environment for both educators and students.',
  setupLti: 'Setup LTI Integration now',
  newLtiIntegration: 'New LTI Platform integration',
  ltiName: 'Name',
  ltiNamePlaceholder: 'Platform Name',
  platformType: 'LMS System',
  platformTypePlaceholder: 'Select your LMS system',
  accessTokenUrl: 'Access Token Url',
  accessTokenPlaceholder: 'Access token Url (can be found in your LMS)',
  authorizeUrl: 'Authorize Url',
  authorizeUrlPlaceholder: 'Authorize Url (can be found in your LMS)',
  issuer: 'Issuer',
  issuerPlaceholder: 'Issuer (can be found in your LMS)',
  jwkSetUrl: 'JWK Set Url',
  jwkSetUrlPlaceholder: 'JWK Set Url (can be found in your LMS)',
  clientId: 'Client Id',
  clientIdPlaceholder: 'Client Id can be found in your LMS)',
  toolDataTitle: 'Tapybl LTI Tool',
  loginUrl: 'Login URL:',
  redirectionUrl: 'Redirection URL:',
  publicKeySetUrl: 'Public Key Set URL:',
  yourPlatforms: 'Your platforms:',
  addNewPlatform: 'Add new platform',
  noPlatforms: "Looks like you don't have any platforms configured yet",
  ltiConnection: 'LTI connection',
  currentPlan: 'Current plan',
  basicPlan: 'Basic Plan',
  upgradeNow: 'Upgrade now!',
  editIntegration: 'Edit Integration',
  deleteIntegration: 'Delete Integration',
  areYouSureDeletePlatform: 'Are you sure you want to delete this platform?',
  delete: 'Delete',
  copyEmail: 'Copy Email',
  expirationDate: 'Subscription expiration date',
  cardDetails: 'Card details',
  unscubscribeInfo:
    'If you wish, you have the option to unsubscribe from your current Plan.',
  unsubscribe: 'Unsubscribe',
  areYouSureCancelSubscription:
    'Are you sure you want to cancel your subscription?',
  trialSubscription: 'Trial Subscription',
  upgradeToUseLti: 'Upgrade Subscription Plan To Use Lti',
  changePaymentMethodInfo:
    'If you need to update your card or change it, click the Link below',
  changePaymentMethod: 'Update Payment Method',
  invoices: 'Invoices',
  otherActions: 'Other Actions',
  viewPdf: 'View PDF',
  setupVimeoStorage: 'Setup Vimeo Integration',
  vimeoClientSecret: 'Vimeo Client Secret',
  vimeoClientPlaceholder:
    'Client Secret (you can find it in vimeo account settings)',
  vimeoAdded: 'Vimeo Integration Setup Success',
  vimeoPrompt:
    'Easily access and manage your Vimeo-hosted videos with our seamless integration system. Streamline your experience by effortlessly browsing and selecting your video content directly from Vimeo, all within our platform. Enjoy the convenience of centralized management while maximizing the potential of your Vimeo library.',
};
