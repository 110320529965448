import React, {useEffect, useState} from 'react';
import FoldersUI from '../view/FoldersUI';
import {toast} from 'react-toastify';
import {inject, observer} from 'mobx-react';
import FoldersStore from '../store/FoldersStore';
import {useNavigate, useParams} from 'react-router-dom';
import {Folder, FolderCreateModel} from '../../../data/models/Course/Folder';
import {Course} from '../../../data/models/Course/Course';
import {foldersText} from '../foldersText';
import {getStructureFromKeySingular} from '../../../data/staticValues/courseStructure';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {getErrorFromCode} from '../../../data/errorCodes';
import {ActiveTabs} from '../../../data/models/UI/ActiveTabs';
import {SidebarOptions} from '../../../data/models/UI/SidebarOption';

interface Props {
  foldersStore?: FoldersStore;
}

const FoldersContainer = inject('foldersStore')(
  observer(({foldersStore}: Props) => {
    const [folders, setFolders] = useState<Folder[]>([]);
    const [course, setCourse] = useState<Course>();
    const {organizationId, courseId} = useParams();
    const [sidebarOptions, setSidebarOptions] = useState<SidebarOptions>([]);
    const [activeTabs, setActiveTabs] = useState<ActiveTabs>({
      courseId: courseId || '',
    });
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      fetchCourse();
      fetchFolders();
    }, []);

    useEffect(() => {
      getSidebarOptions(search);
    }, [search]);

    const fetchFolders = () => {
      if (organizationId && courseId) {
        foldersStore!.getFolders(organizationId, courseId).then(res => {
          const ordered = res.sort((x, y) => (x.order > y.order ? 1 : -1));
          setFolders(ordered);
        });
        getSidebarOptions(search);
      }
    };

    const fetchCourse = () => {
      if (organizationId && courseId) {
        foldersStore!.getCourse(organizationId, courseId).then(course => {
          if (course) {
            setCourse(course);
            setActiveTabs({
              courseId: courseId,
            });
          } else {
            //navigate('/permission_error', {replace: true});
          }
        });
      }
    };

    const onBack = () => {
      navigate(`/manage/${organizationId}/courses`, {replace: true});
    };

    const onNext = () => {
      if (folders.length) {
        const firstFolderId = folders[0].id;
        navigate(
          `/editor/${organizationId}/courses/${courseId}/folders/${firstFolderId}/lessons`,
        );
      }
    };

    const onOpen = (folder: Folder) => {
      navigate(
        `/editor/${organizationId}/courses/${courseId}/folders/${folder.id}/lessons`,
      );
    };

    const onPublish = () => {
      navigate(`/preview/${organizationId}/course/${courseId}`);
    };

    const onEdit = async (folder: Folder) => {
      if (organizationId) {
        await foldersStore?.editFolder(organizationId, folder);
        fetchFolders();
      }
    };

    const onDelete = async (folderId: string) => {
      if (organizationId && courseId) {
        const error = await foldersStore!.deleteFolder(
          organizationId,
          courseId,
          folderId,
        );
        if (!error) {
          const newFolders = [...folders];
          const deletedFolderIndex = newFolders.findIndex(
            x => x.id === folderId,
          );
          newFolders.splice(deletedFolderIndex, 1);
          onFoldersReorder(newFolders);
        } else {
          toast.error(
            getErrorFromCode(error) +
              getStructureFromKeySingular(course?.structure.toString() || ''),
            toastConfig,
          );
          fetchFolders();
        }
      }
    };

    const onCreate = async () => {
      if (organizationId && courseId && course) {
        const newFolder: FolderCreateModel = {
          name: foldersText.newFolderName(
            getStructureFromKeySingular(course.structure.toString()),
          ),
          courseId: courseId,
          order:
            folders.sort((a, b) => (a.order < b.order ? 1 : -1))?.[0].order +
              1 || 0,
        };
        await foldersStore!.createFolder(organizationId, newFolder);
        fetchFolders();
      }
    };

    const onFoldersReorder = async (folders: Folder[]) => {
      folders = folders.map((folder, index) => ({
        ...folder,
        order: index,
      }));
      setFolders(folders);
      if (organizationId && courseId) {
        const success = await foldersStore?.reorderFolders(
          organizationId,
          courseId,
          folders,
        );
        if (!success) {
          fetchFolders();
        } else {
          await getSidebarOptions(search);
          toast.success(foldersText.changesSaved, toastConfig);
        }
      }
    };

    const getBreadcrumbs = () => [
      {
        title: 'Courses',
        link: `/manage/${organizationId}/courses`,
      },
      {
        title: course!.title,
        link: `/editor/${organizationId}/courses/${courseId}/folders`,
      },
    ];

    const getSidebarOptions = (search: string) => {
      if (organizationId) {
        foldersStore!.getSidebarOptions(organizationId, search).then(res => {
          setSidebarOptions(res);
        });
      }
    };

    return course ? (
      <FoldersUI
        items={folders.sort((x, y) => (x.order > y.order ? 1 : -1))}
        onBack={onBack}
        course={course}
        onEdit={onEdit}
        onDelete={onDelete}
        isLoading={foldersStore!.isLoading}
        onCreate={onCreate}
        onItemsReordered={onFoldersReorder}
        onNext={onNext}
        onOpen={onOpen}
        onPublish={onPublish}
        breadcrumbs={getBreadcrumbs()}
        sidebarOptions={sidebarOptions}
        activeTabs={activeTabs}
        onSearch={setSearch}
      />
    ) : (
      <div />
    );
  }),
);

export default FoldersContainer;
