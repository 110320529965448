import {makeAutoObservable} from 'mobx';
import {
  LessonContentBase,
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
  LessonContentVideo,
  MULTIPLE_CHOICE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';
import QuestionGenerationRepository from '../../../../data/repositories/QuestionGenerationRepository';
import {toast} from 'react-toastify';
import {
  getErrorFromCode,
  internalErrorCodes,
} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

class AddMChoiceWizardStore {
  private lessonContentRepo = new LessonContentRepository();
  private questionGenerationRepo = new QuestionGenerationRepository();

  public showWizard = false;
  private parent?: LessonContentBase = undefined;
  private onCreateCallback?: () => void = undefined;

  public isLoading = false;

  public isLoadingAi = false;

  constructor() {
    makeAutoObservable(this);
  }

  public startWizard(parent: LessonContentBase, onCreateCallback?: () => void) {
    this.parent = parent;
    this.onCreateCallback = onCreateCallback;
    this.showWizard = true;
  }

  public async generateQuestions(organizationId: string, topic: string) {
    if (this.parent && (this.parent as LessonContentVideo).contentId) {
      this.isLoadingAi = true;
      const result = await this.questionGenerationRepo.generateQuestions(
        organizationId,
        (this.parent as LessonContentVideo).contentId,
        topic,
      );
      this.isLoadingAi = false;
      if (
        result.success &&
        result.data &&
        result.data.question_answers &&
        result.data.question_answers.length
      ) {
        return result.data;
      } else {
        toast.error(
          getErrorFromCode(internalErrorCodes.CANT_GENERATE_QUESTIONS),
          toastConfig,
        );
      }
    }
  }

  public async createMultipleChoice(
    organizationId: string,
    lessonId: string,
    question: string,
    answers: LessonContentMChoiceAnswer[],
    gradeWeight: number,
  ) {
    if (this.parent) {
      this.isLoading = true;
      const result = await this.lessonContentRepo.addLessonContent(
        organizationId,
        lessonId,
        {
          data: {
            ivNodeType: MULTIPLE_CHOICE,
            question,
            answers,
            gradeWeight,
          } as LessonContentMChoice,
          parentsIds: [this.parent.internalId],
        },
      );
      this.isLoading = false;
      if (result.success && this.onCreateCallback) {
        this.onCreateCallback();
        this.cancelWizard();
      }
    }
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
    this.parent = undefined;
    this.onCreateCallback = undefined;
  }
}

export default AddMChoiceWizardStore;
