import React from 'react';
import {coursesListText} from '../coursesListText';
import {bookActiveIcon, homeActive} from '../../../../assets/images';
import DataTable from 'react-data-table-component';
import {CourseListItem} from '../../../../data/models/Course/Course';
import TablePagination from '../../../../uiToolkit/TablePagination';
import './styles.css';
import '../../../../uiToolkit/DataTable/styles.css';
import PageTitle from '../../../../uiToolkit/PageTitle';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {getCourseTypeFromKey} from '../../../../data/staticValues/courseStructure';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import CoursesEmptyState from '../../../../uiToolkit/EmptyStates/CoursesEmptyState';
import Tooltip from '../../../../uiToolkit/Tooltip';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onCreateNewCourse: () => void;
  courses: CourseListItem[];
  onDelete: (courseId: string) => void;
  onEdit: (id: string) => void;
  onAddLearner: (publishedCourseId: string) => void;
  onAddInstructor: (courseId: string) => void;
  onOpenFolders: (courseId: string) => void;
  onOpenLearners: (courseId: string) => void;
  onPreview: (courseId: string) => void;
  isWorkspace?: boolean;
  isLoading?: boolean;
  onSearchChange: (search: string) => void;
  search: string;
  hasAdminPermissions: boolean;
}

const coursesTableColumns = (
  hasAdminPermissions: boolean,
  onDelete: (id: string) => void,
  onEdit: (id: string) => void,
  onOpenFolders: (courseId: string) => void,
  onAddLearner: (publishedCourseId: string) => void,
  onOpenLearners: (courseId: string) => void,
  onPreview: (courseId: string) => void,
  onAddInstructor: (courseId: string) => void,
) => [
  {
    name: 'Name',
    selector: (row: CourseListItem) => row.title,
    cell: (row: CourseListItem) => (
      <div
        className="DataTableSquareImageNameContainer"
        data-tag="allowRowEvents">
        {row.image ? (
          // <img src={row.image} alt="placeholder"  />
          <div
            style={{backgroundImage: `url(${row.image})`}}
            data-tag="allowRowEvents"
            className="DataTableSquareImageNameContainer_thumb"></div>
        ) : (
          <div className="CourseAvatarPlaceholder">
            <span>{row.title[0].toUpperCase()}</span>
          </div>
        )}
        <Tooltip tooltipText={row.title}>
          <h5
            className="DataTableText"
            data-tag="allowRowEvents"
            style={{cursor: 'pointer'}}
            onClick={() => onPreview(row.id || '')}>
            {row.title}
          </h5>
        </Tooltip>
      </div>
    ),
    grow: 2.5,
  },
  {
    name: 'Category',
    selector: (row: CourseListItem) => row.subjectTitle || '-',
    cell: (row: CourseListItem) => (
      <h5 className="DataTableText" data-tag="allowRowEvents">
        {row.subjectTitle || '-'}
      </h5>
    ),
    id: 'course_category',
  },
  {
    name: 'Type',
    selector: (row: CourseListItem) => row.description || '-',
    cell: (row: CourseListItem) => (
      <Tooltip tooltipText={getCourseTypeFromKey(row.type.toString()) || ''}>
        <h5 className={'DataTableText'} data-tag="allowRowEvents">
          {getCourseTypeFromKey(row.type.toString())?.[0]}
        </h5>
      </Tooltip>
    ),
    id: 'course_type',
  },
  {
    name: 'Enrolled',
    selector: (row: CourseListItem) => row.enrollNumber,
    cell: (row: CourseListItem) => (
      <h5 className={'DataTableText'} data-tag="allowRowEvents">
        {row.enrollNumber}
      </h5>
    ),
    id: 'course_enrolled',
    grow: 0.3,
  },
  // {
  //   name: 'Completed',
  //   selector: (row: CourseListItem) => row.completedNumber,
  //   cell: (row: CourseListItem) => (
  //     <h5 className={'DataTableText'} data-tag="allowRowEvents">
  //       {row.completedNumber}
  //     </h5>
  //   ),
  //   id: 'course_completed',
  //   grow: 0.3,
  // },
  {
    name: 'Avg. Grade',
    selector: (row: CourseListItem) => row.averageGrade,
    cell: (row: CourseListItem) => (
      <h5 className={'DataTableText'} data-tag="allowRowEvents">
        {row.averageGrade == 0 ? 'N/A' : Math.floor(row.averageGrade)}
      </h5>
    ),
    id: 'course_avg',
    grow: 0.3,
  },
  {
    name: 'Published',
    selector: (row: CourseListItem) => row.published,
    cell: (row: CourseListItem) =>
      row.published ? (
        <Tooltip tooltipText={coursesListText.published}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.yes}
          </h5>
        </Tooltip>
      ) : (
        <Tooltip tooltipText={coursesListText.unpublished}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.no}
          </h5>
        </Tooltip>
      ),
    id: 'course_published',
    grow: 0.3,
  },
  {
    name: 'Public',
    selector: (row: CourseListItem) => row.isPublic,
    cell: (row: CourseListItem) =>
      row.isPublic ? (
        <Tooltip tooltipText={coursesListText.public}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.yes}
          </h5>
        </Tooltip>
      ) : (
        <Tooltip tooltipText={coursesListText.private}>
          <h5 className={'DataTableText'} data-tag="allowRowEvents">
            {coursesListText.no}
          </h5>
        </Tooltip>
      ),
    id: 'course_public',
    grow: 0.3,
  },
  {
    name: coursesListText.actions,
    cell: (row: CourseListItem) => (
      <div>
        <div>
          <IconButton
            onClick={() => onEdit(row.id || '')}
            icon="icon-typeEdit-with-square"
            tooltip={coursesListText.edit}
          />
          <IconButton
            onClick={() => onOpenFolders(row.id || '')}
            icon="icon-typePage"
            tooltip={coursesListText.open}
          />
          <IconButton
            onClick={() => onDelete(row.id || '')}
            icon="icon-typeTrash"
            tooltip={
              row.published && row.enrollNumber !== 0
                ? coursesListText.cantDelete
                : coursesListText.delete
            }
            disabled={row.published && row.enrollNumber !== 0}
          />
        </div>
        {hasAdminPermissions && (
          <div>
            <IconButton
              onClick={() => onOpenLearners(row.publishedCourseId || '')}
              icon="icon-typeUsers"
              tooltip={
                row.published
                  ? coursesListText.learners
                  : coursesListText.learnersListDisabled
              }
              disabled={!row.published}
            />
            <IconButton
              onClick={() => onAddLearner(row.publishedCourseId || '')}
              icon="icon-typeAdd-learner"
              tooltip={
                row.published
                  ? coursesListText.addLearners
                  : coursesListText.learnersAddDisabled
              }
              disabled={!row.published}
            />
            <IconButton
              onClick={() => onAddInstructor(row.publishedCourseId || '')}
              icon="icon-typeAssign-Instructor"
              tooltip={
                row.published
                  ? coursesListText.addInstructor
                  : coursesListText.instructorAddDisabled
              }
              disabled={!row.published}
            />
          </div>
        )}
      </div>
    ),
    id: 'course_actions',
    grow: 3,
  },
];

const CoursesListUI = React.memo(
  ({
    onCreateNewCourse,
    courses,
    onDelete,
    onEdit,
    onOpenFolders,
    onAddLearner,
    isWorkspace,
    isLoading,
    onOpenLearners,
    onSearchChange,
    search,
    onPreview,
    onAddInstructor,
    hasAdminPermissions,
  }: Props) => {
    return (
      <div className="CoursesListContainer">
        <div className="CoursesListHeader">
          <PageTitle
            title={
              isWorkspace
                ? coursesListText.WorkspaceTitle
                : coursesListText.title
            }
            icon={isWorkspace ? homeActive : bookActiveIcon}
          />
          <div className="CourseFiltersContainer">
            <SearchInput
              value={search}
              onChange={onSearchChange}
              className="Input"
            />
            <Button
              title={coursesListText.create}
              onClick={onCreateNewCourse}
              uiType="action"
            />
          </div>
        </div>
        {!isLoading && (
          <DataTable
            data={courses}
            columns={coursesTableColumns(
              hasAdminPermissions,
              onDelete,
              onEdit,
              onOpenFolders,
              onAddLearner,
              onOpenLearners,
              onPreview,
              onAddInstructor,
            )}
            className="DataTable"
            pagination
            paginationPerPage={8}
            paginationComponent={TablePagination}
            highlightOnHover
            striped
            theme="custom-stripes"
            noDataComponent={<CoursesEmptyState onCreate={onCreateNewCourse} />}
          />
        )}
      </div>
    );
  },
);

export default CoursesListUI;
