export interface Notification {
  id: string;
  seen: boolean;
  seenAt?: Date;
  dateCreated: Date;
  title: string;
  description: string;
  userId: string;
  type: number;
  entityId: string;
  organizationId?: string;
  organizationAvatarUrl?: string;
}

export const NOTIFICATION_LEARNER_INVITATION = 0;
export const NOTIFICATION_STAFF_INVITATION = 1;
export const NOTIFICATION_COURSE_ENROLLMENT = 2;
export const NOTIFICATION_COURSE_INSTRUCTION = 3;
