import React from 'react';
import {
  LMSPlatforms,
  LtiPlatform,
  LtiTool,
} from '../../../../data/models/Lti/LtiIntegration';
import {settingsText} from '../../settingsText';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import CopyField from '../../../../uiToolkit/CopyField';
import {Accordion} from 'react-bootstrap';
import {dividerWhiteIc} from '../../../../assets/images';
import Tooltip from '../../../../uiToolkit/Tooltip';
import UpgradeHint from '../../../../uiToolkit/UpgradeHint';

interface Props {
  toolData?: LtiTool;
  onOpenAddPlatform: () => void;
  onSetupLti: () => void;
  isLoading: boolean;
  platforms: LtiPlatform[];
  onEditIntegration: (platfrom: LtiPlatform) => void;
  onDeletePlatform: (platformId: string) => void;
  ltiAvailableFeature?: boolean;
}

const LtiSettingsUI = React.memo(
  ({
    toolData,
    onOpenAddPlatform,
    isLoading,
    onSetupLti,
    platforms,
    onEditIntegration,
    onDeletePlatform,
    ltiAvailableFeature,
  }: Props) => {
    const onEdit = (platform: LtiPlatform) => (e: any) => {
      e.stopPropagation();
      onEditIntegration(platform);
    };

    const onDelete = (platformId: string) => (e: any) => {
      e.stopPropagation();
      onDeletePlatform(platformId);
    };

    if (!ltiAvailableFeature) {
      return (
        <div className="LtiSettingsContainer">
          <UpgradeHint hint={settingsText.upgradeToUseLti} />
        </div>
      );
    }

    return (
      <div className="LtiSettingsContainer">
        {toolData ? (
          <div>
            <p className="LtiToolTitle">{settingsText.toolDataTitle}</p>
            <CopyField title={settingsText.loginUrl} data={toolData.loginUrl} />
            <CopyField
              title={settingsText.redirectionUrl}
              data={toolData.redirectionUrl}
            />
            <CopyField
              title={settingsText.publicKeySetUrl}
              data={toolData.publicKeySetUrl}
            />
            <div className="PlatformsContainer">
              <p className="LtiToolTitle">{settingsText.yourPlatforms}</p>
              {platforms.length ? (
                <Accordion defaultActiveKey="-1">
                  {platforms.map((item, index) => (
                    <Accordion.Item
                      eventKey={index.toString()}
                      key={index.toString()}>
                      <Accordion.Header>
                        <span className="accordion-title">
                          {settingsText.ltiConnection}
                        </span>
                        <img src={dividerWhiteIc} />
                        <span className="accordion-title">{item.name}</span>
                        <img src={dividerWhiteIc} />
                        <Tooltip tooltipText={settingsText.editIntegration}>
                          <span
                            className="icon-typeEdit-without-square"
                            onClick={onEdit(item)}></span>
                        </Tooltip>
                        <img src={dividerWhiteIc} />
                        <Tooltip tooltipText={settingsText.deleteIntegration}>
                          <span
                            className="icon-typeTrash"
                            onClick={onDelete(item.id || '')}></span>
                        </Tooltip>
                      </Accordion.Header>
                      <Accordion.Body>
                        <CopyField
                          title={settingsText.ltiName + ':'}
                          data={item.name}
                        />
                        <CopyField
                          title={settingsText.platformType + ':'}
                          data={LMSPlatforms[0].value}
                        />
                        <CopyField
                          title={settingsText.accessTokenUrl + ':'}
                          data={item.accessTokenUrl}
                        />
                        <CopyField
                          title={settingsText.authorizeUrl + ':'}
                          data={item.authorizeUrl}
                        />
                        <CopyField
                          title={settingsText.issuer + ':'}
                          data={item.issuer}
                        />
                        <CopyField
                          title={settingsText.clientId + ':'}
                          data={item.clientId}
                        />
                        <CopyField
                          title={settingsText.jwkSetUrl + ':'}
                          data={item.jwkSetUrl}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              ) : (
                <p className="NoPlatforms">{settingsText.noPlatforms}</p>
              )}
              <Button
                onClick={onOpenAddPlatform}
                title={settingsText.addNewPlatform}
                isLoading={isLoading}
                className="AddPlatfromButton"
              />
            </div>
          </div>
        ) : (
          <div>
            <p className="LtiPrompt">{settingsText.ltiPrompt}</p>
            <Button
              onClick={onSetupLti}
              title={settingsText.setupLti}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    );
  },
);

export default LtiSettingsUI;
