import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {backIc, previewInfoIc} from '../../../assets/images';
import {appText} from '../../../assets/text/appText';
import {videoPlaybackText} from '../videoPlaybackText';

interface Props {
  onBack: () => void;
}

const PreviewHeader = React.memo(({onBack}: Props) => {
  return (
    <Row>
      <Col xs={4} className="pt-3">
        <button className="PlaybackBackButton" onClick={onBack}>
          <img src={backIc} />
          {appText.back}
        </button>
      </Col>
      <Col xs={8} className="pt-3">
        <div className="PreviewBanner">
          <img src={previewInfoIc} />
          <span>{videoPlaybackText.previewMode}</span>
        </div>
      </Col>
    </Row>
  );
});

export default PreviewHeader;
