import OrganizationApi from '../api/OrganizationApi';
import {
  CreateRoleModel,
  EditRoleModel,
} from '../models/Oganization/CreateRoleModel';
import {Organization} from '../models/Oganization/Organization';

class OrganizationRepository {
  private organizationApi = new OrganizationApi();

  public getMyOrganizations() {
    return this.organizationApi.getUserOrganizations();
  }

  public async createOrganization(
    name: string,
    description: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) {
    const result = await this.organizationApi.createOrganization({
      name,
      description,
      primaryColor,
      secondaryColor,
      type,
    });
    if (result.success && result.data && image) {
      await this.organizationApi.uploadOrganizationAvatar(
        image,
        result.data.id,
      );
    }
    return result;
  }

  public getOrganizationDetails(organizationId: string) {
    return this.organizationApi.getOrganizationDetails(organizationId);
  }

  public async editOrganization(organization: Organization, image?: File) {
    const result = await this.organizationApi.editOrganization(organization);
    if (result.success && result.data && image) {
      await this.organizationApi.uploadOrganizationAvatar(
        image,
        organization.id,
      );
    }
    return result;
  }

  public getOrganizationRoles(organizationId: string) {
    return this.organizationApi.getOrganizationRoles(organizationId);
  }

  public createRole(model: CreateRoleModel) {
    return this.organizationApi.createRole(model);
  }

  public getRoleById(id: string, organizationId: string) {
    return this.organizationApi.getRoleById(id, organizationId);
  }

  public editRole(model: EditRoleModel) {
    return this.organizationApi.editRole(model);
  }

  public deleteRole(organizationId: string, roleId: string) {
    return this.organizationApi.deleteRole(organizationId, roleId);
  }

  public tryGetLearnerOrganization() {
    return this.organizationApi.tryGetLearnerOrganization();
  }

  public getOrganizationUsersCount(organizationId: string) {
    return this.organizationApi.getOrganizationUsersCount(organizationId);
  }

  public addVimeoSecret(organizationId: string, secret: string) {
    return this.organizationApi.addVimeoSecret(organizationId, secret);
  }
}

export default OrganizationRepository;
