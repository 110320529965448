import React from 'react';
import AppRouter from './navigation';
import {Provider} from 'mobx-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import NavigationStore from './navigation/store/NavigationStore';
import LoginStore from './pages/Login/store/LoginStore';
import './assets/fonts';
import {ToastContainer} from 'react-toastify';
import VideoPlaybackStore from './pages/VideoPlayback/store/VideoPlaybackStore';
import VideoSourcesStore from './pages/Library/store/VideoSourcesStore';
import LearnersStore from './pages/Learners/store/LearnersStore';
import SignupStore from './pages/Signup/store/SignupStore';
import ConfirmEmailStore from './pages/ConfirmEmail/store/ConfirmEmailStore';
import ForgotPasswordStore from './pages/ForgotPassword/store/ForgotPasswordStore';
import UserMenuPopUpStore from './modules/UserMenuPopUp/store/UserMenuPopUpStore';
import OpenWorkspaceStore from './modules/OpenWorkspace/store/OpenWorkspaceStore';
import CreateOrganizationStore from './modules/CreateOrganization/store/CreateOrganizationStore';
import HomeStore from './pages/Home/store/HomeStore';
import ListCoursesStore from './pages/Courses/List/store/ListCoursesStore';
import ExploreStore from './pages/Explore/store/exploreStore';
import {createTheme} from 'react-data-table-component';
import SettingsStore from './pages/Settings/store/SettingsStore';
import ModalProviderStore from './modules/ModalProvider/store/ModalProviderStore';
import {ModalProvider} from './modules/ModalProvider';
import OrganizationStaffStore from './pages/Settings/store/OrganizationStaffStore';
import NavigationUserAvatarStore from './modules/NavigationUserAvatar/store/NavigationUserAvatarStore';
import AddVideoSourceWizardStore from './modules/Editor/AddVideoSourceWizard/store/AddVideoSourceWizardStore';
import CreateCourseStore from './pages/Courses/Create/store/CreateCourseStore';
import SettingsUserProfileStore from './pages/UserProfile/SettingsUserProfile/store/SettingsUserProfileStore';
import FoldersStore from './pages/Folders/store/FoldersStore';
import LessonsStore from './pages/Lessons/store/LessonsStore';
import LessonEditorStore from './pages/LessonEditor/store/LessonEditorStore';
import AddChoiceQuestionStore from './modules/Editor/AddChoiceQuestionWizard/store/AddChoiceQuestionStore';
import AddChoiceTextOptionWizardStore from './modules/Editor/AddChoiceTextOptionWizard/store/AddChoiceTextOptionWizardStore';
import MyLibraryModalStore from './modules/MyLibraryModal/store/MyLibraryModalStore';
import AddMChoiceWizardStore from './modules/Editor/AddMChoiceWizard/store/AddMChoiceWizardStore';
import CourseHomeStore from './pages/CourseHome/store/CourseHomeStore';
import EnrollLearnersWizardStore from './modules/EnrollLearnersWizard/store/EnrollLearnersWizardStore';
import NotificationCentreStore from './modules/NotificationCentre/store/NotificationCentreStore';
import GeneralCourseEditStore from './pages/CourseEdit/GeneralCourseEdit/store/GeneralCourseEditStore';
import AdminPanelStore from './pages/AdminPanel/store/AdminPanelStore';
import CoursePreviewStore from './pages/Courses/Preview/store/CoursePreviewStore';
import AddInstructorWizardStore from './modules/AddInstructorWizard/store/AddInstructorWizardStore';
import AddChallengeBranchQuestionStore from './modules/Editor/AddChallengeBranchQuestionWizard/store/AddChallengeBranchQuestionStore';
import AddChallengeBranchTextOptionStore from './modules/Editor/AddChallengeBranchTextOptionWizard/store/AddChallengeBranchTextOptionStore';
import AddChallengeBranchAIStore from './modules/Editor/AddChallengeBranchAIWizard/store/AddChallengeBranchAIStore';
import LTIPlayerStore from './pages/LTIPlayer/store/LTIPlayerStore';
import LtiSettingsStore from './pages/Settings/LtiSettings/store/LtiSettingsStore';
import SubscriptionSettingsStore from './pages/Settings/SubscriptionSettings/store/SubscriptionSettingsStore';
import SelectSubscriptionStore from './pages/SelectSubscription/store/SelectSubscriptonStore';
import WorkspaceStore from './pages/Workspace/store/WorkspaceStore';
import ExploreButtonStore from './modules/ExploreButton/store/ExploreButtonStore';
import CourseAccessGateStore from './modules/CourseAccessGate/store/CourseAccessGateStore';
import StorageIntegrationStore from './pages/Settings/StorageIntergrationSettings/store/StorageIntegrationStore';
import AddVideoOverlayStore from './modules/Editor/AddVideoOverlayWizard/store/AddVideoOverlayStore';

createTheme('custom-stripes', {
  striped: {
    default: '#F2F2F2',
    text: 'rgba(0, 0, 0, 0.87)',
  },
});

const checkForTheme = () => {
  const storedPrimaryColor = localStorage.getItem('primaryColor');
  const storedSecondaryColor = localStorage.getItem('secondaryColor');
  if (storedPrimaryColor && storedSecondaryColor) {
    document.documentElement.style.setProperty(
      '--primary-color-1',
      storedPrimaryColor,
    );
    document.documentElement.style.setProperty(
      '--primary-color-3',
      storedPrimaryColor,
    );
    document.documentElement.style.setProperty(
      '--secondary-color-1',
      storedSecondaryColor,
    );
  }
};

const navigationStore = new NavigationStore();
const loginStore = new LoginStore();
const adminPanelStore = new AdminPanelStore();
const videoPlaybackStore = new VideoPlaybackStore();
const videoSourcesStore = new VideoSourcesStore();
const learnersStore = new LearnersStore();
const signupStore = new SignupStore();
const confirmEmailStore = new ConfirmEmailStore();
const forgotPasswordStore = new ForgotPasswordStore();
const createCourseStore = new CreateCourseStore();
const userMenuPopUpStore = new UserMenuPopUpStore();
const openWorkspaceStore = new OpenWorkspaceStore();
const createOrganizationStore = new CreateOrganizationStore();
const homeStore = new HomeStore();
const listCoursesStore = new ListCoursesStore();
const exploreStore = new ExploreStore();
const settingsStore = new SettingsStore();
const modalProviderStore = new ModalProviderStore();
const organizationStaffStore = new OrganizationStaffStore();
const navigationUserAvatarStore = new NavigationUserAvatarStore();
const settingsUserProfileStore = new SettingsUserProfileStore();
const foldersStore = new FoldersStore();
const lessonsStore = new LessonsStore();
const addVideoSourceWizardStore = new AddVideoSourceWizardStore();
const addChoiceQuestionStore = new AddChoiceQuestionStore();
const addChoiceTextOptionWizardStore = new AddChoiceTextOptionWizardStore();
const lessonEditorStore = new LessonEditorStore();
const myLibraryModalStore = new MyLibraryModalStore();
const addMChoiceWizardStore = new AddMChoiceWizardStore();
const courseHomeStore = new CourseHomeStore();
const enrollLearnersWizardStore = new EnrollLearnersWizardStore();
const notificationCentreStore = new NotificationCentreStore();
const generalCourseEditStore = new GeneralCourseEditStore();
const coursePreviewStore = new CoursePreviewStore();
const addInstructorWizardStore = new AddInstructorWizardStore();
const addChallengeBranchQuestionStore = new AddChallengeBranchQuestionStore();
const addChallengeBranchTextOptionStore =
  new AddChallengeBranchTextOptionStore();
const addChallengeBranchAIStore = new AddChallengeBranchAIStore();
const ltiPlayerStore = new LTIPlayerStore();
const ltiSettingsStore = new LtiSettingsStore();
const subscriptionSettingsStore = new SubscriptionSettingsStore();
const selectSubscriptionStore = new SelectSubscriptionStore();
const workspaceStore = new WorkspaceStore();
const exploreButtonStore = new ExploreButtonStore();
const courseAccessGateStore = new CourseAccessGateStore();
const storageIntegrationStore = new StorageIntegrationStore();
const addVideoOverlayStore = new AddVideoOverlayStore();

function App() {
  checkForTheme();
  return (
    <Provider
      signupStore={signupStore}
      confirmEmailStore={confirmEmailStore}
      navigationStore={navigationStore}
      loginStore={loginStore}
      ltiPlayerStore={ltiPlayerStore}
      workspaceStore={workspaceStore}
      settingsStore={settingsStore}
      addInstructorWizardStore={addInstructorWizardStore}
      navigationUserAvatarStore={navigationUserAvatarStore}
      forgotPasswordStore={forgotPasswordStore}
      videoPlaybackStore={videoPlaybackStore}
      videoSourcesStore={videoSourcesStore}
      selectSubscriptionStore={selectSubscriptionStore}
      openWorkspaceStore={openWorkspaceStore}
      createCourseStore={createCourseStore}
      learnersStore={learnersStore}
      exploreButtonStore={exploreButtonStore}
      foldersStore={foldersStore}
      addMChoiceWizardStore={addMChoiceWizardStore}
      lessonEditorStore={lessonEditorStore}
      addVideoOverlayStore={addVideoOverlayStore}
      userMenuPopUpStore={userMenuPopUpStore}
      createOrganizationStore={createOrganizationStore}
      storageIntegrationStore={storageIntegrationStore}
      listCoursesStore={listCoursesStore}
      addChoiceTextOptionWizardStore={addChoiceTextOptionWizardStore}
      modalProviderStore={modalProviderStore}
      courseAccessGateStore={courseAccessGateStore}
      organizationStaffStore={organizationStaffStore}
      lessonsStore={lessonsStore}
      ltiSettingsStore={ltiSettingsStore}
      addChoiceQuestionStore={addChoiceQuestionStore}
      myLibraryModalStore={myLibraryModalStore}
      addVideoSourceWizardStore={addVideoSourceWizardStore}
      homeStore={homeStore}
      subscriptionSettingsStore={subscriptionSettingsStore}
      exploreStore={exploreStore}
      settingsUserProfileStore={settingsUserProfileStore}
      courseHomeStore={courseHomeStore}
      enrollLearnersWizardStore={enrollLearnersWizardStore}
      notificationCentreStore={notificationCentreStore}
      generalCourseEditStore={generalCourseEditStore}
      coursePreviewStore={coursePreviewStore}
      addChallengeBranchAIStore={addChallengeBranchAIStore}
      addChallengeBranchQuestionStore={addChallengeBranchQuestionStore}
      addChallengeBranchTextOptionStore={addChallengeBranchTextOptionStore}
      adminPanelStore={adminPanelStore}>
      <AppRouter />
      <ModalProvider />
      <ToastContainer />
    </Provider>
  );
}

export default App;
