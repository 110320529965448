import {makeAutoObservable} from 'mobx';
import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';

class LTIPlayerStore {
  private lessonsRepo = new LessonsRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getLessonDataBySecret(lessonSecret: string) {
    this.isLoading = true;
    const result = await this.lessonsRepo.getLessonBySecret(lessonSecret);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async saveProgressWithSecret(
    lessonSecret: string,
    progress: LearnerProgressModel,
  ) {
    this.isLoading = true;
    await this.lessonsRepo.saveProgressWithSecret(lessonSecret, progress);
    this.isLoading = false;
  }
}

export default LTIPlayerStore;
