import React from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import Checkbox from '../../../uiToolkit/Inputs/Checkbox';
import DataTable from 'react-data-table-component';
import {Learner} from '../../../data/models/Learners/Learner';
import TablePagination from '../../../uiToolkit/TablePagination';
import './styles.css';
import {getFirstCapitals} from '../../../utils/getFirstCapitals';
import {enrollLearnersWizardText} from '../enrollLearnersWizardText';
import DatePickerComponent from '../components/DatePickerComponent';
import Button from '../../../uiToolkit/Buttons/Button';
import {appText} from '../../../assets/text/appText';
import {ENROLLMENT_LIST_PAGE_SIZE} from '../container/EnrollLearnersWizardContainer';

interface Props {
  learnerData: Learner[];
  onCancel: () => void;
  onSave: (selectedLearners: string[], dueDate?: Date) => void;
  pages: number;
  onPageChange: (page: number) => void;
}

const columns = (
  checkedLearnerIds: string[],
  setCheckedLearnerIds: React.Dispatch<React.SetStateAction<string[]>>,
) => [
  {
    name: '',
    selector: (row: any) => row.learnerId,
    cell: (row: any) => (
      <div className="LearnerModalColumn">
        <Checkbox
          checked={checkedLearnerIds.includes(row.learnerId)}
          className="Checkbox"
          onCheck={() =>
            handleCheckboxChange(
              row.learnerId,
              checkedLearnerIds,
              setCheckedLearnerIds,
            )
          }
        />
      </div>
    ),
    id: 'CheckboxColumn',
    grow: 0.1,
  },
  {
    name: 'Name',
    selector: (row: any) => row.name,
    cell: (row: any) => (
      <div className="LearnerModalColumn">
        {row.avatar ? (
          <img
            src={row.avatar}
            alt="User Profile Picture"
            className="LearnerModalColumn"
          />
        ) : (
          <span className="Initials">{getFirstCapitals(row.name || '')}</span>
        )}
        <h5>{row.name}</h5>
      </div>
    ),
    // grow: 7,
    id: 'NameColumn',
  },
  {
    name: 'Email',
    selector: (row: any) => row.email,
    cell: (row: any) => <h5 className="DataTableText">{row.email}</h5>,
    // grow: 2,
  },
];

const handleCheckboxChange = (
  learnerId: string,
  checkedLearnerIds: string[],
  setCheckedLearnerIds: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (!checkedLearnerIds.includes(learnerId)) {
    setCheckedLearnerIds(prevState => {
      const newState = [...prevState, learnerId];
      return newState;
    });
  } else {
    setCheckedLearnerIds(prevState => {
      const newState = prevState.filter(id => id !== learnerId);
      return newState;
    });
  }
};

const EnrollLearnersWizardUI = React.memo(
  ({onCancel, onSave, learnerData, pages, onPageChange}: Props) => {
    const [checkedLearnerIds, setCheckedLearnerIds] = React.useState<string[]>(
      [],
    );
    const [dueDate, setDueDate] = React.useState<Date | null>(null);

    return (
      <div>
        <ModalContainer onClose={onCancel}>
          <div className="EnrollLearnersWizardContainer">
            <div className="Header">
              <h1>{enrollLearnersWizardText.ModalHeader}</h1>
            </div>
            <DatePickerComponent dueDate={dueDate} setDueDate={setDueDate} />
            <DataTable
              data={learnerData}
              columns={columns(checkedLearnerIds, setCheckedLearnerIds)}
              className="AddLearnerTable"
              pagination
              striped
              theme="custom-stripes"
              paginationPerPage={ENROLLMENT_LIST_PAGE_SIZE}
              paginationComponent={TablePagination}
              pointerOnHover
              onChangePage={onPageChange}
              paginationServer
              paginationTotalRows={pages * ENROLLMENT_LIST_PAGE_SIZE}
            />
            <div className="Footer">
              <Button title={appText.cancel} onClick={onCancel} uiType="text" />
              <Button
                title={appText.save}
                onClick={() =>
                  onSave(
                    checkedLearnerIds,
                    dueDate instanceof Date ? dueDate : undefined,
                  )
                }
                uiType="action"
                disabled={!checkedLearnerIds.length}
              />
            </div>
          </div>
        </ModalContainer>
      </div>
    );
  },
);

export default EnrollLearnersWizardUI;
