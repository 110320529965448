import React, {useEffect, useState} from 'react';
import {editorAddItemIc} from '../../assets/images';
import './styles.css';
import Tooltip from '../Tooltip';

interface TooltopAction {
  icon: string;
  action: () => void;
  tooltip?: string;
}

interface Props {
  actions: TooltopAction[];
  tooltipInfo?: string;
  style?: any;
}

const EditorTooltipButton = React.memo(
  ({actions, tooltipInfo, style}: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
      document.body.addEventListener('click', onDocumentClick);
      return () => {
        removeEventListener('click', onDocumentClick);
      };
    }, []);

    const onDocumentClick = () => {
      setShowTooltip(false);
    };

    const onToggleTooltip = (e: any) => {
      e.stopPropagation();
      setShowTooltip(!showTooltip);
    };

    const onAction = (action: () => void) => {
      setShowTooltip(false);
      action();
    };

    return (
      <div className="EditorTooltopButton" style={style}>
        {tooltipInfo && !showTooltip ? (
          <Tooltip tooltipText={tooltipInfo} greenTooltip>
            <button className="AddButton" onClick={onToggleTooltip}>
              <img src={editorAddItemIc} />
            </button>
          </Tooltip>
        ) : (
          <button className="AddButton" onClick={onToggleTooltip}>
            <img src={editorAddItemIc} />
          </button>
        )}

        {showTooltip && (
          <div className="EditorTooltipContainer">
            <span className="EditorTooltipArrowDetail"></span>
            <div
              className="EditorTooltipContent"
              onClick={e => e.stopPropagation()}
              style={{
                width: `${
                  actions.length * 20 + 24 + (actions.length - 1) * 12
                }px`,
              }}>
              {actions.map((item, index) =>
                item.tooltip ? (
                  <Tooltip
                    tooltipText={item.tooltip}
                    key={index.toString()}
                    greenTooltip>
                    <button onClick={() => onAction(item.action)}>
                      <img src={item.icon} />
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    onClick={() => onAction(item.action)}
                    key={index.toString()}>
                    <img src={item.icon} />
                  </button>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default EditorTooltipButton;
