import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import CreateOrganizationStore from '../store/CreateOrganizationStore';
import CreateOrganizationOpenerUI from '../view/CreateOrganizationOpenerUI';
import {Organization} from '../../../data/models/Oganization/Organization';
import ModalProviderStore from '../../ModalProvider/store/ModalProviderStore';
import {CREATE_ORGANIZATION_MODAL} from '../../ModalProvider/globalModals';
import {useNavigate} from 'react-router-dom';

interface Props {
  createOrganizationStore?: CreateOrganizationStore;
  modalProviderStore?: ModalProviderStore;
}

const CreateOrganizationOpenerContainer = inject(
  'createOrganizationStore',
  'modalProviderStore',
)(
  observer(({createOrganizationStore, modalProviderStore}: Props) => {
    const [userOrganizations, setUserOrganizations] =
      useState<Organization[]>();
    const [learnerOrganization, setLearnerOrganization] =
      useState<Organization>();

    const navigate = useNavigate();

    useEffect(() => {
      createOrganizationStore!.getUserOrganizations().then(res => {
        setUserOrganizations(res);
        createOrganizationStore
          ?.getUserLearnerOrganizations()
          .then(setLearnerOrganization);
      });
    }, []);

    const onOpen = () => {
      modalProviderStore!.openModal(CREATE_ORGANIZATION_MODAL, onFinish);
    };

    const onFinish = (orgId?: string) => {
      if (orgId && typeof orgId === 'string') {
        navigate(`/manage/${orgId}`);
      }
    };

    return userOrganizations &&
      userOrganizations.length === 0 &&
      !learnerOrganization &&
      !createOrganizationStore!.isLoading ? (
      <CreateOrganizationOpenerUI onOpen={onOpen} />
    ) : (
      <div />
    );
  }),
);

export default CreateOrganizationOpenerContainer;
