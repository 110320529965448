import React, {useEffect, useState} from 'react';
import LTIPlayerUI from '../view/LTIPlayerUI';
import {inject, observer} from 'mobx-react';
import LTIPlayerStore from '../store/LTIPlayerStore';
import {Lesson} from '../../../data/models/Course/Lesson';
import {LessonContentList} from '../../../data/models/LessonContent/LessonContentList';
import {useParams} from 'react-router-dom';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';

interface Props {
  ltiPlayerStore?: LTIPlayerStore;
}

const LTIPlayerContainer = inject('ltiPlayerStore')(
  observer(({ltiPlayerStore}: Props) => {
    const [lesson, setLesson] = useState<Lesson>();
    const [content, setContent] = useState<LessonContentList>();

    const {lessonSecret} = useParams();

    useEffect(() => {
      if (lessonSecret) {
        ltiPlayerStore!.getLessonDataBySecret(lessonSecret).then(res => {
          if (res) {
            setLesson(res.lesson);
            setContent(res.lessonContent);
          }
        });
      }
    }, [lessonSecret]);

    const onFinish = () => {
      //TODO
    };

    const onFinishLesson = (progress: LearnerProgressModel) => {
      if (lessonSecret) {
        ltiPlayerStore!.saveProgressWithSecret(lessonSecret, progress);
      }
    };

    return content && lesson ? (
      <LTIPlayerUI
        isLoading={ltiPlayerStore!.isLoading}
        lessonContent={content}
        onFinish={onFinish}
        passingScore={lesson.passingScore || 0}
        isGradable={lesson.isGradable || false}
        onFinishLesson={onFinishLesson}
      />
    ) : null;
  }),
);

export default LTIPlayerContainer;
