import React from 'react';
import Button from '../Buttons/Button';
import {appText} from '../../assets/text/appText';
import './styles.css';
import Tooltip from '../Tooltip';

interface Props {
  saveEnabled?: boolean;
  deleteTitle: string;
  isLoading?: boolean;
  onSave: () => void;
  onDelete: () => void;
  deleteDisabled?: boolean;
}

const EditorOptionsFooter = React.memo(
  ({
    saveEnabled,
    onDelete,
    onSave,
    deleteTitle,
    isLoading,
    deleteDisabled,
  }: Props) => {
    return (
      <div className="EditorOptionsFooter">
        <Button
          title={appText.saveChanges}
          onClick={onSave}
          disabled={!saveEnabled}
          uiType="action_box"
          isLoading={isLoading}
        />
        {deleteDisabled ? (
          <Tooltip tooltipText={appText.lessonAlreadyPublished} greenTooltip>
            <div>
              <Button
                title={deleteTitle}
                onClick={onDelete}
                uiType="text_danger"
                isLoading={isLoading}
                disabled={deleteDisabled}
              />
            </div>
          </Tooltip>
        ) : (
          <Button
            title={deleteTitle}
            onClick={onDelete}
            uiType="text_danger"
            isLoading={isLoading}
            disabled={deleteDisabled}
          />
        )}
      </div>
    );
  },
);

export default EditorOptionsFooter;
