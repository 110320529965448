import {Point} from './ContentFreeform';

export interface LessonContentBase {
  internalId: string;
  ivNodeType: number;
}

export interface LessonContentVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInseconds: number;
  contentId: string;
  contentUrl?: string;
  placeholderUrl?: string;
  title: string;
}

export interface LessonContentOverlayVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInseconds: number;
  contentId: string;
  contentUrl?: string;
  placeholderUrl?: string;
  title: string;
  overlayStartTime: number;
  overlayEndTime: number;
  overlayCoordinates: Point[];
  actionType: number;
  urlPayload?: string;
  encodedAtWidth: number;
  overlayVisible: boolean;
}

export interface LessonContentVimeoVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInSeconds: number;
  contentUrl?: string;
  placeholderUrl?: string;
  title: string;
}

export interface LessonContentVimeoOverlayVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInSeconds: number;
  contentUrl: string;
  placeholderUrl: string;
  title: string;
  overlayStartTime: number;
  overlayEndTime: number;
  overlayCoordinates: Point[];
  actionType: number;
  urlPayload?: string;
  encodedAtWidth: number;
  overlayVisible: boolean;
}

export interface LessonContentChoiceQuestion extends LessonContentBase {
  title: string;
}

export interface LessonContentChoiceTextOption extends LessonContentBase {
  title: string;
}

export interface LessonContentChoiceImageOption extends LessonContentBase {
  contentId: string;
  contentUrl?: string;
}

export interface LessonContentChoiceHeader extends LessonContentBase {
  title: string;
}

export interface LessonContentMChoice extends LessonContentBase {
  question: string;
  answers: LessonContentMChoiceAnswer[];
  gradeWeight: number;
}

export interface LessonContentMChoiceAnswer {
  value: string;
  isCorrect: boolean;
}

export type LessonContentChallengeChoiceHeader = LessonContentBase;

export interface LessonContentChallengeBranchQuestion
  extends LessonContentBase {
  question: string;
  gradeWeight: number;
}

export interface LessonContentChallengeBranchImageOption
  extends LessonContentBase {
  contentId: string;
  contentUrl?: string;
  isCorrect: boolean;
}

export interface LessonContentChallengeBranchTextOption
  extends LessonContentBase {
  answer: string;
  isCorrect: boolean;
}

export interface LessonContentChallengeBranchAI extends LessonContentBase {
  answerMask: string;
  question: string;
  gradeWeight: number;
  frameMask: string;
}

export type LessonContentChallengeBranchAICorrect = LessonContentBase;
export type LessonContentChallengeBranchAIIncorrect = LessonContentBase;

export const VIDEO_NODE_TYPE = 0;
export const IMAGE_NODE_TYPE = 1;
export const CHOICE_HEADER_NODE_TYPE = 2;
export const CHOICE_TEXT_QUESTION = 3;
export const CHOICE_IMAGE_OPTION = 4;
export const CHOICE_TEXT_OPTION = 5;
export const MULTIPLE_CHOICE = 6;
export const CHALLENGE_BRANCH_HEADER = 7;
export const CHALLENGE_BRANCH_QUESTION = 8;
export const CHALLENGE_BRANCH_IMAGE_OPTION = 9;
export const CHALLENGE_BRANCH_TEXT_OPTION = 10;
export const CHALLENGE_BRANCH_AI = 11;
export const CHALLENGE_BRANCH_AI_CORRECT = 12;
export const CHALLENGE_BRANCH_AI_INCORRECT = 13;
export const VIMEO_VIDEO_TYPE = 14;
export const OVERLAY_VIDEO_TYPE = 15;
export const OVERLAY_VIMEO_VIDEO_TYPE = 16;

export const AUDIO_NODE = 100;
export const QUESTION_NODE = 101;
export const TEXT_NODE = 102;
export const GROUP_BRANCH_NODE = 103;
export const RANDOM_BRANCH = 105;
export const COMMENT_NODE = 106;
export const TEXT_ANSWER_NODE = 107;

export const getNextStepsForNodeByType = (
  type?: number,
  isGradable?: boolean,
) => {
  switch (type) {
    case VIDEO_NODE_TYPE:
    case VIMEO_VIDEO_TYPE:
      // case OVERLAY_VIDEO_TYPE:
      // case OVERLAY_VIMEO_VIDEO_TYPE:
      return isGradable
        ? [
            VIDEO_NODE_TYPE,
            // OVERLAY_VIDEO_TYPE,
            // IMAGE_NODE_TYPE,
            // AUDIO_NODE,
            // QUESTION_NODE,
            // TEXT_NODE,
            MULTIPLE_CHOICE,
            CHOICE_HEADER_NODE_TYPE,
            // GROUP_BRANCH_NODE,
            CHALLENGE_BRANCH_HEADER,
            // CHALLENGE_BRANCH_AI,
            // RANDOM_BRANCH,
            // COMMENT_NODE,
            // TEXT_ANSWER_NODE,
          ]
        : [
            VIDEO_NODE_TYPE,
            // IMAGE_NODE_TYPE,
            // AUDIO_NODE,
            // QUESTION_NODE,
            // TEXT_NODE,
            CHOICE_HEADER_NODE_TYPE,
            // GROUP_BRANCH_NODE,
            // RANDOM_BRANCH,
            // COMMENT_NODE,
          ];
    case CHOICE_HEADER_NODE_TYPE:
      return [CHOICE_TEXT_QUESTION];
    case CHOICE_TEXT_QUESTION:
      return [CHOICE_TEXT_OPTION, CHOICE_IMAGE_OPTION];
    case MULTIPLE_CHOICE:
      return [VIDEO_NODE_TYPE];
    case CHALLENGE_BRANCH_HEADER:
      return [CHALLENGE_BRANCH_QUESTION];
    case CHALLENGE_BRANCH_QUESTION:
      return [CHALLENGE_BRANCH_TEXT_OPTION, CHALLENGE_BRANCH_IMAGE_OPTION];
    // case CHALLENGE_BRANCH_AI:
    //   return [VIDEO_NODE_TYPE];
    default:
      return [VIDEO_NODE_TYPE];
  }
};
