import React from 'react';
import DataTable from 'react-data-table-component';
import {logoTextWhite} from '../../../assets/images';
import {
  MEDIA_FILES_ALL,
  MediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import TablePagination from '../../../uiToolkit/TablePagination';
import {videoSourcesText} from '../videoSourcesText';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import LibraryEmptyState from '../../../uiToolkit/EmptyStates/LibraryEmptyState';
import {isImageFile} from '../../../data/models/LibraryFile/VideoSourceShort';
import {formatDateTimeTextFormat} from '../../../utils/notificationTimeTextConvertor';

const tooltip = <Tooltip>{videoSourcesText.fileIsUsed}</Tooltip>;

const columns = (onDelete: (itemId: string) => void) => [
  {
    name: 'Filename',
    selector: (row: MediaFile) => row.fileName,
    sortable: true,
    sortField: 'file_name',
    cell: (row: MediaFile) => (
      <div className="TableNameContainer" data-tag="allowRowEvents">
        <div className="TableNameContainerThumb">
          {isImageFile(row.fileType) ? (
            row.fileUrl && <img src={row.fileUrl} />
          ) : row.placeholderUrl ? (
            <img
              src={row.placeholderUrl}
              alt="placeholder"
              data-tag="allowRowEvents"
            />
          ) : (
            <img
              src={logoTextWhite}
              alt="placeholder"
              className="NoPlaceholderImage"
              data-tag="allowRowEvents"
            />
          )}
        </div>

        <h5 className="TableText" data-tag="allowRowEvents">
          {row.fileName}
        </h5>
      </div>
    ),
    grow: 4,
  },
  {
    name: 'Uploaded Date',
    sortable: true,
    sortField: 'created_date',
    selector: (row: MediaFile) => row.dateCreated.toString(),
    cell: (row: MediaFile) => (
      <h5 className="TableText" data-tag="allowRowEvents">
        {formatDateTimeTextFormat(row.dateCreated.toString())}
      </h5>
    ),
    id: 'created_date',
  },
  {
    name: 'Size',
    sortable: true,
    sortField: 'size',
    selector: (row: MediaFile) => row.dateCreated.toString(),
    cell: (row: MediaFile) => (
      <h5 className="TableText" data-tag="allowRowEvents">
        {row.fileSize} MB
      </h5>
    ),
    id: 'size',
  },
  {
    name: 'Actions',
    cell: (row: MediaFile) => (
      <button className="TableDeleteButton">
        {row.isUsed ? (
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <div className="DeleteDisabled">
              <span className="icon-typeTrash" />
            </div>
          </OverlayTrigger>
        ) : (
          <div className="Delete" onClick={() => onDelete(row.id)}>
            <span className="icon-typeTrash" />
          </div>
        )}
      </button>
    ),
    id: 'actions',
  },
];

interface Props {
  sources: MediaFile[];
  onItemClick: (item: MediaFile) => void;
  pages: number;
  onPageChange: (page: number) => void;
  onDelete: (itemId: string) => void;
  isLoading: boolean;
  onFileUpload: (file: File, duration: number) => void;
  onSort: (selectedColumn: any, sortDirection: any) => void;
}

const AllFilesTable = React.memo(
  ({
    sources,
    onItemClick,
    pages,
    onPageChange,
    onDelete,
    isLoading,
    onFileUpload,
    onSort,
  }: Props) => {
    return (
      <DataTable
        className="Table"
        columns={columns(onDelete)}
        data={sources}
        onRowClicked={onItemClick}
        noDataComponent={
          <LibraryEmptyState
            onVideoUpload={onFileUpload}
            isLoading={isLoading}
            type={MEDIA_FILES_ALL}
          />
        }
        pointerOnHover
        highlightOnHover
        onChangePage={onPageChange}
        pagination
        paginationServer
        onSort={onSort}
        paginationPerPage={10}
        paginationComponent={TablePagination}
        paginationTotalRows={pages * 10}
        striped
        sortServer
        theme="custom-stripes"
      />
    );
  },
);

export default AllFilesTable;
