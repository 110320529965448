export interface Point {
  x: number;
  y: number;
}

export const overlayTapActionOptions = [
  {
    key: '0',
    value: 'URL',
  },
];
