import React from 'react';
import {settingsText} from '../../settingsText';
import './styles.css';
import OrgDataForm from '../../../../forms/OrgDataForm';
import {Organization} from '../../../../data/models/Oganization/Organization';
import ErrorBox from '../../../../uiToolkit/ErrorBox';

interface Props {
  onEdit: (organization: Organization, image?: File) => void;
  organization: Organization;
  isLoading: boolean;
  errors: string[];
  onCancel: () => void;
  clearErrors: () => void;
}

const GeneralSettingsUI = React.memo(
  ({onEdit, organization, isLoading, errors, onCancel, clearErrors}: Props) => {
    const onSubmit = (
      name: string,
      description: string,
      primaryColor: string,
      secondaryColor: string,
      type: number,
      image?: File,
    ) => {
      const newOrganization = {
        ...organization,
        name,
        description,
        primaryColor,
        secondaryColor,
        type,
      };
      onEdit(newOrganization, image);
    };

    return (
      <div className="GeneralSettingsContainer">
        <ErrorBox
          errorCodes={errors}
          className="ErrorBox"
          clearErrors={clearErrors}
        />
        <OrgDataForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitText={settingsText.save}
          isLoading={isLoading}
          organization={organization}
          skipColors
        />
      </div>
    );
  },
);

export default GeneralSettingsUI;
