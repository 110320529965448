import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {adminPanelText} from '../adminPanelText';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  statistic?: any;
  logout: () => void;
}

const AdminPanelUI = React.memo(({statistic, logout}: Props) => {
  return statistic ? (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-row justify-content-between">
              <h3>{adminPanelText.title}</h3>
              <Button
                title={adminPanelText.logout}
                onClick={logout}
                uiType="action"
              />
            </div>
            <ul>
              <li>
                {adminPanelText.activeUsers} {statistic?.activeUsersAmount}
              </li>
              <li>
                {adminPanelText.workspacesAmount} {statistic?.workspacesAmount}
              </li>
              <li>
                {adminPanelText.coursesAmount} {statistic?.coursesAmount}
              </li>
              <li>
                {adminPanelText.publicCoursesAmount}{' '}
                {statistic?.publicCoursesAmount}
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
});

export default AdminPanelUI;
