import React from 'react';
import './styles.css';
import {appText} from '../../../assets/text/appText';

type TextInputUITypes = 'standart' | 'pill' | 'box';

interface Props {
  type?: string;
  value: string;
  onFocusFunc?: () => void;
  onBlurFunc?: () => void;
  onChange: (value: string) => void;
  placeholder: string;
  heading?: string;
  className?: string;
  maxCharacters?: number;
  minCharacters?: number;
  fieldError?: boolean;
  disabled?: boolean;
  isMandatory?: boolean;
  uiType?: TextInputUITypes;
  noEdit?: boolean;
}

const TextInput = React.memo(
  ({
    type,
    value,
    onChange,
    placeholder,
    heading,
    className,
    maxCharacters,
    fieldError,
    disabled,
    isMandatory,
    uiType,
    minCharacters,
    onFocusFunc,
    onBlurFunc,
    noEdit,
  }: Props) => {
    const getRootClass = () => {
      switch (uiType) {
        case 'pill':
          return 'TextInputPill';
        case 'box':
          return 'TextInputBox';
        default:
          return 'TextInputStandart';
      }
    };
    return (
      <div className={className}>
        <p className="TextInputTitle">
          {heading}
          {isMandatory ? <p className="MandatoryIndicator">*</p> : ''}
        </p>
        <div className="TextInputContainer">
          <input
            type={type}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            onFocus={onFocusFunc}
            onBlur={onBlurFunc}
            className={`${getRootClass()} ${fieldError ? 'FieldError' : ''} ${
              disabled ? 'DisabledInput' : ''
            }`}
            maxLength={maxCharacters}
            disabled={disabled || noEdit}
          />
        </div>
        <div className="TextInputCharactersCountContainer">
          {minCharacters ? (
            <div className="TextInputMaxCharacters">
              <span>{appText.minimumCharacters(minCharacters)}</span>
            </div>
          ) : (
            <div />
          )}
          {maxCharacters ? (
            <div className="TextInputMaxCharacters">
              <span>
                {value.length} / {maxCharacters}
              </span>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  },
);

export default TextInput;
