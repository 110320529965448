import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';

class LoginStore {
  private authRepo = new AuthRepository();

  public errors: string[] = [];
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async loginUser(email: string, password: string) {
    this.isLoading = true;
    const result = await this.authRepo.loginUser({email, password});
    this.isLoading = false;
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.errors = result.errors;
    }
  }

  public async sendEmailVerification() {
    this.isLoading = true;
    const result = await this.authRepo.sendEmailVerification();
    this.isLoading = false;
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.errors = result.errors;
    }
  }

  public getUser() {
    return this.authRepo.getUser();
  }

  public isUserEmailConfirmed() {
    return this.authRepo.getUser()?.emailConfirmed;
  }

  public clearErrors() {
    this.errors = [];
  }
}

export default LoginStore;
