import {makeAutoObservable} from 'mobx';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';
import {AddLessonContentModel} from '../../../../data/models/LessonContent/AddLessonContentModel';
import {
  LessonContentOverlayVideo,
  LessonContentVimeoOverlayVideo,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
} from '../../../../data/models/LessonContent/LessonContentBase';
import LessonContentRepository from '../../../../data/repositories/LessonContentRepository';

class AddVideoOverlayStore {
  private libraryContentRepo = new LessonContentRepository();

  private isShown = false;
  private parentId?: string;
  private selectedFile: MediaFile | null = null;
  private selectedVimeoFile: VimeoMediaFile | null = null;
  private successCallback?: () => void;

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public openWizard(
    parentId: string,
    mediaFile: MediaFile | null,
    vimeoFile: VimeoMediaFile | null,
    successCallback: () => void,
  ) {
    this.selectedFile = mediaFile;
    this.selectedVimeoFile = vimeoFile;
    this.parentId = parentId;
    this.successCallback = successCallback;
    this.isShown = true;
  }

  public shouldShowWizard() {
    return this.isShown;
  }

  public closeWizard() {
    this.selectedFile = null;
    this.selectedVimeoFile = null;
    this.parentId = undefined;
    this.successCallback = undefined;
    this.isShown = false;
  }

  public getVideoSource() {
    return this.selectedFile;
  }

  public getVimeoSource() {
    return this.selectedVimeoFile;
  }

  public async saveOverlayNode(
    organizationId: string,
    lessonId: string,
    start: number,
    end: number,
    overlayStartTime: number,
    overlayEndTime: number,
    overlayAction: number,
    urlPayload: string,
    overlayCoordinates: Point[],
    encodedAtWidth: number,
    overlayVisible: boolean,
  ) {
    if (this.selectedFile) {
      this.isLoading = true;
      const model: AddLessonContentModel = {
        data: {
          ivNodeType: OVERLAY_VIDEO_TYPE,
          startInSeconds: start,
          endInSeconds: end,
          durationInseconds: end - start,
          contentId: this.selectedFile.storageFileName,
          title: this.selectedFile.fileName,
          overlayEndTime,
          overlayStartTime,
          overlayCoordinates,
          urlPayload,
          actionType: overlayAction,
          encodedAtWidth,
          overlayVisible,
        } as LessonContentOverlayVideo,
        parentsIds: this.parentId ? [this.parentId] : undefined,
      };
      const result = await this.libraryContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
      );
      this.isLoading = false;
      if (result.success && this.successCallback) {
        this.successCallback();
        this.closeWizard();
      }
    }

    if (this.selectedVimeoFile) {
      this.isLoading = true;
      const model: AddLessonContentModel = {
        data: {
          ivNodeType: OVERLAY_VIMEO_VIDEO_TYPE,
          startInSeconds: start,
          endInSeconds: end,
          durationInSeconds: end - start,
          contentUrl: this.selectedVimeoFile.url,
          title: this.selectedVimeoFile.title,
          placeholderUrl: this.selectedVimeoFile.placeholderUrl,
          overlayEndTime,
          overlayStartTime,
          overlayCoordinates,
          urlPayload,
          actionType: overlayAction,
          encodedAtWidth,
          overlayVisible,
        } as LessonContentVimeoOverlayVideo,
        parentsIds: this.parentId ? [this.parentId] : undefined,
      };
      const result = await this.libraryContentRepo.addLessonContent(
        organizationId,
        lessonId,
        model,
      );
      this.isLoading = false;
      if (result.success && this.successCallback) {
        this.successCallback();
        this.closeWizard();
      }
    }
  }
}

export default AddVideoOverlayStore;
