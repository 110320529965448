import React, {useContext} from 'react';
import {videoIcon} from '../../../assets/images';
import {EditorContext} from '../container/LessonEditorContainer';
import {LessonContentVideo} from '../../../data/models/LessonContent/LessonContentBase';
import {NodeData} from '../models';
import NodeHandles from './NodeHandles';

interface Props {
  data: NodeData;
}

const CardNode = ({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  return (
    <NodeHandles>
      <div
        className={`EditorCard ${
          context.selectedNode?.internalId === data.payload.internalId
            ? 'SelectedEditorCard'
            : ''
        }`}
        onClick={onClick}>
        <p>{(data.payload as LessonContentVideo).title}</p>
        <div className="PlaceholderContainer">
          {(data.payload as LessonContentVideo).placeholderUrl ? (
            <img src={(data.payload as LessonContentVideo).placeholderUrl} />
          ) : (
            <img src={videoIcon} />
          )}
        </div>
      </div>
    </NodeHandles>
  );
};

export default CardNode;
