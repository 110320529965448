import React, {useLayoutEffect, useRef} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {logoTextWhite} from '../../assets/images';
import {Link} from 'react-router-dom';
import {UserPopUpMenu} from '../../modules/UserMenuPopUp';
import {OpenWorkspace} from '../../modules/OpenWorkspace';
import {NotificationsMenuPopUp} from '../../modules/NotificationsMenuPopUp';
import {appText} from '../../assets/text/appText';
import './styles.css';
import {NavigationUserAvatar} from '../../modules/NavigationUserAvatar';

interface Props {
  onHeightCalculated?: (height: number) => void;
}

const NavBar = React.memo(({onHeightCalculated}: Props) => {
  const rootRef = useRef<any>(null);
  const [profileShown, setProfileShown] = React.useState(false);
  const [notificationsShown, setNotificationsShown] = React.useState(false);

  useLayoutEffect(() => {
    if (rootRef && rootRef.current && onHeightCalculated) {
      onHeightCalculated(rootRef.current.clientHeight);
    }
  }, [onHeightCalculated, rootRef]);

  const onToggleProfile = (e: any) => {
    e.stopPropagation();
    setProfileShown(!profileShown);
    setNotificationsShown(false);
  };

  const onToggleNotifications = (e: any) => {
    e.stopPropagation();
    setNotificationsShown(!notificationsShown);
    setProfileShown(false);
  };

  return (
    <div className="NavBarContainer" ref={rootRef}>
      <Container fluid>
        <Row>
          <Col xs={2}>
            <Link to="/home">
              <img
                src={logoTextWhite}
                alt="logo-text-white"
                className="NavBarLogo"
              />
            </Link>
          </Col>
          <Col xs={{offset: 5, span: 5}}>
            <div className="NavBarButtonsContainer">
              <OpenWorkspace />
              <Link
                className="NavBarRegularButton"
                to="https://help.tapybl.com/support/home"
                target="_blank">
                {appText.help}
              </Link>
              <NotificationsMenuPopUp
                shown={notificationsShown}
                onToggle={onToggleNotifications}
                onHide={() => setNotificationsShown(false)}
              />
              <button className="NavBarAvatarButton" onClick={onToggleProfile}>
                <NavigationUserAvatar />
              </button>
              <UserPopUpMenu
                shown={profileShown}
                onHide={() => setProfileShown(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default NavBar;
