import {Edge, Node, Position} from 'reactflow';
import dagre from 'dagre';

const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction = 'TB',
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({rankdir: direction, ranksep: 80});

  nodes.forEach(node => {
    const size = getElementSizeFromType(node.type || '');
    dagreGraph.setNode(node.id, size);
  });

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    const size = getElementSizeFromType(node.type || '');
    node.position = {
      x: nodeWithPosition.x - size.width / 2,
      y: nodeWithPosition.y - size.height / 2,
    };

    return node;
  });

  return {nodes, edges};
};

const getElementSizeFromType = (type: string) => {
  switch (type) {
    case 'elementCard':
    case 'choiceImageNode':
      return {
        width: 140,
        height: 110,
      };
    case 'challangeBranchHeader':
      return {
        width: 151,
        height: 50,
      };
    case 'entryNode':
    case 'choiceHeader':
    case 'challengeBranchQuestionNode':
    case 'choiceQuestion':
      return {
        width: 141,
        height: 50,
      };
    case 'challengeBranchTextNode':
    case 'addNewSourceNode':
    case 'choiceTextOption':
    case 'challengeBranchImageNode':
      return {
        width: 141,
        height: 110,
      };
    case 'mChoiceNode':
      return {
        width: 200,
        height: 140,
      };
    case 'challengeBranchAINode':
      return {
        width: 141,
        height: 200,
      };
    case 'challengeBranchAICorrectNode':
    case 'challengeBranchAIInorrectNode':
      return {
        width: 21,
        height: 12,
      };
    default:
      return {
        width: 140,
        height: 110,
      };
  }
};

export {getLayoutedElements};
