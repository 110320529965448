import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import AddMChoiceWizardUI from '../view/AddMChoiceWizardUI';
import AddMChoiceWizardStore from '../store/AddMChoiceWizardStore';
import {LessonContentMChoiceAnswer} from '../../../../data/models/LessonContent/LessonContentBase';
import {useParams} from 'react-router-dom';
import {GeneratedQuestions} from '../../../../data/models/QuestionGeneration/GeneratedQuestions';

interface Props {
  addMChoiceWizardStore?: AddMChoiceWizardStore;
}

const AddMChoiceWizardContainer = inject('addMChoiceWizardStore')(
  observer(({addMChoiceWizardStore}: Props) => {
    const {organizationId, lessonId} = useParams();
    const [generatedQuestions, setGeneratedQuestions] =
      useState<GeneratedQuestions>();

    useEffect(() => {
      setGeneratedQuestions(undefined);
    }, [addMChoiceWizardStore!.showWizard]);

    const onSave = (
      question: string,
      answers: LessonContentMChoiceAnswer[],
      gradeWeight: number,
    ) => {
      if (organizationId && lessonId) {
        addMChoiceWizardStore!.createMultipleChoice(
          organizationId,
          lessonId,
          question,
          answers,
          gradeWeight,
        );
      }
    };

    const onGenerateQuestions = (topic: string) => {
      if (organizationId) {
        addMChoiceWizardStore!
          .generateQuestions(organizationId, topic)
          .then(setGeneratedQuestions);
      }
    };

    return addMChoiceWizardStore?.isVisible() ? (
      <AddMChoiceWizardUI
        onCancel={() => addMChoiceWizardStore!.cancelWizard()}
        isLoading={addMChoiceWizardStore.isLoading}
        saveChanges={onSave}
        onGenerateQuestions={onGenerateQuestions}
        isLoadingAi={addMChoiceWizardStore!.isLoadingAi}
        generatedQuestions={generatedQuestions}
      />
    ) : null;
  }),
);

export default AddMChoiceWizardContainer;
