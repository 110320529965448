import {ApiResponse} from '../models/ApiResponse';
import {ChangePasswordWithCodeModel} from '../models/Auth/ChangePasswordWithCodeModel';
import {ConfirmEmailModel} from '../models/Auth/ConfirmEmailModel';
import {ConfirmEmailResponse} from '../models/Auth/ConfrimEmailReponse';
import {LoginModel} from '../models/Auth/LoginModel';
import {RegisterModel} from '../models/Auth/RegisterModel';
import {UserLoginResponse} from '../models/Auth/UserLoginResponse';
import {UserRegisterResponse} from '../models/Auth/UserRegisterResponse';
import {apiClient, withAuth} from './baseApiClient';

class AuthApi {
  public loginUser(model: LoginModel): Promise<ApiResponse<UserLoginResponse>> {
    return apiClient.post('/authentication/login', model);
  }

  public loginAdminUser(
    model: LoginModel,
  ): Promise<ApiResponse<UserLoginResponse>> {
    return apiClient.post('/authentication/adminLogin', model);
  }

  public registerUser(
    model: RegisterModel,
  ): Promise<ApiResponse<UserRegisterResponse>> {
    return apiClient.post('/authentication/register', model);
  }

  public async confirmEmail(
    model: ConfirmEmailModel,
  ): Promise<ApiResponse<ConfirmEmailResponse>> {
    const headers = await withAuth({});
    return apiClient.post(
      '/authentication/verify-email',
      {verificationCode: model.code},
      {headers},
    );
  }

  public async sendEmailVerification(): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      '/authentication/send-email-verification',
      {},
      {headers},
    );
  }

  public async verifyUserPermission(
    permission: string,
    organizationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/organizationPermissions/${organizationId}/has_permission?permission=${permission}`,
      {headers},
    );
  }

  public async getUserOrgPermissions(
    organizationId: string,
  ): Promise<ApiResponse<string[]>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/organizationPermissions/${organizationId}/my_permissions`,
      {headers},
    );
  }

  public async invalidateToken(
    refreshToken: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `/authentication/invalidate_tokens?refreshToken=${refreshToken}`,
      {headers},
    );
  }

  public sendForgotPasswordEmail(email: string): Promise<ApiResponse<any>> {
    return apiClient.post('/authentication/send-reset-password-code', {email});
  }

  public changePasswordWithCode(
    model: ChangePasswordWithCodeModel,
  ): Promise<ApiResponse<any>> {
    return apiClient.post('/authentication/reset-password-with-code', model);
  }
}

export default AuthApi;
