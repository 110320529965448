import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';

class SignupStore {
  private authRepo = new AuthRepository();

  public errors: string[] = [];
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async registerUser(email: string, password: string, userName: string) {
    this.isLoading = true;
    const result = await this.authRepo.registerUser({
      email,
      password,
      userName,
    });
    this.isLoading = false;
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.errors = result.errors;
    }
    return false;
  }

  public async sendEmailVerification() {
    this.isLoading = true;
    const result = await this.authRepo.sendEmailVerification();
    this.isLoading = false;
    if (result.success) {
      return result.success;
    } else if (result.errors) {
      this.errors = result.errors;
    }
  }

  public getUser() {
    return this.authRepo.getUser();
  }

  public clearErrors() {
    this.errors = [];
  }
}

export default SignupStore;
