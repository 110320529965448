import React, {useState} from 'react';
import {SelectOption} from '../../../data/models/UI/SelectOption';
import './styles.css';
import {chevronDownIcon} from '../../../assets/images';
import {NA_OPTION} from '../../../data/staticValues/selectOptions';

type DropdownType = 'standart' | 'box';

interface Props {
  heading: string;
  options: SelectOption[];
  placeholder?: string;
  selectedOption?: string | null;
  setSelectedOption: (option: string) => void;
  className?: string;
  withNA?: boolean;
  fieldError?: boolean;
  disabled?: boolean;
  isMandatory?: boolean;
  type?: DropdownType;
}

const Dropdown = React.memo(
  ({
    heading,
    options,
    placeholder,
    selectedOption,
    setSelectedOption,
    className,
    withNA,
    fieldError,
    disabled,
    isMandatory,
    type,
  }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const getOptions = () => {
      if (withNA) {
        return [NA_OPTION, ...options];
      }
      return options;
    };

    const toggle = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    };

    const onOptionClicked = (value: string) => () => {
      setSelectedOption(value);
      setIsOpen(false);
    };

    const getSelectedOption = () => {
      return getOptions().find(option => option.key === selectedOption)?.value;
    };

    const getRootClassByType = () => {
      switch (type) {
        case 'box':
          return 'DropdownBodyBox';
        default:
          return 'DropdownBody';
      }
    };

    return (
      <div className={`${className}`}>
        <p className={`DropdownTitle ${disabled && 'DropdownDisabled'}`}>
          {heading}
          {isMandatory && !disabled ? (
            <p className="MandatoryIndicator">*</p>
          ) : (
            ''
          )}
        </p>
        <div className="DropdownContainer">
          <div
            className={`${getRootClassByType()} ${
              !selectedOption ? 'Placeholder' : ''
            } ${fieldError ? 'FieldError' : ''} ${
              disabled && 'DropdownDisabled'
            }`}
            onClick={toggle}>
            <span>{getSelectedOption() || placeholder}</span>
            <img
              src={chevronDownIcon}
              alt="chevron_down"
              className={isOpen ? 'ChevronOpen' : ''}
            />
          </div>
          {isOpen && (
            <ul className="DropdownList">
              {getOptions().map(option => (
                <li
                  className="ListItem"
                  onClick={onOptionClicked(option.key)}
                  key={option.key}>
                  {option.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  },
);

export default Dropdown;
