import secureLocalStorage from 'react-secure-storage';

class LocalStorage {
  public static Keys = {
    USER_KEY: 'key_user',
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    USER_INFO: 'user_info',
  };

  public saveToStorage(key: string, data: string | number | boolean | object) {
    secureLocalStorage.setItem(key, data);
  }

  public getItemFromStorage(key: string) {
    return secureLocalStorage.getItem(key);
  }

  public removeFromStorage(key: string) {
    secureLocalStorage.removeItem(key);
  }

  public wipeLocalStorage() {
    secureLocalStorage.clear();
  }
}

export default LocalStorage;
