import React, {useEffect, useState} from 'react';
import WorkspaceUI from '../view/WorkspaceUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import WorkspaceStore from '../store/WorkspaceStore';
import {Organization} from '../../../data/models/Oganization/Organization';
import {OrganizationSubscription} from '../../../data/models/Subscription/Subscription';

interface Props {
  workspaceStore?: WorkspaceStore;
}

const WorkspaceContainer = inject('workspaceStore')(
  observer(({workspaceStore}: Props) => {
    const [organization, setOrganization] = useState<Organization>();
    const [subscription, setSubscription] =
      useState<OrganizationSubscription>();
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      workspaceStore!.getMyOrganization().then(setOrganization);
      if (organizationId) {
        workspaceStore!
          .getOrgSubscription(organizationId)
          .then(setSubscription);
      }
    }, []);

    const onStartTrial = () => {
      navigate(`/subscription/${organizationId}/select_trial`);
    };

    const onSubscribe = () => {
      navigate(`/subscription/${organizationId}/select`);
    };

    return organization ? (
      <WorkspaceUI
        subscription={subscription}
        usedTrial={organization.trialSubscriptionUsed || false}
        onStartTrial={onStartTrial}
        isLoading={workspaceStore!.isLoading}
        onSubscribe={onSubscribe}
        loadingSubscription={workspaceStore!.loadingSubscription}
      />
    ) : null;
  }),
);

export default WorkspaceContainer;
