import {ApiResponse, PaginatedApiReponse} from '../models/ApiResponse';
import {MediaFile, VimeoMediaFile} from '../models/LibraryFile/MediaFile';
import {apiClient, withAuth} from './baseApiClient';

class LibraryApi {
  public async uploadLibraryVideo(
    organizationId: string,
    video: File,
    duration: number,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    const form = new FormData();
    form.append('file', video);
    form.append('duration', duration.toString());
    return apiClient.post(`/mediaLibrary/${organizationId}/upload`, form, {
      headers,
    });
  }

  public async getLibraryFiles(
    page: number,
    organizationId: string,
    pageSize: number,
    orderBy: string,
    typeFilter: number,
    search?: string,
    orderAsk?: boolean,
  ): Promise<PaginatedApiReponse<MediaFile>> {
    const headers = await withAuth({});
    return apiClient.post(
      `/mediaLibrary/${organizationId}/list`,
      {
        page,
        pageSize,
        orderBy,
        typeFilter,
        search,
        orderAsk,
      },
      {headers},
    );
  }

  public async getFileByContentId(
    organizationId: string,
    contnentId: string,
  ): Promise<ApiResponse<MediaFile>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/mediaLibrary/${organizationId}/get_by_content?contentId=${contnentId}`,
      {headers},
    );
  }

  public async deleteMediaFile(
    organizationId: string,
    mediaFileId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(`/mediaLibrary/${organizationId}/${mediaFileId}`, {
      headers,
    });
  }

  public async getVimeoVideos(
    organizationId: string,
    page: number,
    pageSize: number,
  ): Promise<ApiResponse<VimeoMediaFile[]>> {
    const headers = await withAuth({});
    return apiClient.get(
      `/mediaLibrary/${organizationId}/vimeo/videos?page=${page}&pageSize=${pageSize}`,
      {
        headers,
      },
    );
  }
}

export default LibraryApi;
