import {makeAutoObservable, runInAction} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';

class CreateOrganizationStore {
  private orgRepo = new OrganizationRepository();

  public errors: string[] = [];
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserOrganizations() {
    this.isLoading = true;
    const result = await this.orgRepo.getMyOrganizations();
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getUserLearnerOrganizations() {
    this.isLoading = true;
    const result = await this.orgRepo.tryGetLearnerOrganization();
    this.isLoading = false;
    return result.data;
  }

  public async createOrganization(
    name: string,
    description: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) {
    runInAction(() => {
      this.isLoading = true;
    });
    const result = await this.orgRepo.createOrganization(
      name,
      description,
      primaryColor,
      secondaryColor,
      type,
      image,
    );
    runInAction(() => {
      this.isLoading = false;
    });
    if (!result.success && result.errors) {
      this.errors = result.errors;
    }
    return result.data;
  }

  public clearErrors() {
    this.errors = [];
  }
}

export default CreateOrganizationStore;
