import React, {useState} from 'react';
import {foldersText} from '../foldersText';
import FolderOptions from '../components/FolderOptions';
import {Folder} from '../../../data/models/Course/Folder';
import {Course} from '../../../data/models/Course/Course';
import {getStructureFromKeySingular} from '../../../data/staticValues/courseStructure';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import EditorHeader from '../../../uiToolkit/Editor/EditorHeader';
import EditorLayout from '../../../uiToolkit/Editor/EditorLayout';
import EditorOptions from '../../../uiToolkit/Editor/EditorOptions';
import EditorDraggableList from '../../../uiToolkit/Editor/EditorDraggableList';
import FolderCard from '../components/FolderCard';
import './styles.css';
import EditorAddBelowButton from '../../../uiToolkit/Editor/EditorAddBelowButton';
import EditorSidebarNavigation from '../../../uiToolkit/Editor/EditorSidebarNavigation';
import {ActiveTabs} from '../../../data/models/UI/ActiveTabs';
interface Props {
  items: Folder[];
  onBack: () => void;
  course: Course;
  onEdit: (folder: Folder) => void;
  isLoading: boolean;
  onDelete: (folderId: string) => void;
  onCreate: () => void;
  onItemsReordered: (items: Folder[]) => void;
  onNext: () => void;
  onOpen: (folder: Folder) => void;
  onPublish: () => void;
  breadcrumbs: {title: string; link?: string}[];
  sidebarOptions: any;
  activeTabs: ActiveTabs;
  onSearch: (search: string) => void;
}

interface FolderEditorSelectedItem {
  original?: Folder;
  edit?: Folder;
}

const OPTIONS_TAB = 'Options';
const FOLDER_OPTIONS = 'FolderOptions';

const FoldersUI = React.memo(
  ({
    items,
    onBack,
    course,
    onEdit,
    isLoading,
    onDelete,
    onCreate,
    onItemsReordered,
    onNext,
    onOpen,
    onPublish,
    breadcrumbs,
    sidebarOptions,
    activeTabs,
    onSearch,
  }: Props) => {
    const [folderToEdit, setFolderToEdit] = useState<FolderEditorSelectedItem>({
      original: undefined,
      edit: undefined,
    });
    const [folderToDelete, setFolderToDelete] = useState<Folder>();
    const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);

    const clearSelectedFolder = () => {
      if (editWasMade()) {
        setShowUnsavedChanges(true);
      } else {
        discardChanges();
      }
    };

    const editWasMade = () => {
      return (
        folderToEdit.original?.name !== folderToEdit.edit?.name ||
        folderToEdit.original?.description !== folderToEdit.edit?.description ||
        folderToEdit.edit?.newImage !== undefined ||
        (folderToEdit.original?.image !== undefined &&
          folderToEdit.edit?.image === undefined)
      );
    };

    const saveChanges = async () => {
      if (folderToEdit.edit) {
        await onEdit(folderToEdit.edit);
        discardChanges();
      }
    };

    const discardChanges = () => {
      setFolderToEdit({original: undefined, edit: undefined});
      setShowUnsavedChanges(false);
    };

    const onDeleteFolder = () => {
      if (folderToDelete) {
        discardChanges();
        setFolderToDelete(undefined);
        onDelete(folderToDelete?.id || '');
      }
    };

    const onEditFolder = (field: string, value: any) => {
      if (folderToEdit.original && folderToEdit.edit) {
        setFolderToEdit({
          original: folderToEdit.original,
          edit: {
            ...folderToEdit.edit,
            [field]: value,
          },
        });
      }
    };

    const onSelectFolder = (folder: Folder) => {
      setFolderToEdit({
        original: folder,
        edit: {...folder},
      });
    };

    return (
      <div className="FoldersRoot">
        <EditorLayout
          isPublished={course.isPublished}
          onContentClick={clearSelectedFolder}
          isLoading={isLoading}
          showSidebar={showSidebar}
          sidebarSet={() => setShowSidebar(!showSidebar)}
          header={
            <EditorHeader
              breadcrumbs={breadcrumbs}
              onBack={onBack}
              onNext={onNext}
              nextTitle={foldersText.next}
              onPublish={onPublish}
            />
          }
          sideBar={
            <EditorSidebarNavigation
              onSearchKeyChange={onSearch}
              sidebarOptions={sidebarOptions}
              activeTabs={activeTabs}
            />
          }
          content={
            items.length ? (
              <EditorDraggableList<Folder>
                items={items}
                onItemsReordered={onItemsReordered}
                renderFooter={isDragging => (
                  <EditorAddBelowButton
                    isDragging={isDragging}
                    onClick={onCreate}
                    tooltip={foldersText.newFolder(
                      getStructureFromKeySingular(course.structure.toString()),
                    )}
                  />
                )}
                renderItem={(item, isDragging) => (
                  <FolderCard
                    folder={item}
                    onSelect={onSelectFolder}
                    isSelected={folderToEdit.original?.id === item.id}
                    isDragging={isDragging}
                    onOpen={onOpen}
                  />
                )}
              />
            ) : (
              <div />
            )
          }
          options={
            <EditorOptions
              entityToEdit={folderToEdit.edit}
              entityType={FOLDER_OPTIONS}
              emptyStateText={foldersText.optionsEmpty(
                getStructureFromKeySingular(course.structure.toString()),
              )}
              tabs={[{tabName: OPTIONS_TAB}]}>
              <FolderOptions
                contentForTab={OPTIONS_TAB}
                structure={course.structure}
                optionsForType={FOLDER_OPTIONS}
                onDelete={setFolderToDelete}
                onEditFolder={onEditFolder}
                saveChanges={saveChanges}
                saveEnabled={editWasMade()}
                onDiscardChanges={discardChanges}
                isLoading={isLoading}
              />
            </EditorOptions>
          }
        />
        <ConfirmationModal
          title={foldersText.deleteConfirmation(
            getStructureFromKeySingular(
              course.structure.toString(),
            ).toLowerCase(),
          )}
          onCancel={() => setFolderToDelete(undefined)}
          onAccept={onDeleteFolder}
          shown={folderToDelete !== undefined}
          acceptText={foldersText.deleteAction}
        />
        <ConfirmationModal
          title={foldersText.discardChangesConfirmation}
          onCancel={() => setShowUnsavedChanges(false)}
          onAccept={discardChanges}
          shown={showUnsavedChanges}
        />
      </div>
    );
  },
);

export default FoldersUI;
