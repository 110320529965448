import React, {useEffect, useState} from 'react';
import LibraryUI from '../view/LibraryUI';
import {inject, observer} from 'mobx-react';
import VideoSourcesStore from '../store/VideoSourcesStore';
import {useParams} from 'react-router-dom';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../data/models/LibraryFile/MediaFile';
import ConfirmationModal from '../../../uiToolkit/ConfirmationModal';
import {videoSourcesText} from '../videoSourcesText';
import NavigationStore from '../../../navigation/store/NavigationStore';

interface Props {
  videoSourcesStore?: VideoSourcesStore;
  navigationStore?: NavigationStore;
}

const PAGE_SIZE = 10;

const LibraryContanier = inject(
  'videoSourcesStore',
  'navigationStore',
)(
  observer(({videoSourcesStore, navigationStore}: Props) => {
    const [vimeoVideos, setVimeoVideos] = useState<VimeoMediaFile[]>([]);
    const [videoSources, setVideoSources] = useState<MediaFile[]>([]);
    const [recentFiles, setRecentFiles] = useState<MediaFile[]>([]);
    const [storageLimit, setStorageLimit] = useState(0);
    const [search, setSearch] = useState('');
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOptions, setSortOptions] = useState({
      column: 'file_name',
      order: 'desc',
    });
    const [fileToDeleteId, setFileToDeleteId] = useState<string>();
    const {organizationId} = useParams();

    useEffect(() => {
      fetchFiles();
      if (recentFiles.length === 0) {
        fetchRecentFiles();
      }
      if (organizationId) {
        videoSourcesStore!
          .getStorageLimit(organizationId)
          .then(setStorageLimit);
        videoSourcesStore!
          .getVimeoVideos(organizationId, 1, 10)
          .then(setVimeoVideos);
      }
    }, [videoSourcesStore, search]);

    useEffect(() => {
      fetchFiles();
    }, [currentPage, search, sortOptions]);

    const fetchFiles = () => {
      if (organizationId) {
        videoSourcesStore
          ?.getMyVideoSources(
            currentPage - 1,
            organizationId,
            PAGE_SIZE,
            sortOptions.column,
            search,
            sortOptions.order === 'asc',
          )
          .then(res => {
            setVideoSources(res.data || []);
            setPages(res.pagesCount);
          });
      }
    };

    const fetchRecentFiles = () => {
      if (organizationId) {
        videoSourcesStore
          ?.getMyVideoSources(
            0,
            organizationId,
            PAGE_SIZE,
            'created_date',
            '',
            false,
          )
          .then(res => {
            setRecentFiles(res.data || []);
          });
      }
    };

    const onUpload = (video: File, duration: number) => {
      if (organizationId) {
        videoSourcesStore
          ?.uploadLibraryVideo(organizationId, video, duration)
          .then(() => {
            fetchFiles();
            fetchRecentFiles();
            navigationStore!.getOrganizationDetails(organizationId);
          });
      }
    };

    const onSearchChange = (search: string) => {
      setSearch(search);
      setCurrentPage(1);
    };

    const onItemClick = () => {
      // navigate('/video_editor');
    };

    const onDelete = () => {
      if (organizationId && fileToDeleteId) {
        videoSourcesStore!
          .deleteMediaFile(organizationId, fileToDeleteId)
          .then(() => {
            fetchFiles();
            fetchRecentFiles();
            navigationStore!.getOrganizationDetails(organizationId);
          });
        setFileToDeleteId(undefined);
      }
    };

    const onSort = (selectedColumn: any, sortDirection: any) => {
      setSortOptions({column: selectedColumn.sortField, order: sortDirection});
    };

    return (
      <>
        <LibraryUI
          videoSources={videoSources}
          onItemClick={onItemClick}
          search={search}
          setSearch={onSearchChange}
          isLoading={videoSourcesStore!.isLoading}
          onUpload={onUpload}
          pages={pages}
          onPageChange={setCurrentPage}
          recentFiles={recentFiles}
          onDelete={setFileToDeleteId}
          storageLimit={storageLimit}
          onSort={onSort}
          vimeoVideos={vimeoVideos}
        />
        <ConfirmationModal
          shown={fileToDeleteId !== undefined}
          onAccept={onDelete}
          onCancel={() => setFileToDeleteId(undefined)}
          acceptText={videoSourcesText.delete}
          title={videoSourcesText.confirmDelete}
        />
      </>
    );
  }),
);

export default LibraryContanier;
