import React from 'react';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import {learnerInvitationsText} from '../learnerInvitationsText';
import Button from '../../../../uiToolkit/Buttons/Button';
import DataTable from 'react-data-table-component';
import {LearnerInvitation} from '../../../../data/models/Learners/LearnerInvitation';
import {formatDateTimeTextFormat} from '../../../../utils/notificationTimeTextConvertor';
import TablePagination from '../../../../uiToolkit/TablePagination';
import './styles.css';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {deleteIcon} from '../../../../assets/images';
import {listLearnersText} from '../../List/listLearnersText';

const columns = (onDelete: (id: string) => void) => [
  {
    name: 'Email',
    selector: (row: LearnerInvitation) => row.invitedUserEmail,
    cell: (row: LearnerInvitation) => (
      <h5 className={'TableText'} data-tag="allowRowEvents">
        {row.invitedUserEmail}
      </h5>
    ),
    id: 'email',
  },
  {
    name: 'Status',
    selector: () => true,
    cell: () => (
      <h5 className={'TableText InvitedStatus'} data-tag="allowRowEvents">
        {learnerInvitationsText.invited}
      </h5>
    ),
    id: 'learner_status',
  },
  {
    name: 'Date Invited',
    selector: (row: LearnerInvitation) =>
      formatDateTimeTextFormat(row.dateCreated),
    cell: (row: LearnerInvitation) => (
      <h5 className={'TableText'} data-tag="allowRowEvents">
        {formatDateTimeTextFormat(row.dateCreated)}
      </h5>
    ),
    id: 'learner_status',
  },
  {
    name: 'Actions',
    cell: (row: LearnerInvitation) => (
      <IconButton
        onClick={() => onDelete(row.id)}
        icon={deleteIcon}
        tooltip={listLearnersText.deleteInvitation}
      />
    ),
  },
];

interface Props {
  onAddNewLearners: () => void;
  search: string;
  setSearch: (search: string) => void;
  invitations: LearnerInvitation[];
  pages: number;
  onPageChange: (page: number) => void;
  onDelete: (id: string) => void;
}

const LearnerInvitationsUI = React.memo(
  ({
    onAddNewLearners,
    search,
    setSearch,
    invitations,
    pages,
    onPageChange,
    onDelete,
  }: Props) => {
    return (
      <div className="LearnerInvitationsContainer">
        <div className="Header">
          <SearchInput value={search} onChange={setSearch} className="Input" />
          <div className="AddButtonContainer">
            <Button
              title={learnerInvitationsText.invite}
              onClick={onAddNewLearners}
              uiType="action"
              className="AddButton"
            />
          </div>
        </div>
        <div>
          <DataTable
            data={invitations}
            columns={columns(onDelete)}
            className="Table"
            onChangePage={onPageChange}
            pagination
            paginationServer
            paginationPerPage={10}
            paginationTotalRows={pages * 10}
            paginationComponent={TablePagination}
            striped
            noDataComponent={
              <p className="NoLearners">
                {learnerInvitationsText.noInvitations}
              </p>
            }
          />
        </div>
      </div>
    );
  },
);

export default LearnerInvitationsUI;
