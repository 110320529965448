import {makeAutoObservable} from 'mobx';
import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {
  CreateLearnerLessonModel,
  LearnerProgressModel,
} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import CoursesRepository from '../../../data/repositories/CoursesRepository';

class VideoPlaybackStore {
  private lessonRepository = new LessonsRepository();
  private courseRepository = new CoursesRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getLessonPresentation(courseId: string, lessonId: string) {
    this.isLoading = true;
    const result = await this.lessonRepository.getLessonPresentation(
      courseId,
      lessonId,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getLessonPreview(
    organizationId: string,
    courseId: string,
    lessonId: string,
  ) {
    this.isLoading = true;
    const result = await this.lessonRepository.getLessonPreview(
      organizationId,
      courseId,
      lessonId,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getLearnerProgress(courseId: string, lessonId: string) {
    this.isLoading = true;
    const result = await this.lessonRepository.getLearnerProgress(
      courseId,
      lessonId,
    );
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async createLessonLearner(
    courseId: string,
    model: CreateLearnerLessonModel,
  ) {
    this.isLoading = true;
    await this.lessonRepository.createLessonLearner(courseId, model);
    this.isLoading = false;
  }

  public async editLessonLearnerProgress(
    courseId: string,
    lessonId: string,
    model: LearnerProgressModel,
  ) {
    this.isLoading = true;
    const result = await this.lessonRepository.editLessonLearnerProgress(
      courseId,
      lessonId,
      model,
    );
    this.isLoading = false;
    return result.data;
  }

  public async getLearnerCourseProgress(courseId: string) {
    this.isLoading = true;
    const result =
      await this.courseRepository.getLearnerCourseProgress(courseId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default VideoPlaybackStore;
