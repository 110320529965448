import {makeAutoObservable} from 'mobx';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import SubscriptionRepository from '../../../data/repositories/SubscriptionRepository';

class WorkspaceStore {
  private subscriptionRepo = new SubscriptionRepository();
  private organizationRepo = new OrganizationRepository();

  public isLoading = false;
  public loadingSubscription = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getMyOrganization() {
    this.isLoading = true;
    const result = await this.organizationRepo.getMyOrganizations();
    this.isLoading = false;
    if (result.success && result.data && result.data.length) {
      return result.data[0];
    }
  }

  public async getOrgSubscription(organizationId: string) {
    this.loadingSubscription = true;
    const result =
      await this.subscriptionRepo.getOrganizationSubscription(organizationId);
    this.loadingSubscription = false;
    if (result.success && result.data) {
      return result.data;
    }
  }
}

export default WorkspaceStore;
