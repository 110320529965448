import React, {useCallback, useLayoutEffect, useRef} from 'react';
import PathTapHandles from './PathTapHandles';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';

interface Props {
  points: {[key: number]: Point};
  setPoints: (points: {[key: number]: Point}) => void;
}

const PathTap = React.memo(({points, setPoints}: Props) => {
  const [position, setPosition] = React.useState({
    active: false,
    offset: {x: 0, y: 0},
  });
  const canvasRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (canvasRef.current) {
      // const width = canvasRef.current.clientWidth;
      // const height = canvasRef.current.clientHeight;
    }
  }, []);

  const handlePointerDown = (e: any) => {
    const el = e.target;
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    el.setPointerCapture(e.pointerId);
    setPosition({
      ...position,
      active: true,
      offset: {
        x,
        y,
      },
    });
  };

  const handlePointerMove = (e: any) => {
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    if (position.active) {
      let newPoints = {...points};
      const handles = Object.keys(points);
      handles.forEach((handle: string) => {
        newPoints = {
          ...newPoints,
          [parseInt(handle, 10)]: {
            x: points[parseInt(handle, 10)].x - (position.offset.x - x),
            y: points[parseInt(handle, 10)].y - (position.offset.y - y),
          },
        };
      });
      setPoints(newPoints);
    }
  };

  const handlePointerUp = () => {
    setPosition({
      ...position,
      active: false,
    });
  };

  const getObjectPath = useCallback(() => {
    let path = 'M';
    if (Object.keys(points).length === 0) {
      return '';
    }

    Object.keys(points)
      .sort((x, y) => (x < y ? 1 : -1))
      .forEach((handle: string) => {
        if (points[parseInt(handle, 10)]) {
          path +=
            points[parseInt(handle, 10)].x +
            ' ' +
            points[parseInt(handle, 10)].y +
            ' ';
        }
      });
    path += 'z';
    return path;
  }, [points]);

  return (
    <g ref={canvasRef}>
      <path
        fillOpacity={0.4}
        fill={'#3DA2FF'}
        d={getObjectPath()}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      />
      <PathTapHandles points={points} setPoints={setPoints} />
    </g>
  );
});

export default PathTap;
