import React, {useEffect, useState} from 'react';
import OpenWorkspaceUI from '../view/OpenWorkspaceUI';
import OpenWorkspaceStore from '../store/OpenWorkspaceStore';
import {inject, observer} from 'mobx-react';
import {Organization} from '../../../data/models/Oganization/Organization';
import {useNavigate} from 'react-router-dom';

interface Props {
  openWorkspaceStore?: OpenWorkspaceStore;
}

const OpenWorkspaceContainer = inject('openWorkspaceStore')(
  observer(({openWorkspaceStore}: Props) => {
    const [userOrganizations, setUserOrganizations] = useState<Organization[]>(
      [],
    );

    const navigate = useNavigate();

    useEffect(() => {
      openWorkspaceStore!.getUserOrganizations().then(setUserOrganizations);
    }, []);

    const onOpenWorkspace = () => {
      if (userOrganizations.length === 1) {
        navigate(`/manage/${userOrganizations[0].id}/workspace`);
      }
    };

    return userOrganizations.length ? (
      <OpenWorkspaceUI onOpenDashboard={onOpenWorkspace} />
    ) : (
      <div />
    );
  }),
);

export default OpenWorkspaceContainer;
