import React from 'react';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import ImagePickerInput from '../../../../uiToolkit/Inputs/ImagePickerInput';
import {resizeImage} from '../../../../utils/resizeImage';
import './styles.css';
import {userProfileText} from '../../userProfileText';
import {UserInfo} from '../../../../data/models/User/UserInfo';
import PasswordInput from '../../../../uiToolkit/Inputs/PasswordInput';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';

const ORG_AVATAR_WIDTH = 300;
const ORG_AVATAR_HEIGHT = 300;

interface Props {
  onSave: (fullName: string) => void;
  onChangePassword: (
    password: string,
    newPassword: string,
    confirmNewPassword: string,
  ) => void;
  onUploadImage: (image: File) => void;
  userProfile: UserInfo;
}

const SettingsUserProfileUI = React.memo(
  ({onSave, onChangePassword, onUploadImage, userProfile}: Props) => {
    const [fullName, setFullName] = React.useState(userProfile.name);
    const [email, setEmail] = React.useState(userProfile.email);
    const [profilePicture, setProfilePicture] = React.useState<File>();
    const [pictureChanged, setPictureChanged] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
    const [isPasswordInputFocused, setIsPasswordInputFocused] =
      React.useState(false);

    const onInitiateSave = () => {
      if (fullName.trim() !== userProfile.name) {
        if (fullName.trim().length >= 2) {
          onSave(fullName.trim());
        } else {
          toast.error(userProfileText.nameShortLength, toastConfig);
        }
      }

      if (pictureChanged && profilePicture) {
        onUploadImage(profilePicture);
      }

      if (
        password.trim() !== '' ||
        newPassword.trim() !== '' ||
        confirmNewPassword.trim() !== ''
      ) {
        if (newPassword.trim() === confirmNewPassword.trim()) {
          onChangePassword(
            password.trim(),
            newPassword.trim(),
            confirmNewPassword.trim(),
          );
        } else if (
          newPassword.trim() === '' ||
          confirmNewPassword.trim() === ''
        ) {
          toast.error(
            userProfileText.passwordAndConfirmPasswordEmpty,
            toastConfig,
          );
        } else if (password.trim() === '') {
          toast.error(userProfileText.currentPasswordEmpty, toastConfig);
        } else {
          toast.error(
            userProfileText.passwordAndConfirmPasswordMismatch,
            toastConfig,
          );
        }
      }
    };

    const onChangeImage = async (file?: File) => {
      if (file) {
        const compressed = await resizeImage(
          file,
          ORG_AVATAR_WIDTH,
          ORG_AVATAR_HEIGHT,
        );
        setProfilePicture(compressed);
        setPictureChanged(true);
      }
    };

    return (
      <div className="SettingsUserProfileContainer">
        <TextInput
          value={fullName}
          onChange={(value: string) => setFullName(value)}
          placeholder={userProfileText.namePlaceholder}
          heading={userProfileText.nameTitle}
          maxCharacters={50}
          className="SettingsInput"
          isMandatory
        />
        <ImagePickerInput
          image={profilePicture}
          onChange={onChangeImage}
          heading={userProfileText.imageTitle}
          placeholder={userProfileText.imageSubtitle}
          existingImage={userProfile.avatarUrl}
          className="SettingsInput"
        />
        <TextInput
          value={email}
          onChange={setEmail}
          placeholder={userProfileText.emailPlaceholder}
          heading={userProfileText.emailTitle}
          className="SettingsInput"
          disabled={true}
        />
        <p>Change Password</p>
        <div className="ForgotPasswordInputContainer">
          <PasswordInput
            value={password}
            onChange={(value: string) => setPassword(value)}
            showValidation={false}
            placeholder={userProfileText.currentPasswordPlaceholder}
          />
        </div>

        <div className="ForgotPasswordInputContainer">
          <PasswordInput
            value={newPassword}
            onChange={(value: string) => setNewPassword(value)}
            showValidation={isPasswordInputFocused}
            placeholder={userProfileText.newPasswordPlaceholder}
            onFocus={() => setIsPasswordInputFocused(true)}
            onBlur={() => setIsPasswordInputFocused(false)}
          />
        </div>
        <div className="ForgotPasswordInputContainer">
          <PasswordInput
            value={confirmNewPassword}
            onChange={(value: string) => setConfirmNewPassword(value)}
            showValidation={false}
            placeholder={userProfileText.confirmPasswordPlaceholder}
          />
        </div>
        <div>
          <button onClick={onInitiateSave}> Save Changes </button>
        </div>
      </div>
    );
  },
);

export default SettingsUserProfileUI;
