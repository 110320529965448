import React from 'react';
import {LessonPresentation} from '../../../data/models/Course/LessonPresentation';
import {videoPlaybackText} from '../videoPlaybackText';

interface Props {
  lessonPresentation: LessonPresentation;
  currentLessonId: string;
}

const VideoPlaybackDetails = React.memo(
  ({lessonPresentation, currentLessonId}: Props) => {
    return (
      <div className="VideoPlaybackDetails">
        <div className="InfoContainer">
          <img
            src={lessonPresentation.organization.avatar}
            alt="avatar"
            className="Avatar"
          />
          <h5 className="Title">{lessonPresentation.organization.name}</h5>
          <h5 className="Title">
            {videoPlaybackText.course}
            {lessonPresentation.course.title}
          </h5>
          {/* <img src={ratingsIc} />
          <img src={likeDislikeIc} /> */}
          {/* <h5 className='StatsText'>10 views{"  "}|{"  "}20 taps{"  "}|{"  "}5 min ago</h5> */}
        </div>
        <div className="VideoDescriptionContainer">
          <h3>
            {
              lessonPresentation.lessons.find(
                item => item.id === currentLessonId,
              )?.title
            }
          </h3>
          <h5>
            {
              lessonPresentation.lessons.find(
                item => item.id === currentLessonId,
              )?.description
            }
          </h5>
        </div>
      </div>
    );
  },
);

export default VideoPlaybackDetails;
