import React from 'react';
import NavBar from '../../../uiToolkit/NavBar';
import {Col, Container, Row} from 'react-bootstrap';
import PlaylistItem from '../components/PlaylistItem';
import VideoPlaybackDetails from '../components/VideoPlaybackDetails';
import {LessonPresentation} from '../../../data/models/Course/LessonPresentation';
import {GradeResult} from '../models';
import PreviewHeader from '../components/PreviewHeader';
import {TapyblPlayer} from '../../../modules/TapyblPlayer';
import {LearnerProgressModel} from '../../../data/models/LessonContent/CreateLearnerLessonModel';
import './styles.css';

interface Props {
  presentation: LessonPresentation;
  currentLessonId: string;
  gradeResult?: GradeResult;
  preview?: boolean;
  onBack: () => void;
  onCourseHome: () => void;
  onContinue: () => void;
  isLoading?: boolean;
  nextLessonId?: string;
  finishedLessons: string[];
  onOpenLesson: (lessonId: string) => void;
  passingScore?: number;
  onFinishedLesson: (result: LearnerProgressModel) => void;
}

const VideoPlaybackUI = React.memo(
  ({
    presentation,
    currentLessonId,
    gradeResult,
    preview,
    onBack,
    onCourseHome,
    onContinue,
    isLoading,
    nextLessonId,
    finishedLessons,
    onOpenLesson,
    passingScore,
    onFinishedLesson,
  }: Props) => {
    const getNonSupplementalLessons = () => {
      return presentation.lessons
        .sort((x, y) => (x.order > y.order ? 1 : -1))
        .filter(x => !x.isSupplementalLesson);
    };
    return (
      <div>
        <NavBar />
        <Container>
          {preview && <PreviewHeader onBack={onBack} />}
          <Row>
            <Col lg={10} md={12} className="pt-4 player_container">
              <TapyblPlayer
                preview={preview || false}
                isLoading={isLoading || false}
                lessonContent={presentation.lessonContent}
                onContinue={onContinue}
                onCourseHome={onCourseHome}
                passingScore={passingScore || 0}
                onFinishedLesson={onFinishedLesson}
                isGradable={
                  presentation.lessons.find(item => item.id === currentLessonId)
                    ?.isGradable || false
                }
                gradeResult={gradeResult}
                nextLessonIsTheSame={nextLessonId === currentLessonId}
                nextLessonId={nextLessonId}
              />
              <VideoPlaybackDetails
                lessonPresentation={presentation}
                currentLessonId={currentLessonId}
              />
            </Col>
            <Col lg={2} md={12} className="pt-4">
              {!preview && (
                <div className="VideoPlaybabackPlaylist">
                  <h2 className="Title">{presentation.folder.name}</h2>
                  {getNonSupplementalLessons().map((item, index) => (
                    <PlaylistItem
                      item={item}
                      key={item.id}
                      currentlyPlayingId={currentLessonId}
                      isLast={index === getNonSupplementalLessons().length - 1}
                      completed={finishedLessons.includes(item.id)}
                      onClick={() => onOpenLesson(item.id)}
                    />
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default VideoPlaybackUI;
