import {makeAutoObservable} from 'mobx';
import AuthRepository from '../../../data/repositories/AuthRepository';
import {internalErrorCodes} from '../../../data/errorCodes';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';

class ConfirmEmailStore {
  private authRepo = new AuthRepository();
  private organizationRepo = new OrganizationRepository();

  public errors: string[] = [];
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async confirmEmail(code: string) {
    this.isLoading = true;
    const result = await this.authRepo.confirmEmail(code);
    this.isLoading = false;
    if (result && result.success) {
      return result.success;
    } else if (result && result.errors) {
      this.errors = result.errors;
    } else {
      this.errors = [internalErrorCodes.SOMETHING_WENT_WRONG];
    }
    return false;
  }

  public async getLearnerWorkspace() {
    this.isLoading = true;
    const result = await this.organizationRepo.tryGetLearnerOrganization();
    this.isLoading = false;
    return result.data;
  }

  public async getMyWorkspace() {
    this.isLoading = true;
    const result = await this.organizationRepo.getMyOrganizations();
    this.isLoading = false;
    return result.data;
  }

  public async sendEmailVerification() {
    const result = await this.authRepo.sendEmailVerification();
    if (result && result.success) {
      return result.success;
    } else if (result && result.errors) {
      this.errors = result.errors;
    } else {
      this.errors = [internalErrorCodes.SOMETHING_WENT_WRONG];
    }
    return false;
  }

  public getUser() {
    return this.authRepo.getUser();
  }

  public clearUser() {
    return this.authRepo.logout();
  }

  public clearErrors() {
    this.errors = [];
  }
}

export default ConfirmEmailStore;
