import React, {useEffect, useState} from 'react';
import TextInput from '../../uiToolkit/Inputs/TextInput';
import ImagePickerInput from '../../uiToolkit/Inputs/ImagePickerInput';
import {orgDataFormText} from './orgDataFormText';
import {Organization} from '../../data/models/Oganization/Organization';
import {resizeImage} from '../../utils/resizeImage';
import './styles.css';
import Dropdown from '../../uiToolkit/Inputs/Dropdown';
import Button from '../../uiToolkit/Buttons/Button';
import {HexColorPicker} from 'react-colorful';
import {generateColorPalette} from '../../utils/generatePallete';

const ORG_AVATAR_WIDTH = 300;
const ORG_AVATAR_HEIGHT = 300;

interface Props {
  onSubmit: (
    name: string,
    description: string,
    primaryColor: string,
    secondaryColor: string,
    type: number,
    image?: File,
  ) => void;
  onCancel: () => void;
  submitText: string;
  isLoading: boolean;
  organization?: Organization;
  skipColors?: boolean;
}

const OrgDataForm = React.memo(
  ({
    onSubmit,
    submitText,
    onCancel,
    isLoading,
    organization,
    skipColors,
  }: Props) => {
    const [name, setName] = useState('');
    const [image, setImage] = useState<File>();
    const [description, setDescription] = useState('');
    const [primaryColor, setPrimaryColor] = useState(
      localStorage.getItem('primaryColor') || '#011E1E',
    );
    const [secondaryColor, setSecondaryColor] = useState(
      localStorage.getItem('secondaryColor') || '#B8EF36',
    );
    const [type, setType] = useState('');

    // COLOR PICKER
    const [primaryColorPick, setPrimaryColorPick] = useState(false);
    const [secondaryColorPick, setSecondaryColorPick] = useState(false);

    // COLOR PICKER

    useEffect(() => {
      if (organization) {
        setName(organization.name);
        setDescription(organization.description);
        setType(organization.type.toString());
      }
    }, []);

    const onChangeImage = async (file?: File) => {
      if (file) {
        const compressed = await resizeImage(
          file,
          ORG_AVATAR_WIDTH,
          ORG_AVATAR_HEIGHT,
        );
        setImage(compressed);
      } else {
        setImage(undefined);
      }
    };
    const primaryHandleColorPickerOn = () => {
      setPrimaryColorPick(true);
    };
    const secondaryHandleColorPickerOn = () => {
      setSecondaryColorPick(true);
    };
    const primaryHandleColorPickerOff = () => {
      setPrimaryColorPick(false);
    };
    const secondaryHandleColorPickerOff = () => {
      setSecondaryColorPick(false);
    };

    const submitEnabled = () => {
      if (organization) {
        return (
          (name !== (organization.name ?? '') ||
            primaryColor !== (organization.primaryColor ?? '#011E1E') ||
            secondaryColor !== (organization.secondaryColor ?? '#B8EF36') ||
            image) &&
          name?.trim() !== '' &&
          primaryColor?.trim() !== '' &&
          secondaryColor?.trim() !== ''
        );
      }
      return (
        name?.trim() !== '' &&
        primaryColor?.trim() !== '' &&
        secondaryColor?.trim() !== '' &&
        image &&
        type !== ''
      );
    };

    const changeColor = () => {
      const colorPalettePrimary = generateColorPalette(primaryColor);
      const colorPaletteSecondary = generateColorPalette(secondaryColor);

      // Iterate over the color palette and set the corresponding CSS variables
      for (let i = 1; i <= 10; i++) {
        document.documentElement.style.setProperty(
          `--workspace-primary-color-${i}`,
          colorPalettePrimary[i - 1],
        );

        document.documentElement.style.setProperty(
          `--workspace-secondary-color-${i}`,
          colorPaletteSecondary[i - 1],
        );
      }
    };

    const onCreate = () => {
      if (submitEnabled()) {
        onSubmit(
          name.trim(),
          description.trim(),
          primaryColor.trim(),
          secondaryColor.trim(),
          parseInt(type, 10),
          image,
        );
        localStorage.setItem('primaryColor', primaryColor);
        localStorage.setItem('secondaryColor', secondaryColor);
        changeColor();
      }
    };

    return (
      <div className="OrgDataForm">
        <TextInput
          value={name}
          onChange={setName}
          placeholder={orgDataFormText.namePlaceholder}
          heading={orgDataFormText.nameTitle}
          className="Input"
          maxCharacters={40}
          isMandatory={true}
        />
        <ImagePickerInput
          image={image}
          onChange={onChangeImage}
          heading={orgDataFormText.avatarTitle}
          placeholder={orgDataFormText.avatarPlaceholder}
          className="Input"
          existingImage={organization?.avatar}
          existingImageName={organization?.avatarName}
          isMandatory={true}
        />
        {/* <TextInput
          value={description}
          onChange={setDescription}
          placeholder={orgDataFormText.desscriptionPlaceholder}
          heading={orgDataFormText.description}
          className="Input"
          isMandatory={true}
        /> */}
        {!skipColors && (
          <>
            <TextInput
              value={primaryColor}
              onChange={setPrimaryColor}
              heading={orgDataFormText.primaryColorTitle}
              placeholder={orgDataFormText.primaryColorPlaceholder}
              className="Input"
              isMandatory={true}
              onFocusFunc={primaryHandleColorPickerOn}
            />
            {primaryColorPick && (
              <div className="colorPickContainer">
                <span
                  className="colorPickClose icon-type-bolder_x"
                  onClick={primaryHandleColorPickerOff}></span>
                <HexColorPicker
                  color={primaryColor}
                  onChange={setPrimaryColor}
                />
              </div>
            )}
            <TextInput
              value={secondaryColor}
              onChange={setSecondaryColor}
              heading={orgDataFormText.secondaryColorTitle}
              placeholder={orgDataFormText.secondaryColorPlaceholder}
              className="Input"
              isMandatory={true}
              onFocusFunc={secondaryHandleColorPickerOn}
            />
            {/* COLOR PICKER */}
            {secondaryColorPick && (
              <div className="colorPickContainer">
                <span
                  className="colorPickClose icon-type-bolder_x"
                  onClick={secondaryHandleColorPickerOff}></span>
                <HexColorPicker
                  color={secondaryColor}
                  onChange={setSecondaryColor}
                />
              </div>
            )}
            {/* COLOR PICKER */}
          </>
        )}
        <Dropdown
          disabled={organization !== undefined}
          options={[
            {
              key: '0',
              value: 'Educational Institution',
            },
            {
              key: '1',
              value: 'CPD / Professional Development',
            },
          ]}
          selectedOption={type}
          placeholder={orgDataFormText.selectType}
          setSelectedOption={setType}
          heading={orgDataFormText.type}
          isMandatory={organization === undefined}
        />
        <div className="Footer">
          <Button
            title={orgDataFormText.cancel}
            onClick={onCancel}
            uiType="text"
          />
          <Button
            title={submitText}
            onClick={onCreate}
            disabled={!submitEnabled()}
            isLoading={isLoading}
            uiType="action"
          />
        </div>
      </div>
    );
  },
);

export default OrgDataForm;
