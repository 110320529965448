import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  MediaFile,
  VimeoMediaFile,
} from '../../../../data/models/LibraryFile/MediaFile';
import {
  getSecondsFromTimeText,
  getTimeTextFromSeconds,
} from '../../../../utils/getTimeTextFromSeconds';
import SimplePlayerControlled from '../../../../uiToolkit/SimplePlayerControlled';
import './styles.css';
import TimeInput from '../../../../uiToolkit/Inputs/TimeInput/indes';
import Button from '../../../../uiToolkit/Buttons/Button';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import {addVideoOverlayWizardText} from '../addVideoOverlayWizardText';
import PathTap from '../components/PathTap';
import {
  Point,
  overlayTapActionOptions,
} from '../../../../data/models/LessonContent/ContentFreeform';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';

interface Props {
  selectedFile: MediaFile | null;
  selectedVimeoFile: VimeoMediaFile | null;
  onCancel: () => void;
  onSave: (
    start: number,
    end: number,
    overlayStart: number,
    overlayEnd: number,
    overlayAction: number,
    urlPayload: string,
    points: Point[],
    encodedAtWidth: number,
    overlayVisible: boolean,
  ) => void;
}

const STARTING_WIDTH = 120;
const STARTING_HEIGHT = 140;

const CropVideoStepUI = React.memo(
  ({selectedFile, onCancel, onSave, selectedVimeoFile}: Props) => {
    const [width, setWidth] = useState(0);
    const [timeStart, setTimeStart] = useState('00:00');
    const [timeEnd, setTimeEnd] = useState('00:01');
    const [overlayTimeStart, setOverlayTimeStart] = useState('00:00');
    const [overlayTimeEnd, setOverlayTimeEnd] = useState('00:01');
    const [duration, setDuration] = useState(0);
    const [showOverlay, setShowOverlay] = useState(true);
    const [overlayAction, setOverlayAction] = useState('0');
    const [urlPayload, setUrlPayload] = useState('');
    const [overlayVisible, setOverlayVisible] = useState(false);

    const [points, setPoints] = React.useState<{[key: number]: Point}>({});

    const containerRef = useRef<any>(null);

    const saveNode = () => {
      if (
        startTimeIsValid() &&
        endTimeIsValid() &&
        overlayStartTimeIsValid() &&
        overlayEndTimeIsValid()
      ) {
        onSave(
          getSecondsFromTimeText(timeStart),
          getSecondsFromTimeText(timeEnd),
          getSecondsFromTimeText(overlayTimeStart),
          getSecondsFromTimeText(overlayTimeEnd),
          parseInt(overlayAction, 10),
          urlPayload,
          Object.keys(points)
            .sort((x, y) => (x < y ? 1 : -1))
            .map(key => points[parseInt(key, 10)]),
          width,
          overlayVisible,
        );
      }
    };

    const generateStartingPoints = useCallback(() => {
      const generatedMap: any = {};
      const centerPointX = width / 2;
      const centerPointY = width / 1.77 / 2;
      generatedMap[0] = {
        x: centerPointX + STARTING_WIDTH / 2,
        y: centerPointY + STARTING_HEIGHT / 2,
      };
      generatedMap[1] = {
        x: centerPointX - STARTING_WIDTH / 2,
        y: centerPointY + STARTING_HEIGHT / 2,
      };
      generatedMap[2] = {
        x: centerPointX - STARTING_WIDTH / 2,
        y: centerPointY - STARTING_HEIGHT / 2,
      };
      generatedMap[3] = {
        x: centerPointX + STARTING_WIDTH / 2,
        y: centerPointY - STARTING_HEIGHT / 2,
      };
      setPoints(generatedMap);
    }, [width]);

    useEffect(() => {
      if (width && Object.keys(points).length === 0) {
        generateStartingPoints();
      }
    }, [width, generateStartingPoints, points]);

    const onAddPoint = () => {
      const pointKeys = Object.keys(points).sort((x, y) => (x < y ? 1 : -1));
      if (pointKeys.length >= 7) {
        return;
      }
      const lastPointKey = parseInt(pointKeys[0], 10);
      const midX = (points[0].x + points[lastPointKey].x) / 2;
      const midY = (points[0].y + points[lastPointKey].y) / 2;
      const newPoints = {
        ...points,
        [lastPointKey + 1]: {
          x: midX,
          y: midY,
        },
      };
      setPoints(newPoints);
    };

    const onRemovePoint = () => {
      const pointKeys = Object.keys(points).sort((x, y) => (x < y ? 1 : -1));
      if (pointKeys.length <= 3) {
        return;
      }
      const lastPointKey = parseInt(pointKeys[0], 10);
      const newPoints = {...points};
      delete newPoints[lastPointKey];
      setPoints(newPoints);
    };

    React.useEffect(() => {
      function handleResize() {
        if (containerRef && containerRef.current) {
          setWidth(containerRef.current.offsetWidth);
        }
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    React.useLayoutEffect(() => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    }, []);

    const startTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeStart);
        const endSeconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration) && seconds < endSeconds;
      }
      return true;
    };

    const endTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration);
      }
      return true;
    };

    const overlayStartTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(overlayTimeStart);
        const endSeconds = getSecondsFromTimeText(overlayTimeEnd);
        return seconds <= Math.ceil(duration) && seconds < endSeconds;
      }
      return true;
    };

    const overlayEndTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(overlayTimeEnd);
        return seconds <= Math.ceil(duration);
      }
      return true;
    };

    const onDuration = (dur: number) => {
      setDuration(dur);
      setTimeEnd(getTimeTextFromSeconds(Math.ceil(dur)));
      setOverlayTimeEnd(getTimeTextFromSeconds(Math.ceil(dur)));
    };

    const onProgress = (seconds: number) => {
      if (
        getSecondsFromTimeText(overlayTimeStart) <= seconds &&
        getSecondsFromTimeText(overlayTimeEnd) >= seconds
      ) {
        setShowOverlay(true);
      } else {
        setShowOverlay(false);
      }
    };

    return (
      <ModalContainer onClose={onCancel}>
        <div className="d-flex flex-row">
          <div className="AddVideoOverlaySourceStepContent CropPlayerContent">
            <p className="CropHeader">{addVideoOverlayWizardText.cropTitle}</p>
            <div className="CropPlayerContainer" ref={containerRef}>
              <SimplePlayerControlled
                width={width}
                onDuration={onDuration}
                onVideoProgress={onProgress}
                source={
                  selectedFile ? selectedFile?.fileUrl : selectedVimeoFile?.url
                }
                startTime={
                  startTimeIsValid()
                    ? getSecondsFromTimeText(timeStart)
                    : undefined
                }
                endTime={
                  endTimeIsValid() ? getSecondsFromTimeText(timeEnd) : undefined
                }
              />
              {showOverlay && (
                <div
                  style={{width, height: width / 1.77}}
                  className="OverlayCanvas">
                  <svg width={width} height={width / 1.77}>
                    <PathTap points={points} setPoints={setPoints} />
                  </svg>
                </div>
              )}
            </div>
            <div className="TimePickersContainer">
              <TimeInput
                value={timeStart}
                onChange={setTimeStart}
                label={addVideoOverlayWizardText.startTime}
                isValid={startTimeIsValid()}
                labelTop
              />
              <TimeInput
                value={timeEnd}
                onChange={setTimeEnd}
                label={addVideoOverlayWizardText.endTime}
                isValid={endTimeIsValid()}
                labelTop
              />
            </div>
            <div className="Footer">
              <Button
                title={addVideoOverlayWizardText.cancel}
                onClick={onCancel}
                uiType="text"
              />
              <Button
                title={addVideoOverlayWizardText.save}
                onClick={saveNode}
                disabled={
                  !startTimeIsValid() ||
                  !endTimeIsValid() ||
                  !overlayEndTimeIsValid() ||
                  !overlayStartTimeIsValid() ||
                  !urlPayload
                }
                uiType="action"
              />
            </div>
          </div>
          <div className="OverlaySettings">
            <p className="Title">{addVideoOverlayWizardText.overlayTitle}</p>
            <div className="d-flex flex-row">
              <Button uiType="box" title="Add Point" onClick={onAddPoint} />
              <Button
                uiType="box"
                title="Remove Point"
                onClick={onRemovePoint}
                className="RemovePointButton"
              />
            </div>
            <div className="OverlayTimeInputsContainer">
              <TimeInput
                value={overlayTimeStart}
                onChange={setOverlayTimeStart}
                label={addVideoOverlayWizardText.startTime}
                isValid={overlayStartTimeIsValid()}
                labelTop
              />
              <TimeInput
                value={overlayTimeEnd}
                onChange={setOverlayTimeEnd}
                label={addVideoOverlayWizardText.endTime}
                isValid={overlayEndTimeIsValid()}
                labelTop
              />
            </div>

            <Dropdown
              type="box"
              options={overlayTapActionOptions}
              selectedOption={overlayAction}
              setSelectedOption={setOverlayAction}
              heading="Overlay Action"
            />
            <TextInput
              value={urlPayload}
              onChange={setUrlPayload}
              heading="URL Destination"
              className="DestinationInput"
              uiType="box"
              placeholder="URL"
            />
            <Checkbox
              text={'Show Overlay For Learners?'}
              checked={overlayVisible}
              onCheck={() => setOverlayVisible(!overlayVisible)}
              slim
              color="#555555"
              className="VisibleCheck"
            />
          </div>
        </div>
      </ModalContainer>
    );
  },
);

export default CropVideoStepUI;
