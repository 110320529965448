import React, {useContext} from 'react';
import {EditorContext} from '../container/LessonEditorContainer';
import {NodeData} from '../models';
import {
  mChoiceAnswerCorrect,
  mChoiceAnswerIncorrect,
  multipleChoiceListIc,
} from '../../../assets/images';
import {LessonContentMChoice} from '../../../data/models/LessonContent/LessonContentBase';
import NodeHandles from './NodeHandles';

interface Props {
  data: NodeData;
}

const MChoiceNode = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const onClick = (e: any) => {
    context.setSelectedNode(data.payload, data.type);
    e.stopPropagation();
  };

  return (
    <NodeHandles>
      <>
        <div
          className={`EditorCard MChoiceCard ${
            context.selectedNode?.internalId === data.payload.internalId
              ? 'SelectedEditorCard'
              : ''
          }`}
          onClick={onClick}>
          <div className="MChoiceQuestion">
            <img src={multipleChoiceListIc} />
            <p>{(data.payload as LessonContentMChoice).question}</p>
          </div>
        </div>
        <div className="MChoiceAnswersContainer">
          <div className="MChoiceAnswersContent">
            {(data.payload as LessonContentMChoice).answers.map(
              (item, index) => (
                <div
                  key={index.toString()}
                  className="MChoiceAnswer"
                  style={
                    index ===
                    (data.payload as LessonContentMChoice).answers.length - 1
                      ? {marginBottom: '0px'}
                      : {}
                  }>
                  <span>{item.value}</span>
                  {item.isCorrect ? (
                    <img src={mChoiceAnswerCorrect} />
                  ) : (
                    <img src={mChoiceAnswerIncorrect} />
                  )}
                </div>
              ),
            )}
          </div>
        </div>
      </>
    </NodeHandles>
  );
});

export default MChoiceNode;
