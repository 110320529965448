import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Button from '../Buttons/Button';
import {appText} from '../../assets/text/appText';
import './styles.css';
import Breadcrumbs from '../Breadcrumbs';

interface Props {
  onBack: () => void;
  onNext?: () => void;
  nextTitle?: string;
  breadcrumbs: {title: string; link?: string}[];

  onPublish?: () => void;
}

const EditorHeader = React.memo(
  ({
    onBack,
    onNext,
    nextTitle,
    breadcrumbs,

    onPublish,
  }: Props) => {
    return (
      <Row>
        <Col xs={12}>
          <div className="EditorHeader">
            <div className="Section">
              <Button
                icon="icon-typeGo-Back"
                onClick={onBack}
                title={appText.back}
                uiType="secondary"
              />
              <Breadcrumbs steps={breadcrumbs} />
            </div>
            <div className="Section">
              {onPublish ? (
                <Button
                  title={appText.publishCourse}
                  onClick={onPublish}
                  uiType="secondary"
                />
              ) : null}
              {onNext && nextTitle ? (
                <Button title={nextTitle} onClick={onNext} uiType="action" />
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    );
  },
);

export default EditorHeader;
