import LessonContentApi from '../api/LessonContentApi';
import {mapLessonContent} from '../mappers/lessonContentMapper';
import {AddLessonContentModel} from '../models/LessonContent/AddLessonContentModel';
import {LessonContentBase} from '../models/LessonContent/LessonContentBase';
import {LessonContentList} from '../models/LessonContent/LessonContentList';

class LessonContentRepository {
  private lessonContentApi = new LessonContentApi();

  public addLessonContent(
    organizationId: string,
    lessonId: string,
    model: AddLessonContentModel,
  ) {
    return this.lessonContentApi.addContentNode(
      organizationId,
      lessonId,
      model,
    );
  }

  public async getLessonContent(organizationId: string, lessonId: string) {
    const result = await this.lessonContentApi.getLibraryContent(
      organizationId,
      lessonId,
    );
    if (result.success && result.data) {
      return {
        ...result,
        data: this.mapLessonContentList(result.data),
      };
    }
    return result;
  }

  public async getLessonContentPresentation(
    courseId: string,
    lessonId: string,
  ) {
    const result = await this.lessonContentApi.getLessonContentPresentation(
      courseId,
      lessonId,
    );
    if (result.success && result.data) {
      return {
        ...result,
        data: this.mapLessonContentList(result.data),
      };
    }
    return result;
  }

  public async getLessonContentPreview(
    organizationId: string,
    lessonId: string,
  ) {
    const result = await this.lessonContentApi.getLessonContentPreview(
      organizationId,
      lessonId,
    );
    if (result.success && result.data) {
      return {
        ...result,
        data: this.mapLessonContentList(result.data),
      };
    }
    return result;
  }

  public editLessonContent(
    organizationId: string,
    lessonId: string,
    model: LessonContentBase,
  ) {
    return this.lessonContentApi.editLessonContent(
      organizationId,
      lessonId,
      model,
    );
  }

  public deleteLessonContent(
    organizationId: string,
    lessonId: string,
    internalId: string,
  ) {
    return this.lessonContentApi.deleteLessonContent(
      organizationId,
      lessonId,
      internalId,
    );
  }

  private mapLessonContentList = (content: LessonContentList) => ({
    ...content,
    contentList: content.contentList
      ? {
          ...content.contentList,
          items: mapLessonContent(content.contentList.items),
        }
      : {
          rootId: '',
          items: {},
        },
  });
}

export default LessonContentRepository;
