import React from 'react';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';

interface Props {
  points: {[key: number]: Point};
  setPoints: (points: {[key: number]: Point}) => void;
}

const PathTapHandles = React.memo(({points, setPoints}: Props) => {
  const [handlePosition, setHandlePosition] = React.useState({
    active: false,
    handleId: -1,
    offset: {x: 0, y: 0},
  });

  const handleHandlesPointerDown = (handleId: number) => (e: any) => {
    const el = e.target;
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    el.setPointerCapture(e.pointerId);
    setHandlePosition({
      ...handlePosition,
      active: true,
      handleId: handleId,
      offset: {
        x,
        y,
      },
    });
  };

  const handleHandlesPointerMove = (e: any) => {
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    if (handlePosition.active && handlePosition.handleId !== -1) {
      const newPoints = {
        ...points,
        [handlePosition.handleId]: {
          x: points[handlePosition.handleId].x - (handlePosition.offset.x - x),
          y: points[handlePosition.handleId].y - (handlePosition.offset.y - y),
        },
      };
      setPoints(newPoints);
    }
  };

  const handleHandlesPointerUp = () => {
    setHandlePosition({
      ...handlePosition,
      active: false,
      handleId: -1,
    });
  };

  return (
    <g>
      {Object.keys(points).map((handle: string) => (
        <circle
          cx={points[parseInt(handle, 10)].x}
          cy={points[parseInt(handle, 10)].y}
          r={5}
          fill="#B8EF36"
          onPointerDown={handleHandlesPointerDown(parseInt(handle, 10))}
          onPointerMove={handleHandlesPointerMove}
          onPointerUp={handleHandlesPointerUp}
          key={handle}
        />
      ))}
    </g>
  );
});

export default PathTapHandles;
