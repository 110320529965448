import React, {useEffect, useState} from 'react';
import LessonsUI from '../view/LessonsUI';
import {toast} from 'react-toastify';
import {Folder} from '../../../data/models/Course/Folder';
import {useNavigate, useParams} from 'react-router-dom';
import LessonsStore from '../store/LessonsStore';
import {inject, observer} from 'mobx-react';
import {Course} from '../../../data/models/Course/Course';
import {Lesson} from '../../../data/models/Course/Lesson';
import {lessonsText} from '../lessonsText';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {ALERT_SUPPLEMENTAL} from '../../../data/staticValues/supplementalTypes';
import {GRADABLE_COURSE_TYPE} from '../../../data/staticValues/courseStructure';
import {ActiveTabs} from '../../../data/models/UI/ActiveTabs';
import {SidebarOptions} from '../../../data/models/UI/SidebarOption';

interface Props {
  lessonsStore?: LessonsStore;
}

const LessonsContainer = inject('lessonsStore')(
  observer(({lessonsStore}: Props) => {
    const [folder, setFolder] = useState<Folder>();
    const [course, setCourse] = useState<Course>();
    const [lessons, setLessons] = useState<Lesson[]>([]);
    const {organizationId, folderId, courseId} = useParams();
    const navigate = useNavigate();
    const [sidebarOptions, setSidebarOptions] = useState<SidebarOptions>([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
      if (organizationId && folderId && courseId) {
        fetchFolder();
        lessonsStore?.getCourse(organizationId, courseId).then(setCourse);
        fetchLessons();
      }
    }, []);

    useEffect(() => {
      getSidebarOptions(search);
    }, [search]);

    const fetchFolder = () => {
      if (organizationId && folderId) {
        lessonsStore?.getLessonFolder(organizationId, folderId).then(setFolder);
      }
    };

    const fetchLessons = () => {
      if (organizationId && folderId) {
        lessonsStore!.getLessons(organizationId, folderId).then(res => {
          setLessons(res.sort((x, y) => (x.order > y.order ? 1 : -1)));
        });
        getSidebarOptions(search);
      }
    };

    const onBack = () => {
      navigate(`/editor/${organizationId}/courses/${courseId}/folders`, {
        replace: true,
      });
    };

    const onNext = () => {
      const firstLesson = lessons[0];
      if (firstLesson) {
        navigate(
          `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons/${firstLesson.id}/edit`,
        );
      }
    };

    const onOpen = (lesson: Lesson) => {
      navigate(
        `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons/${lesson.id}/edit`,
      );
    };

    const onPublish = () => {
      navigate(`/preview/${organizationId}/course/${courseId}`);
    };

    const onReorder = async (orderedLessons: Lesson[]) => {
      orderedLessons = orderedLessons.map((folder, index) => ({
        ...folder,
        order: index,
      }));
      setLessons(orderedLessons);
      if (organizationId && folderId) {
        const success = await lessonsStore?.reorderLessons(
          organizationId,
          folderId,
          orderedLessons,
        );
        if (!success) {
          fetchLessons();
        } else {
          await getSidebarOptions(search);
          toast.success(lessonsText.changesSaved, toastConfig);
        }
      }
    };

    const onRetriesChange = async (lesson: Lesson, retries: number) => {
      if (!lessonsStore?.isLoading) {
        lesson.amountOfRetries = retries;
        await onEdit(lesson);
      }
    };

    const onCreateLesson = () => {
      if (organizationId && folderId && course) {
        lessonsStore
          ?.createLesson(organizationId, {
            folderId,
            title: lessonsText.newLessonTitle,
            description: lessonsText.newLessonDescription,
            organizationId: organizationId,
            order: lessons.length,
            isGradable: course.type.toString() === GRADABLE_COURSE_TYPE,
            libraryMediaFiles: [],
          })
          .then(fetchLessons);
      }
    };

    const onDelete = async (lessonId: string) => {
      if (organizationId) {
        const success = await lessonsStore!.deleteLesson(
          organizationId,
          lessonId,
        );
        if (success) {
          const newLessons = [...lessons];
          const deletedLessonIndex = newLessons.findIndex(
            x => x.id === lessonId,
          );
          if (deletedLessonIndex > 0) {
            newLessons.splice(deletedLessonIndex, 1);
            onReorder(newLessons).then(fetchLessons);
          } else {
            fetchLessons();
          }
        } else {
          toast.error(lessonsText.operationFailed, toastConfig);
          fetchLessons();
        }
      }
    };

    const onEdit = (lesson: Lesson) => {
      if (organizationId) {
        return lessonsStore!
          .editLesson(organizationId, lesson)
          .then(fetchLessons);
      }
    };

    const onAddSupplementalLesson = (lesson: Lesson) => {
      if (organizationId) {
        lessonsStore!
          .addSupplementalLesson(organizationId, lesson)
          .then(fetchLessons);
      }
    };

    const onAddSupplementalAlert = (lesson: Lesson) => {
      if (organizationId) {
        lesson.supplementalType = ALERT_SUPPLEMENTAL;
        lessonsStore!.editLesson(organizationId, lesson).then(fetchLessons);
      }
    };

    const getBreadcrumbs = () => [
      {
        title: 'Courses',
        link: `/manage/${organizationId}/courses`,
      },
      {
        title: course!.title,
        link: `/editor/${organizationId}/courses/${courseId}/folders`,
      },
      {
        title: folder!.name,
        link: `/editor/${organizationId}/courses/${courseId}/folders/${folderId}/lessons`,
      },
    ];

    const getSidebarOptions = (search: string) => {
      if (organizationId) {
        lessonsStore!.getSidebarOptions(organizationId, search).then(res => {
          setSidebarOptions(res);
        });
      }
    };

    return folder && course ? (
      <LessonsUI
        onNext={onNext}
        onBack={onBack}
        folder={folder}
        course={course}
        lessons={lessons}
        onCreate={onCreateLesson}
        onDelete={onDelete}
        onEdit={onEdit}
        onReorder={onReorder}
        isLoading={lessonsStore!.isLoading}
        onRetriesChange={onRetriesChange}
        onAddSupplementalLesson={onAddSupplementalLesson}
        onAddSupplementalAlert={onAddSupplementalAlert}
        onOpen={onOpen}
        onPublish={onPublish}
        breadcrumbs={getBreadcrumbs()}
        sidebarOptions={sidebarOptions}
        activeTabs={{courseId, folderId} as ActiveTabs}
        onSearch={setSearch}
      />
    ) : (
      <div />
    );
  }),
);

export default LessonsContainer;
