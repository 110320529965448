import React from 'react';
import {appText} from '../../../assets/text/appText';
import {eye, eyeOff} from '../../../assets/images';
import './styles.css';

interface Props {
  value: string;
  onChange: (value: string, isSecure: boolean) => void;
  placeholder: string;
  showValidation?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
}

const PasswordInput = React.memo(
  ({
    value,
    onChange,
    placeholder,
    showValidation,
    onFocus,
    onBlur,
    className,
  }: Props) => {
    const [showValue, setShowValue] = React.useState(false);

    const onToggleShow = () => {
      setShowValue(!showValue);
    };

    const renderValidationSection = (title: string, isValid: boolean) => (
      <div className="PasswordInputValidationSectionContainer">
        <div className="PasswordInputValidationRadioOuter">
          {isValid && <div className="PasswordInputValidationRadioInner" />}
        </div>
        <p className="PasswordInputValidationText">{title}</p>
      </div>
    );

    const onPasswordChange = (text: string) => {
      onChange(
        text.trim(),
        isValidLength(text.trim()) &&
          isValidLetterNumber(text.trim()) &&
          isValidUpperLower(text.trim()) &&
          isValidSpecialCharacter(text.trim()),
      );
    };

    const isValidLength = (v: string) => v.length >= 8;

    const isValidLetterNumber = (v: string) =>
      /\d[a-zA-Z]|[a-zA-Z]\d|\d[!,@,#,$,?,&]|[!,@,#,$,?,&]\d/i.test(v);

    const isValidUpperLower = (v: string) => /[A-Z]/.test(v) && /[a-z]/.test(v);

    const isValidSpecialCharacter = (v: string) => /[!,@,#,$,?,&]/i.test(v);

    return (
      <>
        <div className={`PasswordInputContainer ${className ? className : ''}`}>
          <input
            type={showValue ? 'none' : 'password'}
            value={value}
            onChange={e => onPasswordChange(e.target.value)}
            placeholder={placeholder}
            className={'PasswordInputInput'}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <img
            src={showValue ? eyeOff : eye}
            alt="eye"
            onClick={onToggleShow}
            className="PasswordInputEye"
          />
        </div>

        {showValidation && (
          <div>
            <p className="PasswordInputDetailsTitle">
              {appText.passwordMustContain}
            </p>
            <div className="PasswordInputValidationContainer">
              {renderValidationSection(
                appText.passwordValidationLength,
                isValidLength(value),
              )}
              {renderValidationSection(
                appText.passwordValidationNumber,
                isValidLetterNumber(value),
              )}
              {renderValidationSection(
                appText.passwordValidationCase,
                isValidUpperLower(value),
              )}
              {renderValidationSection(
                appText.passwordValidationCharacter,
                isValidSpecialCharacter(value),
              )}
            </div>
          </div>
        )}
      </>
    );
  },
);

export default PasswordInput;
