export const APP_PERMISSIONS: any = {
  ORG_LIST_LEARNERS: 'Permission.Workspace.ListLearners',
  ORG_CREATE_LEARNERS: 'Permission.Workspace.InviteLearners',
  ORG_CREATE_COURSES: 'Permission.Workspace.CreateCourses',
  ORG_LIST_COURSES: 'Permission.Workspace.ListCourses',
  ORG_LIST_FILES: 'Permission.Workspace.ListFiles',
  ORG_UPLOAD_FILES: 'Permission.Workspace.UploadFiles',
  ORG_MANAGE_SETTINGS: 'Permission.Workspace.ManageSettings',
  ORG_ASSIGN_INSTRUCOR: 'Permission.Workspace.AssignInstructor',
};

export const getPermissionName = (permission: string) => {
  switch (permission) {
    case APP_PERMISSIONS.ORG_LIST_LEARNERS:
      return 'View Workspace Learners';
    case APP_PERMISSIONS.ORG_CREATE_LEARNERS:
      return 'Invite and Edit Learners in Workspace';
    case APP_PERMISSIONS.ORG_CREATE_COURSES:
      return 'Create and Edit Courses in Workspace';
    case APP_PERMISSIONS.ORG_LIST_COURSES:
      return 'View Courses in Workspace';
    case APP_PERMISSIONS.ORG_LIST_FILES:
      return 'View Workspace Library Files';
    case APP_PERMISSIONS.ORG_UPLOAD_FILES:
      return 'Upload files to Workspace Library';
    case APP_PERMISSIONS.ORG_MANAGE_SETTINGS:
      return 'Manage Workspace Settings';
    default:
      return '';
  }
};

export const SUBSCRIPTION_PERMISSIONS: any = {
  GRADABLE_LESSONS: 'gradable_lessons',
  COURSE_MANAGMENT: 'course_management',
};

export const getSubscriptionFeatureName = (feature: string) => {
  switch (feature) {
    case SUBSCRIPTION_PERMISSIONS.GRADABLE_LESSONS:
      return 'Gradable Lessons';
    case SUBSCRIPTION_PERMISSIONS.COURSE_MANAGMENT:
      return 'Course Management';
    default:
      return '';
  }
};
