import React, {useEffect, useState} from 'react';
import {LessonContentVideo} from '../../../../../data/models/LessonContent/LessonContentBase';
import {MediaFile} from '../../../../../data/models/LibraryFile/MediaFile';
import {
  getSecondsFromTimeText,
  getTimeTextFromSeconds,
} from '../../../../../utils/getTimeTextFromSeconds';
import {lessonEditorText} from '../../../lessonEditorText';
import TextInput from '../../../../../uiToolkit/Inputs/TextInput';
import BoxVideoInput from '../../../../../uiToolkit/Inputs/BoxVideoInput';
import TimeInput from '../../../../../uiToolkit/Inputs/TimeInput/indes';
import Button from '../../../../../uiToolkit/Buttons/Button';
import './styles.css';
import EditorOptionsFooter from '../../../../../uiToolkit/Editor/EditorOptionsFooter';

interface Props {
  videoContent: LessonContentVideo;
  newFile?: MediaFile;
  onCancel: () => void;
  saveChanges: (timeStart: number, timeEnd: number, title: string) => void;
  onDelete: () => void;
  onPickNewFile: () => void;
  isLoading: boolean;
  isPublished?: boolean;
}

const VideoSourceOptionsUI = React.memo(
  ({
    videoContent,
    newFile,
    saveChanges,
    isLoading,
    onDelete,
    onPickNewFile,
    isPublished,
  }: Props) => {
    const [duration, setDuration] = useState(0);
    const [timeStart, setTimeStart] = useState(
      getTimeTextFromSeconds(videoContent.startInSeconds),
    );
    const [timeEnd, setTimeEnd] = useState(
      getTimeTextFromSeconds(videoContent.endInSeconds),
    );
    const [title, setTitle] = useState(videoContent.title);

    useEffect(() => {
      setTimeStart(getTimeTextFromSeconds(videoContent.startInSeconds));
      setTimeEnd(getTimeTextFromSeconds(videoContent.endInSeconds));
      setTitle(videoContent.title);
    }, [videoContent]);

    const onSave = () => {
      saveChanges(
        getSecondsFromTimeText(timeStart),
        getSecondsFromTimeText(timeEnd),
        title,
      );
    };

    const startTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeStart);
        const endSeconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration) && seconds < endSeconds;
      }
      return true;
    };

    const endTimeIsValid = () => {
      if (duration > 0) {
        const seconds = getSecondsFromTimeText(timeEnd);
        return seconds <= Math.ceil(duration);
      }
      return true;
    };

    const changesWasMade = () => {
      return (
        videoContent.startInSeconds !== getSecondsFromTimeText(timeStart) ||
        videoContent.endInSeconds !== getSecondsFromTimeText(timeEnd) ||
        videoContent.title !== title.trim() ||
        newFile
      );
    };

    const isValid = () => {
      return startTimeIsValid() && endTimeIsValid() && title.trim() !== '';
    };

    return (
      <div className="VideoSourceOptions">
        <TextInput
          heading={lessonEditorText.title}
          value={title}
          onChange={setTitle}
          placeholder={lessonEditorText.title}
          fieldError={title.trim() === ''}
          isMandatory
          uiType="box"
          className="Section"
        />
        {videoContent?.contentUrl ? (
          <BoxVideoInput
            heading={lessonEditorText.source}
            source={newFile ? newFile.fileUrl : videoContent.contentUrl}
            startTime={
              startTimeIsValid() ? getSecondsFromTimeText(timeStart) : undefined
            }
            endTime={
              endTimeIsValid() ? getSecondsFromTimeText(timeEnd) : undefined
            }
            setDuration={setDuration}
          />
        ) : null}
        <div className="TimePickersContainer">
          <TimeInput
            isValid={startTimeIsValid()}
            value={timeStart}
            onChange={setTimeStart}
            label={lessonEditorText.startTime}
          />
          <TimeInput
            isValid={endTimeIsValid()}
            value={timeEnd}
            onChange={setTimeEnd}
            label={lessonEditorText.endTime}
          />
        </div>
        <Button
          title={lessonEditorText.editSource}
          onClick={onPickNewFile}
          uiType="box"
          className="SelectSourceButton"
        />
        <EditorOptionsFooter
          saveEnabled={changesWasMade() && isValid()}
          onSave={onSave}
          onDelete={onDelete}
          deleteTitle={lessonEditorText.delete}
          isLoading={isLoading}
          deleteDisabled={isPublished}
        />
      </div>
    );
  },
);

export default VideoSourceOptionsUI;
