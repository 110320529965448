import React from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';

type YesNoOptionUIType = 'standart' | 'box' | 'icon';

interface Props {
  title?: string;
  onSelect: (value: boolean) => void;
  value: boolean;
  className?: string;
  uiType?: YesNoOptionUIType;
  disabled?: boolean;
}

const YesNoOption = React.memo(
  ({title, onSelect, value, className, uiType, disabled}: Props) => {
    const getRootClass = () => {
      switch (uiType) {
        case 'box':
          return 'YesNoOptionsBox';
        case 'icon':
          return 'YesNoOptionsIcon';
        default:
          return 'YesNoOptionsStandart';
      }
    };

    return (
      <div className={className}>
        <div
          className={`YesNoOptions ${getRootClass()} ${
            disabled ? 'YesNoDisabled' : ''
          }`}>
          {title && <p>{title}</p>}
          <div className="Options">
            <button
              onClick={() => onSelect(true)}
              disabled={disabled}
              className={`ButtonYes ${!value ? 'GreyedOut' : ''}`}>
              {uiType === 'icon' ? (
                <span className="icon-typeCheck"></span>
              ) : (
                appText.yes
              )}
            </button>
            <button
              onClick={() => onSelect(false)}
              disabled={disabled}
              className={`ButtonNo ${value ? 'GreyedOut' : ''}`}>
              {uiType === 'icon' ? (
                <span className="icon-typeClose"></span>
              ) : (
                appText.no
              )}
            </button>
          </div>
        </div>
      </div>
    );
  },
);

export default YesNoOption;
