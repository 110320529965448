import React, {useCallback} from 'react';
import './styles.css';

interface Props {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: (page: number, totalRows: number) => void;
  currentPage: number;
}

const TablePagination = React.memo(
  ({rowsPerPage, rowCount, onChangePage, currentPage}: Props) => {
    const getAmountOfPages = useCallback(() => {
      return rowCount / rowsPerPage;
    }, [rowsPerPage, rowCount]);

    const getPagesArray = useCallback(() => {
      const pages = [];
      const amountOfPages = getAmountOfPages();
      for (let i = 0; i < amountOfPages; i++) {
        pages.push(i + 1);
      }
      return pages;
    }, [getAmountOfPages]);

    const canGoPrev = useCallback(() => {
      return currentPage > 1;
    }, [currentPage]);

    const canGoNext = useCallback(() => {
      return currentPage < getAmountOfPages();
    }, [currentPage, getAmountOfPages]);

    return (
      <div className="TablePaginationContainer">
        <button
          className={`SideButton ${canGoPrev() ? 'Active' : ''}`}
          disabled={!canGoPrev()}
          onClick={() => onChangePage(currentPage - 1, rowCount)}>
          Previous page
        </button>
        {getPagesArray().map(item => (
          <button
            className={`NumberButton ${currentPage === item ? 'Active' : ''}`}
            onClick={() => onChangePage(item, rowCount)}
            key={item}>
            {item}
          </button>
        ))}
        <button
          className={`SideButton ${canGoNext() ? 'Active' : ''}`}
          disabled={!canGoNext()}
          onClick={() => onChangePage(currentPage + 1, rowCount)}>
          Next page
        </button>
      </div>
    );
  },
);

export default TablePagination;
