import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../../data/repositories/UserRepository';

class SettingsUserProfileStore {
  private userRepository = new UserRepository();

  public isLoading = false;
  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserProfile() {
    const result = await this.userRepository.getUserInfo();
    if (result.success && result.data) {
      return result.data;
    }
    return null;
  }

  public async editUserProfile(fullName: string) {
    this.isLoading = true;
    const result = await this.userRepository.editUserName(fullName);
    this.isLoading = false;
    if (!result.success && result.errors) {
      return false;
    }
    return true;
  }

  public async editUserAvatar(image: File) {
    this.isLoading = true;
    const result = await this.userRepository.uploadUserAvatar(image);
    this.isLoading = false;
    if (!result.success && result.errors) {
      return false;
    }
    return true;
  }

  public async editUserPassword(
    password: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    this.isLoading = true;
    const result = await this.userRepository.changeUserPassword(
      password,
      newPassword,
      confirmNewPassword,
    );
    this.isLoading = false;
    if (!result.success && result.errors) {
      return false;
    }
    return true;
  }
}
export default SettingsUserProfileStore;
