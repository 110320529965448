import React, {useRef, useState} from 'react';
import {
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
  LessonContentOverlayVideo,
  LessonContentVideo,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import VideoPlayer from '../components/VideoPlayer';
import {LessonContentItem} from '../../../data/models/LessonContent/LessonContentList';
import StartingOverlay from '../overlays/StartingOverlay/StartingOverlay';
import EndingOverlay from '../overlays/EndingOverlay/EndingOverlay';
import MCHoiceOverlay from '../overlays/MChoiceOverlay/MChoiceOverlay';
import ChoiceOverlay from '../overlays/ChoiceOverlay/ChoiceOverlay';
import ChallengeBranchOverlay from '../overlays/ChallengeBranchOverlay/ChallengeBranchOverlay';
import ChallengeBranchAIOverlay from '../overlays/ChallengeBranchAIOverlay/ChallengeBranchAIOverlay';
import {GradeResult} from '../models';
import {
  ChallengeBranchAIMapped,
  LessonContentChoiceAnswerMapped,
  LessonContentChoiceMapped,
} from '../../../data/models/LessonContent/LessonConentMapped';
import ResultsModal from '../components/ResultsModal';
import FreeformOverlay from '../overlays/FreeformOverlay/FreeformOverlay';

interface Props {
  currentVideoNode?: LessonContentVideo;
  started: boolean;
  ended: boolean;
  preview: boolean;
  onFinishedVideo: () => void;
  currentNode?: LessonContentItem;
  isGradable?: boolean;
  onStart: () => void;
  gradeResult?: GradeResult;
  onCourseHome: () => void;
  onContinue: () => void;
  nextLessonIsTheSame?: boolean;
  isLoading: boolean;
  passingScore: number;
  onSelectMChoice: (choices: LessonContentMChoiceAnswer[]) => void;
  onSelectChoice: (choice: LessonContentChoiceAnswerMapped) => void;
  onSelectChallenge: (choice: LessonContentChoiceAnswerMapped) => void;
  onSelectChallengeAI: (
    answer: ChallengeBranchAIMapped,
    selectedAnswer: string,
  ) => void;
  fitHeight?: boolean;
  skipFinishButtons?: boolean;
  nextLessonId?: string;
}

const TapyblPlayerUI = React.memo(
  ({
    currentVideoNode,
    started,
    ended,
    preview,
    onFinishedVideo,
    currentNode,
    isGradable,
    onStart,
    gradeResult,
    onContinue,
    onCourseHome,
    nextLessonIsTheSame,
    isLoading,
    passingScore,
    onSelectChallenge,
    onSelectChallengeAI,
    onSelectChoice,
    onSelectMChoice,
    fitHeight,
    skipFinishButtons,
    nextLessonId,
  }: Props) => {
    const [showResults, setShowResults] = useState(false);
    const [width, setWidth] = useState(0);
    const [progress, setProgres] = useState(0);

    const playerRef = useRef<any>(null);

    const onPause = () => {
      if (playerRef.current) {
        playerRef.current.pause();
      }
    };

    return currentVideoNode ? (
      <>
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '20px',
          }}>
          <VideoPlayer
            source={currentVideoNode?.contentUrl || ''}
            startTime={currentVideoNode.startInSeconds}
            endTime={currentVideoNode.endInSeconds}
            onFinished={onFinishedVideo}
            currentNode={currentNode}
            started={started}
            showControls={preview}
            fitHeight={fitHeight}
            onWidthCalculated={setWidth}
            reference={(ref: any) => {
              playerRef.current = ref;
            }}
            setProgres={setProgres}
          />
          {!started && (
            <div className="PlayerBranchOverlay">
              <StartingOverlay
                gradable={isGradable || false}
                onStart={onStart}
                showResults={() => setShowResults(true)}
                gradeResult={gradeResult}
              />
            </div>
          )}
          {ended && (
            <div className="PlayerBranchOverlay">
              <EndingOverlay
                gradeResult={gradeResult}
                gradable={isGradable}
                onContinue={onContinue}
                onCourseHome={onCourseHome}
                preview={preview}
                isLoading={isLoading}
                nextLessonIsTheSame={nextLessonIsTheSame}
                showResults={() => setShowResults(true)}
                skipFinishButtons={skipFinishButtons}
                nextLessonId={nextLessonId}
              />
            </div>
          )}
          {currentNode?.data.ivNodeType === MULTIPLE_CHOICE && (
            <div className="PlayerBranchOverlay">
              <MCHoiceOverlay
                onSelectChoice={onSelectMChoice}
                mChoice={currentNode.data as LessonContentMChoice}
                passingScore={passingScore}
              />
            </div>
          )}
          {(currentNode?.data.ivNodeType === OVERLAY_VIDEO_TYPE ||
            currentNode?.data.ivNodeType === OVERLAY_VIMEO_VIDEO_TYPE) && (
            <FreeformOverlay
              overlayNode={currentNode.data as LessonContentOverlayVideo}
              progress={progress}
              width={width}
              onPause={onPause}
            />
          )}
          {currentNode?.data.ivNodeType === CHOICE_HEADER_NODE_TYPE && (
            <div className="PlayerBranchOverlay">
              <ChoiceOverlay
                onSelectChoice={onSelectChoice}
                choice={currentNode.data as LessonContentChoiceMapped}
              />
            </div>
          )}
          {currentNode?.data.ivNodeType === CHALLENGE_BRANCH_HEADER && (
            <div className="PlayerBranchOverlay">
              <ChallengeBranchOverlay
                onSelectChoice={onSelectChallenge}
                choice={currentNode.data as LessonContentChoiceMapped}
                passingScore={passingScore}
              />
            </div>
          )}
          {currentNode?.data.ivNodeType === CHALLENGE_BRANCH_AI && (
            <ChallengeBranchAIOverlay
              passingScore={passingScore}
              challengeBranch={currentNode.data as ChallengeBranchAIMapped}
              onSelectChallengeAI={onSelectChallengeAI}
            />
          )}
        </div>
        {showResults && gradeResult && (
          <ResultsModal
            results={gradeResult}
            onCancel={() => setShowResults(false)}
          />
        )}
      </>
    ) : null;
  },
);

export default TapyblPlayerUI;
