import React, {useContext} from 'react';
import {Handle, Position} from 'reactflow';
import {handleStyle} from '../view/options';
import EditorTooltipButton from '../../../uiToolkit/EditorTooltopButton';
import {
  choiceBranchIc,
  editorAddAudio,
  editorAddComment,
  editorAddFreeAnswer,
  editorAddGradableBranch,
  editorAddGroupBranch,
  editorAddQuestion,
  editorVideoIcon,
  imageNodeIc,
  lessonBlocked,
  multipleChoiceListIc,
  textIc,
} from '../../../assets/images';
import {EditorContext} from '../container/LessonEditorContainer';
import {
  AUDIO_NODE,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_IMAGE_OPTION,
  CHALLENGE_BRANCH_QUESTION,
  CHALLENGE_BRANCH_TEXT_OPTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_IMAGE_OPTION,
  CHOICE_TEXT_OPTION,
  CHOICE_TEXT_QUESTION,
  COMMENT_NODE,
  GROUP_BRANCH_NODE,
  IMAGE_NODE_TYPE,
  LessonContentBase,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  QUESTION_NODE,
  RANDOM_BRANCH,
  TEXT_ANSWER_NODE,
  TEXT_NODE,
  VIDEO_NODE_TYPE,
  getNextStepsForNodeByType,
} from '../../../data/models/LessonContent/LessonContentBase';
import {lessonEditorText} from '../lessonEditorText';
import Tooltip from '../../../uiToolkit/Tooltip';

interface Props {
  data: {
    parent?: LessonContentBase;
  };
}

const AddNewSourceNode = React.memo(({data}: Props) => {
  const context = useContext(EditorContext);

  const getActionsByParent = () => {
    const availableActions = getNextStepsForNodeByType(
      data.parent?.ivNodeType,
      context.isGradable,
    );
    return availableActions.map(item => ({
      icon: getIconByType(item),
      action: () => context.addNewNode(data.parent?.internalId, item),
      tooltip: getTooltipByType(item),
    }));
  };

  const getIconByType = (type: number) => {
    switch (type) {
      case VIDEO_NODE_TYPE:
        return editorVideoIcon;
      case IMAGE_NODE_TYPE:
      case CHOICE_IMAGE_OPTION:
      case CHALLENGE_BRANCH_IMAGE_OPTION:
        return imageNodeIc;
      case CHOICE_HEADER_NODE_TYPE:
        return choiceBranchIc;
      case CHALLENGE_BRANCH_QUESTION:
      case CHOICE_TEXT_QUESTION:
      case CHOICE_TEXT_OPTION:
      case CHALLENGE_BRANCH_TEXT_OPTION:
      case TEXT_NODE:
        return textIc;
      case MULTIPLE_CHOICE:
        return multipleChoiceListIc;
      case AUDIO_NODE:
        return editorAddAudio;
      case QUESTION_NODE:
        return editorAddQuestion;
      case GROUP_BRANCH_NODE:
        return editorAddGroupBranch;
      case CHALLENGE_BRANCH_HEADER:
      case CHALLENGE_BRANCH_AI:
        return editorAddGradableBranch;
      case RANDOM_BRANCH:
        return editorAddGradableBranch;
      case COMMENT_NODE:
        return editorAddComment;
      case TEXT_ANSWER_NODE:
        return editorAddFreeAnswer;
      default:
        return editorVideoIcon;
    }
  };

  const getTooltipByType = (type: number) => {
    switch (type) {
      case VIDEO_NODE_TYPE:
        return lessonEditorText.videoNodeTooltip;
      case IMAGE_NODE_TYPE:
        return 'Image Source';
      case CHOICE_IMAGE_OPTION:
        return lessonEditorText.choiceImageNodeTooltip;
      case CHOICE_HEADER_NODE_TYPE:
        return lessonEditorText.choiceNodeTooltip;
      case OVERLAY_VIDEO_TYPE:
        return lessonEditorText.videoOverlay;
      case CHOICE_TEXT_QUESTION:
        return lessonEditorText.choiceQuestionNodeTooltip;
      case CHOICE_TEXT_OPTION:
        return lessonEditorText.choiceTextNodeTooltip;
      case MULTIPLE_CHOICE:
        return lessonEditorText.mChoiceNodeTooltip;
      case CHALLENGE_BRANCH_AI:
        return lessonEditorText.challengeBranchAITooltip;
      case AUDIO_NODE:
        return 'Audio Source';
      case QUESTION_NODE:
        return 'Single question';
      case TEXT_NODE:
        return 'Text';
      case CHALLENGE_BRANCH_HEADER:
        return 'Challenge Branch';
      case GROUP_BRANCH_NODE:
        return 'Group Branch';
      case RANDOM_BRANCH:
        return 'Random Branch';
      case COMMENT_NODE:
        return 'Feedback Button';
      case TEXT_ANSWER_NODE:
        return 'Survey';
      case CHALLENGE_BRANCH_QUESTION:
        return lessonEditorText.challengeBranchQuestionTooltip;
      case CHALLENGE_BRANCH_TEXT_OPTION:
        return lessonEditorText.challengeBranchTextOption;
      case CHALLENGE_BRANCH_IMAGE_OPTION:
        return lessonEditorText.challengeBranchImageOption;
      default:
        return '';
    }
  };

  return (
    <div className="EditorNodeContainer">
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      {context.isPublished ? (
        <Tooltip tooltipText={lessonEditorText.cantProceedTooltip} greenTooltip>
          <img src={lessonBlocked} />
        </Tooltip>
      ) : (
        <EditorTooltipButton actions={getActionsByParent()} />
      )}
    </div>
  );
});

export default AddNewSourceNode;
