import {
  LearnerProgressModel,
  PROGRESS_ITEM_CHALLENGE,
  PROGRESS_ITEM_CHALLENGE_AI,
  PROGRESS_ITEM_MCHOICE,
} from '../../data/models/LessonContent/CreateLearnerLessonModel';
import {
  LessonContentChallengeBranchAI,
  LessonContentChallengeBranchQuestion,
  LessonContentMChoice,
} from '../../data/models/LessonContent/LessonContentBase';
import {LessonContentList} from '../../data/models/LessonContent/LessonContentList';
import {LessonLearner} from '../../data/models/LessonContent/LessonLearner';
import {GradeResult} from './models';

export const parseLessonAnswers = (
  progress: LearnerProgressModel,
  content: LessonContentList,
  lessonLearner: LessonLearner,
  passingScore: number,
) => {
  const answers: {question: string; correct: boolean}[] = [];
  let totalQuestions = 0;
  let correctAnswers = 0;
  const recordedAnswersKeys = Object.keys(progress);

  recordedAnswersKeys.forEach(key => {
    if (content.contentList.items[key]) {
      const type = progress[key].type;
      if (type === PROGRESS_ITEM_MCHOICE) {
        answers.push({
          question: (
            content.contentList.items[key].data as LessonContentMChoice
          ).question,
          correct: progress[key].isCorrect || false,
        });
        totalQuestions++;
        if (progress[key].isCorrect) {
          correctAnswers++;
        }
      }
      if (type === PROGRESS_ITEM_CHALLENGE) {
        const node = content.contentList.items[key];
        const parent = content.contentList.items[node.parentsIds[0]];
        answers.push({
          question: (parent.data as LessonContentChallengeBranchQuestion)
            .question,
          correct: progress[key].isCorrect || false,
        });
        totalQuestions++;
        if (progress[key].isCorrect) {
          correctAnswers++;
        }
      }
      if (type === PROGRESS_ITEM_CHALLENGE_AI) {
        const node = content.contentList.items[key];
        answers.push({
          question: (node.data as LessonContentChallengeBranchAI).question,
          correct: progress[key].isCorrect || false,
        });
        totalQuestions++;
        if (progress[key].isCorrect) {
          correctAnswers++;
        }
      }
    }
  });

  return {
    passingScore,
    earnedScore: lessonLearner.grade || 0,
    answers,
    totalQuestions,
    correctAnswers,
  } as GradeResult;
};
