import CoursesApi from '../api/CoursesApi';
import {mapCourse} from '../mappers/courseMapper';
import {Course, CourseViewModel} from '../models/Course/Course';

class CoursesRepository {
  private coursesApi = new CoursesApi();

  public getLearingStandarts(organizationId: string) {
    return this.coursesApi.getLearningStandarts(organizationId);
  }

  public async createCourse(model: CourseViewModel, organizationId: string) {
    const courseMapped = mapCourse(model);
    const result = await this.coursesApi.createCourse(
      courseMapped,
      organizationId,
    );
    if (result.success && result.data && result.data.id && model.image) {
      await this.coursesApi.uploadCourseAvatar(
        model.image,
        result.data.id,
        organizationId,
      );
    }
    return result;
  }

  public getOrganizationCourses(organizationId: string) {
    return this.coursesApi.getOrganizationCourses(organizationId);
  }

  public getPublishedOrganizationCourses(organizationId: string) {
    return this.coursesApi.getPublishedOrganizationCourses(organizationId);
  }

  public getCourseInstructor(organizationId: string, courseId: string) {
    return this.coursesApi.getCourseInstructor(organizationId, courseId);
  }

  public deleteCourse(organizationId: string, courseId: string) {
    return this.coursesApi.deleteCourse(organizationId, courseId);
  }

  public getCourse(organizationId: string, courseId: string) {
    return this.coursesApi.getCourse(organizationId, courseId);
  }

  public getCourseList() {
    return this.coursesApi.getCoursesList();
  }

  public getPublicCourseList(subjectId?: string) {
    return this.coursesApi.getPublicCoursesList(subjectId);
  }

  public editCourse(organizationId: string, course: Course) {
    return this.coursesApi.editCourse(organizationId, course);
  }

  public getCoursePresentation(courseId: string) {
    return this.coursesApi.getCoursePresentation(courseId);
  }

  public getCoursePreview(organizationId: string, courseId: string) {
    return this.coursesApi.getCoursePreview(organizationId, courseId);
  }

  public getLearnerCourseProgress(courseId: string) {
    return this.coursesApi.getLearnerCourseProgress(courseId);
  }

  public selfEnroll(courseId: string) {
    return this.coursesApi.selfEnrollInTheCourse(courseId);
  }

  public uploadCourseAvatar(
    image: File,
    courseId: string,
    organizationId: string,
  ) {
    return this.coursesApi.uploadCourseAvatar(image, courseId, organizationId);
  }

  public getSubjects() {
    return this.coursesApi.getCourseSubjects();
  }

  public editCoursePoster(
    organizationId: string,
    courseId: string,
    image: File,
  ) {
    return this.coursesApi.uploadCourseAvatar(image, courseId, organizationId);
  }

  public publishCourse(organizationId: string, courseId: string) {
    return this.coursesApi.publishCourse(organizationId, courseId);
  }

  public republishCourse(organizationId: string, courseId: string) {
    return this.coursesApi.rePublishCourse(organizationId, courseId);
  }

  public getSidebarOptions(organizationId: string, search: string) {
    return this.coursesApi.getSidebarOptions(organizationId, search);
  }
}

export default CoursesRepository;
