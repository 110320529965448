import * as React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import {Login} from '../pages/Login';
import {Home} from '../pages/Home';
import {
  confirmEmailLoader,
  createSubscriptionLoader,
  landingLoader,
  permissionRouteLoader,
  rootLoader,
  secureRouteLoader,
  workspaceRouteLoader,
} from './loaders';
import {Library} from '../pages/Library';
import {VideoPlayback} from '../pages/VideoPlayback';
import {DashboardRoot} from './rootElements/DashboardRoot';
import AuthRoot from './rootElements/AuthRoot';
import {CoursePreview, CoursesList, CreateCourse} from '../pages/Courses';
import {CreateStudent} from '../pages/Learners/Create';
import {ListLearners, CreateLearners} from '../pages/Learners';
import {Signup} from '../pages/Signup';
import {ConfirmEmail} from '../pages/ConfirmEmail';
import {ResetPassword, SendForgotPassword} from '../pages/ForgotPassword';
import {Landing} from '../pages/Landing';
import PermissionError from '../pages/PermissionError';
import {APP_PERMISSIONS} from '../data/permissions';
import DashboardSettingsRoot from './rootElements/DashboardSettingsRoot';
import {GeneralSettings} from '../pages/Settings/GeneralSettings';
import {RoleCreate, RoleEdit, RoleList} from '../pages/Settings/RoleSettings';
import {
  StaffEdit,
  StaffInvite,
  StaffList,
} from '../pages/Settings/StaffSettings';
import {Onboarding} from '../pages/Onboarding';
import {Folders} from '../pages/Folders';
import EditorRoot from './rootElements/EditorRoot';
import {UserProfileRoot} from './rootElements/UserProfileRoot';
import {SettingsUserProfile} from '../pages/UserProfile/SettingsUserProfile';
import {NotificationsUserProfile} from '../pages/UserProfile/NotificationsUserProfile';
import {Lessons} from '../pages/Lessons';
import {LessonEditor} from '../pages/LessonEditor';
import {CourseHome} from '../pages/CourseHome';
import {CourseEditRoot} from './rootElements/CourseEditRoot';
import {GeneralCourseEdit} from '../pages/CourseEdit/GeneralCourseEdit';
import {AdminPanel} from '../pages/AdminPanel';
import NotFound from '../modules/NotFound';
import LearnersRoot from './rootElements/LearnersRoot';
import {LearnerInvitations} from '../pages/Learners/Invitations';
import {LTIPlayer} from '../pages/LTIPlayer';
import {LtiSettings} from '../pages/Settings/LtiSettings';
import {SubscriptionSettings} from '../pages/Settings/SubscriptionSettings';
import {
  TailorSubscription,
  SelectSubscription,
} from '../pages/SelectSubscription';
import {Workspace} from '../pages/Workspace';
import SubscriptionSuccess from '../pages/SubscriptionSuccess';
// import {StorageIntegration} from '../pages/Settings/StorageIntergrationSettings';
// import {VideoEditor} from '../pages/VideoEditor';

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<Landing />} key="/" loader={rootLoader} />,
    <Route
      path="/permission_error"
      element={<PermissionError />}
      key="/permission_error"
    />,
    <Route
      path="/admin_panel"
      element={<AdminPanel />}
      key="admin_panel"
      loader={secureRouteLoader}
    />,
    <Route path="/auth" element={<AuthRoot />} key="/auth">
      <Route path="login" loader={landingLoader} element={<Login />} />
      <Route path="signup" loader={landingLoader} element={<Signup />} />
      <Route
        path="confirm_email"
        loader={confirmEmailLoader}
        element={<ConfirmEmail />}
      />
      <Route
        path="forgot_password"
        loader={landingLoader}
        element={<SendForgotPassword />}
      />
      <Route
        path="reset_password"
        loader={landingLoader}
        element={<ResetPassword />}
      />
      <Route path="onboarding" element={<Onboarding />} />
    </Route>,
    <Route
      path="/home"
      loader={secureRouteLoader}
      element={<Home />}
      key="/home"
    />,
    <Route
      path="/course/:courseId"
      element={<CourseHome />}
      key="/course"
      loader={secureRouteLoader}
    />,
    <Route
      path="/course/:courseId/lesson/:lessonId"
      element={<VideoPlayback />}
      loader={secureRouteLoader}
      key="/course"
    />,
    <Route
      path="/subscription/:organizationId/select"
      element={<SelectSubscription />}
      loader={createSubscriptionLoader}
      key="/sub_selectt"
    />,
    <Route
      path="/subscription/:organizationId/tailor"
      element={<TailorSubscription />}
      loader={createSubscriptionLoader}
      key="/sub_tailor"
    />,
    <Route
      path="/subscription/:organizationId/select_trial"
      element={<SelectSubscription trial />}
      loader={createSubscriptionLoader}
      key="/sub_selectt"
    />,
    <Route
      path="/subscription/:organizationId/tailor_trial"
      element={<TailorSubscription trial />}
      loader={createSubscriptionLoader}
      key="/sub_tailor"
    />,
    // <Route
    //   path="/subscription/:organizationId/upgrade"
    //   element={<SelectSubscription upgrade />}
    //   loader={createSubscriptionLoader}
    //   key="/sub_upgrade"
    // />,
    // <Route
    //   path="/subscription/:organizationId/tailor_upgrade"
    //   element={<TailorSubscription upgrade />}
    //   loader={createSubscriptionLoader}
    //   key="/sub_tailor_upgrade"
    // />,
    <Route
      path="/subscription/:organizationId/success"
      element={<SubscriptionSuccess />}
      loader={secureRouteLoader}
      key="/sub_success"
    />,
    <Route
      path="/subscription/:organizationId/success_update"
      element={<SubscriptionSuccess forUpdate />}
      loader={secureRouteLoader}
      key="/sub_success_update"
    />,
    <Route
      path="/subscription/:organizationId/payment_success"
      element={<SubscriptionSuccess forPayment />}
      loader={secureRouteLoader}
      key="/payment_success"
    />,
    <Route
      path="/subscription/:organizationId/trial_success"
      element={<SubscriptionSuccess forTrial />}
      loader={secureRouteLoader}
      key="/trial_success"
    />,
    <Route
      path="/manage/:organizationId"
      loader={workspaceRouteLoader}
      element={<DashboardRoot />}
      key="/manage">
      <Route
        path="workspace"
        loader={workspaceRouteLoader}
        element={<Workspace />}
      />
      <Route
        path="library"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_LIST_FILES)}
        element={<Library />}
      />
      <Route
        path="course/:courseId"
        element={<CourseEditRoot />}
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
        key="/course">
        <Route
          path="general"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
          element={<GeneralCourseEdit />}
        />
        {/* <Route
          path="learners"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
          element={<CourseHome />}
        />
        <Route
          path="syllabus"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
          element={<CourseHome />}
        /> */}
      </Route>
      <Route
        path="courses"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_LIST_COURSES)}
        element={<CoursesList />}
      />
      <Route
        path="courses/create"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
        element={<CreateCourse />}
      />
      <Route
        path="learners/create"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_LIST_LEARNERS)}
        element={<CreateLearners />}
      />
      <Route
        path="learners"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_LIST_LEARNERS)}
        element={<LearnersRoot />}>
        <Route
          path="general"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_LIST_LEARNERS)}
          element={<ListLearners />}
        />
        <Route
          path="invitations"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_LIST_LEARNERS)}
          element={<LearnerInvitations />}
        />
      </Route>
      <Route
        path="students/create"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_LEARNERS)}
        element={<CreateStudent />}
      />
      <Route
        path="settings"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
        element={<DashboardSettingsRoot />}>
        <Route
          path="general"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<GeneralSettings />}
        />
        <Route
          path="roles"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<RoleList />}
        />
        <Route
          path="users"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<StaffList />}
        />
        <Route
          path="invite_users"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<StaffInvite />}
        />
        <Route
          path="create_role"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<RoleCreate />}
        />
        <Route
          path="edit_role/:roleId"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<RoleEdit />}
        />
        <Route
          path="edit_user/:memberId"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<StaffEdit />}
        />
        <Route
          path="lti_settings"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<LtiSettings />}
        />
        {/* <Route
          path="storage_integration"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<StorageIntegration />}
        /> */}
        <Route
          path="subscription"
          loader={permissionRouteLoader(APP_PERMISSIONS.ORG_MANAGE_SETTINGS)}
          element={<SubscriptionSettings />}
        />
      </Route>
    </Route>,
    <Route
      path="preview/:organizationId"
      loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
      key={'preview'}>
      <Route
        path="course/:courseId"
        key="course_preview"
        element={<CoursePreview />}
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
      />
      <Route
        path="course/:courseId/lesson/:lessonId"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
        element={<VideoPlayback preview />}
        key={Math.random().toString()}
      />
    </Route>,
    <Route
      path="/editor/:organizationId"
      key="editor"
      element={<EditorRoot />}
      loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}>
      <Route
        path="courses/:courseId/folders"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
        element={<Folders />}
      />
      <Route
        path="courses/:courseId/folders/:folderId/lessons"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
        element={<Lessons />}
      />
      <Route
        path="courses/:courseId/folders/:folderId/lessons/:lessonId/edit"
        loader={permissionRouteLoader(APP_PERMISSIONS.ORG_CREATE_COURSES)}
        element={<LessonEditor />}
      />
    </Route>,
    <Route
      path="/player/:lessonId"
      loader={secureRouteLoader}
      element={<VideoPlayback />}
      key="/playback"
    />,
    <Route
      path="/profile"
      element={<UserProfileRoot />}
      loader={secureRouteLoader}
      key="/profile">
      {/* <Route path="stats" element={<StatsUserProfile />} /> */}
      <Route
        path="settings"
        loader={secureRouteLoader}
        element={<SettingsUserProfile />}
      />
      <Route
        path="notifications"
        loader={secureRouteLoader}
        element={<NotificationsUserProfile />}
      />
    </Route>,
    <Route
      path="/lti_player/:lessonSecret"
      element={<LTIPlayer />}
      key="lti_player"
    />,
    // <Route path="/editor_test" element={<VideoEditor />} key="editor_test" />,
    <Route path="*" element={<NotFound />} key="not_found" />,
  ]),
);

const AppRouter = React.memo(() => {
  return <RouterProvider router={router} />;
});

export default AppRouter;
