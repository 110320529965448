import {ApiResponse, PaginatedApiReponse} from '../models/ApiResponse';
import {LearnerInvitation} from '../models/Learners/LearnerInvitation';
import {apiClient, withAuth} from './baseApiClient';

class LearnersApi {
  public async getMyLearners(
    page: number,
    organizationId: string,
    pageSize: number,
    courseIds?: string[],
    search?: string,
  ): Promise<PaginatedApiReponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `organizationLearners/${organizationId}/list`,
      {
        page,
        pageSize,
        courseIds,
        search,
      },
      {headers},
    );
  }

  public async getEnrollmentCandidates(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search?: string,
  ): Promise<PaginatedApiReponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `organizationLearners/${organizationId}/enrollment_candidates/${courseId}`,
      {
        page,
        pageSize,
        search,
      },
      {headers},
    );
  }

  public async getLearnerInvitations(
    organizationId: string,
    page: number,
    pageSize: number,
    search?: string,
  ): Promise<PaginatedApiReponse<LearnerInvitation>> {
    const headers = await withAuth({});
    return apiClient.post(
      `organizationLearners/${organizationId}/learnerInvitations`,
      {
        page,
        pageSize,
        search,
      },
      {headers},
    );
  }

  public async addLearnersToCourse(
    organizationLearnerIds: string[],
    organizationId: string,
    courseId: string,
    dueDate?: Date,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `OrganizationLearners/${organizationId}/course/${courseId}/enroll`,
      {
        organizationLearnerIds,
        dueDate,
      },
      {headers},
    );
  }

  public async deleteLearnerFromOrganization(
    organizationId: string,
    learnerId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `organizationLearners/${organizationId}/${learnerId}`,
      {
        headers,
      },
    );
  }

  public async removeLearnerFromTheCourse(
    organizationId: string,
    learnerId: string,
    courseId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `organizationLearners/${organizationId}/${learnerId}/course/${courseId}`,
      {
        headers,
      },
    );
  }

  public async reEnrollLearnerInTheCourse(
    organizationId: string,
    courseId: string,
    learnerId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `course/${organizationId}/${courseId}/activateCourseLearner/${learnerId}`,
      {},
      {
        headers,
      },
    );
  }

  public async reactivateLearnerInOrganization(
    organizationId: string,
    learnerId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.post(
      `organizationLearners/${organizationId}/activate/${learnerId}`,
      {},
      {
        headers,
      },
    );
  }

  public async deleteLearnerInvitation(
    organizationId: string,
    invitationId: string,
  ): Promise<ApiResponse<any>> {
    const headers = await withAuth({});
    return apiClient.delete(
      `organizationInvitation/${organizationId}/invitation/learner/${invitationId}`,
      {
        headers,
      },
    );
  }
}

export default LearnersApi;
