import {makeAutoObservable} from 'mobx';
import AnalyticsRepository from '../../../data/repositories/AnalyticsRepository';
import AuthRepository from '../../../data/repositories/AuthRepository';

class AdminPanelStore {
  private analyticsRepo = new AnalyticsRepository();
  private authRepo = new AuthRepository();

  constructor() {
    makeAutoObservable(this);
  }

  public getAnalytics() {
    return this.analyticsRepo.getAdminAnalytics();
  }

  public logout() {
    return this.authRepo.logout();
  }
}

export default AdminPanelStore;
