import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../data/repositories/UserRepository';
import OrganizationRepository from '../../../data/repositories/OrganizationRepository';
import NotificationsRepository from '../../../data/repositories/NotificationsRepository';
import CoursesListRepository from '../../../data/repositories/CoursesRepository';

class HomeStore {
  private userRepo = new UserRepository();
  private orgRepo = new OrganizationRepository();
  private notificationsRepository = new NotificationsRepository();
  private coursesListRepository = new CoursesListRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getUserInfo() {
    this.isLoading = true;
    const result = await this.userRepo.getUserInfo();
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getLearnerOrganization() {
    this.isLoading = true;
    const result = await this.orgRepo.tryGetLearnerOrganization();
    this.isLoading = false;
    return result.data;
  }

  public async getMyOrganizations() {
    this.isLoading = true;
    const result = await this.orgRepo.getMyOrganizations();
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getMyInvitations() {
    const result = await this.notificationsRepository.getMyNotifications();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getCourseList() {
    const result = await this.coursesListRepository.getCourseList();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default HomeStore;
