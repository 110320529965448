import {MarkerType} from 'reactflow';
import {
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHALLENGE_BRANCH_QUESTION,
  CHOICE_HEADER_NODE_TYPE,
  CHOICE_TEXT_QUESTION,
} from '../../../data/models/LessonContent/LessonContentBase';

export const defaultEdgeOptions = (parentType?: number) => ({
  style: {
    stroke: '#1D7777',
  },
  type: 'step',
  markerEnd: getArrowMarker(parentType),
  animated: getIsAnimated(parentType),
  ...getLabel(parentType),
});

const getIsAnimated = (parentType?: number) => {
  if (
    parentType === CHOICE_TEXT_QUESTION ||
    parentType === CHOICE_HEADER_NODE_TYPE ||
    parentType === CHALLENGE_BRANCH_HEADER ||
    parentType === CHALLENGE_BRANCH_QUESTION ||
    parentType === CHALLENGE_BRANCH_AI
  ) {
    return true;
  }
  return false;
};

const getLabel = (parentType?: number) => {
  if (parentType === CHOICE_HEADER_NODE_TYPE) {
    return {
      label: 'Choice',
      labelStyle: {
        fontFamily: 'Walsheim',
        fontSize: '12px',
        fill: '#98BDBD',
        fontWeight: 'normal',
      },
    };
  }
  if (parentType === CHALLENGE_BRANCH_HEADER) {
    return {
      label: 'Challenge',
      labelStyle: {
        fontFamily: 'Walsheim',
        fontSize: '12px',
        fill: '#98BDBD',
        fontWeight: 'normal',
      },
    };
  }
  return {};
};

const getArrowMarker = (parentType?: number) => {
  if (
    parentType === CHOICE_TEXT_QUESTION ||
    parentType === CHOICE_HEADER_NODE_TYPE ||
    parentType === CHALLENGE_BRANCH_HEADER ||
    parentType === CHALLENGE_BRANCH_QUESTION ||
    parentType === CHALLENGE_BRANCH_AI
  ) {
    return undefined;
  }
  return {
    type: MarkerType.ArrowClosed,
    color: '#1D7777',
  };
};

export const defaultNodeOptions = {
  draggable: false,
  connectable: false,
  position: {x: 0, y: 0},
};

export const handleStyle = {
  background: 'transparent',
  border: 'none',
};
