import React from 'react';
import {Col} from 'react-bootstrap';
import {appText} from '../../../../assets/text/appText';
import {bookIc} from '../../../../assets/images';
import './styles.css';
import Button from '../../../../uiToolkit/Buttons/Button';

interface Props {
  onCreate: () => void;
}

const OrganizationEmptyState = React.memo(({onCreate}: Props) => {
  return (
    <Col lg={{span: 8, offset: 2}} xs={12}>
      <div className="OrgEmptyStateContainer">
        <img src={bookIc} alt="book" />
        <p className="Title">{appText.noCourses}</p>
        <div className="ButtonsContainer">
          <div className="Button">
            <p>{appText.createCoursePrompt}</p>
            <Button
              title={appText.createCourseAction}
              onClick={onCreate}
              uiType="action"
            />
          </div>
        </div>
      </div>
    </Col>
  );
});

export default OrganizationEmptyState;
