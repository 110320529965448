import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import AuthRepository from '../../../../data/repositories/AuthRepository';

class ListCoursesStore {
  private coursesRepo = new CoursesRepository();
  private authRepo = new AuthRepository();

  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getOrganizationCourses(organizationId: string) {
    this.isLoading = true;
    const result =
      await this.coursesRepo.getOrganizationCourses(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async deleteCourse(organizationId: string, courseId: string) {
    this.isLoading = true;
    const result = await this.coursesRepo.deleteCourse(
      organizationId,
      courseId,
    );
    this.isLoading = false;
    return result.success;
  }

  public async getPermissions(organizationId: string): Promise<string[]> {
    this.isLoading = true;
    const result = await this.authRepo.getUserOrgPermissions(organizationId);
    this.isLoading = false;
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }
}

export default ListCoursesStore;
