import {makeAutoObservable} from 'mobx';
import LearnerRepository from '../../../data/repositories/LearnerRepository';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';

class EnrollLearnersWizardStore {
  private learnerRepository = new LearnerRepository();
  private showWizard = false;
  private courseId?: string;
  private callback?: () => void;

  constructor() {
    makeAutoObservable(this);
  }

  public startWizard(courseId?: string, callback?: () => void) {
    this.showWizard = true;
    this.courseId = courseId;
    this.callback = callback;
  }

  public isVisible() {
    return this.showWizard;
  }

  public cancelWizard() {
    this.showWizard = false;
  }

  public async saveLearners(
    selectedLearners: string[],
    organizationId: string,
    dueDate?: Date,
  ) {
    if (this.courseId) {
      const result = await this.learnerRepository.addLearnersToCourse(
        selectedLearners,
        organizationId,
        this.courseId,
        dueDate,
      );
      if (result.success) {
        toast.success('Learner(s) added successfully', toastConfig);
        this.showWizard = false;
        if (this.callback) {
          this.callback();
        }
        return result.data;
      } else {
        toast.error('There was an issue adding learner(s)', toastConfig);
        return result.errors;
      }
    } else {
      toast.error('There was an issue adding learner(s)', toastConfig);
    }
  }

  public async getOrganizationLearners(
    organizationId: string,
    page: number,
    pageSize: number,
  ) {
    if (this.courseId) {
      const result = await this.learnerRepository.getEnrollmentCandidates(
        organizationId,
        this.courseId || '',
        page,
        pageSize,
        '',
      );

      if (result.success && result.data) {
        return result.data;
      }
    }

    return {
      data: [],
      pagesCount: 0,
    };
  }
}

export default EnrollLearnersWizardStore;
